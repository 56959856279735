import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useFetch from "use-http";
import { IRoute, IUser } from "../../../reducers/types";
import ETable from "../../../common/components/ETable/ETable";
import { Profile } from "./../../profile/Profile";
import { API_URL } from "../../../index";
import {
  ArrowRight,
  FileEarmarkArrowDown,
  FolderSymlink,
  PencilSquare,
  FileEarmarkArrowUp,
} from "react-bootstrap-icons";
import { MapComponent } from "../../../common/components/MapComponent";
import moment from "moment";
import { LoadingScreen } from "../../../common/components/LoadingScreen";
import ViewVpProfile from "../../../common/components/ViewVpProfile";

export default function Deployment() {
  const [user]: [IUser] = useSelector((state: any) => [state.user.user.id]);
  const [viewProfileId, setViewProfileId] = React.useState([]);
  const [deploymentId, setDeploymentId] = React.useState();
  const [showProfileModal, setShowProfileModal] = React.useState(false);
  const [showDeployModal, setDeployModal] = React.useState(false);

  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);

  // const { get: eachProfileReview, response: eachReview } = useFetch(`/items/profile_review?filter[profile]=${res?.data?.profile?.id}&filter[review_year]=${moment(date)?.format('Y')}&meta=filter_count&limit=0`, {} ,[]);

  const [state, setState] = React.useState<{
    page: number;
    query: string;
    sortBy: string;
    isAsc: boolean;
  }>({
    page: 1,
    query: "",
    sortBy: "id",
    isAsc: true,
  });

  const [userId, setUserId] = useState();
  const getUserId = () => {
    let persistantState = JSON.parse(
      localStorage.getItem("persistantState") || ""
    );
    let id = persistantState.user.user.id;
    setUserId(id);
  };

  useEffect(() => {
    getUserId();
  }, []);

  const { get, data, loading: loadingProfile } = useFetch(`/items/assignments`);

  // const { get: profile, data: profileData } = useFetch(`/items/assignments?filter[assign_to]=${userId}`);
  const { get: profile, data: profileData } = useFetch(`/items/assignments`);

  const {
    patch: addDeployment,
    get: getDeployment,
    data: deploymentData,
  } = useFetch(`/items/assignments`);

  useEffect(() => {
    if (userId) {
      // let url = `?fields=*.*.*&filter[assign_to]=${user}&limit=10`;
      // let url = `?fields=*.*.*&filter[assign_to]=${user}&limit=10`;// previous
      let url = `?filter[assign_to]=${userId}&fields=*.*.*`;
      if (state?.query && state.query.length > 0)
        url += `&meta=filter_count&query=${state.query}`;
      if (state?.page)
        url += `&meta=filter_count&offset=${(state.page - 1) * 10}`;
      if (state?.sortBy)
        url += `&meta=filter_count&sort=${
          state.isAsc ? state.sortBy : "-" + state.sortBy
        }`;
      get(url);
    }
  }, [get, state, userId]);

  let date = moment();

  // const [today_date, setToday_date] = React.useState(moment(date).format('YYYY-MM-DD'))
  // let reviewDtae = moment();//today date

  // moment(each.review_date).format("YYYY-MM-DD") <= moment(reviewDtae).format("YYYY-MM-DD"))
  // six math later date use for testing switch to upper state
  // let reviewDtae = moment();
  // let sixm = reviewDtae.add(6, 'M').format('YYYY-MM-DD');
  // const [today_date, setToday_date] = React.useState(sixm)

  const { get: eachProfileReview, response: eachReview } = useFetch(
    `/items/profile_review`,
    {}
  );
  const [assignmentData, setAssignmentData] = useState<Array<any>>([]);

  useEffect(() => {
    setAssignmentData([]);
    let dataAfterReviewcount: Array<object> = [];
    if (!loadingProfile) {
      data?.data?.map((each) =>
        eachProfileReview(
          `?filter[profile]=${each?.profile?.id}&filter[review_year]=${moment(
            date
          )?.format("Y")}&meta=filter_count&limit=0`
        ).then((res) => {
          each.reviewCount = res?.meta?.filter_count;
          // dataAfterReviewcount.push(each)
          // console.log("res", res)
          setAssignmentData((pre) => [...pre, each]);
        })
      );
    }

    // setAssignmentData(dataAfterReviewcount)
  }, [data]);

  // console.log("dataAfterReviewcount", assignmentData);

  return (
    <>
      {!data ? (
        <LoadingScreen />
      ) : (
        <React.Fragment>
          <div className="main-header d-flex justify-content-between">
            <div>
              <h1>Deployment And Manage Deployment</h1>
            </div>
          </div>
          <div className="main-body">
            <div className="card h-auto">
              <div className="card-body">
                <ETable<any>
                  columns={[
                    // { key: "id", label: "Assignment ID" },
                    { key: "id", label: "ID" },
                    // { key: "profile.name", label: "Profile Name" },
                    // { key: "profile.name", label: "Name" },
                  ]}
                  // totalPages={Math.ceil((data?.meta?.filter_count || 10) / 10)}
                  totalPages={Math.ceil((assignmentData.length || 10) / 10)}
                  onChange={(query, sortKey, isSortAsc, page) => {
                    setState({
                      query,
                      sortBy: sortKey,
                      isAsc: isSortAsc,
                      page,
                    });
                  }}
                  // items={data?.data || []}
                  items={assignmentData || []}
                  actionButtons={[]}
                  customColumns={[
                    {
                      heading: "Name",
                      renderer: (item) =>
                        item.profile.name ? (
                          <a
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View profile"
                            className="text-secondary "
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              setShowProfileModal(true);
                              await profile(
                                `/${item.id}?fields=*.*.*&single=1`
                              );
                            }}
                          >
                            {item.profile.name}
                          </a>
                        ) : (
                          <span>Not Avaliable</span>
                        ),
                    },

                    {
                      heading: "Status",
                      renderer: (item) => (
                        <span
                          className={
                            "badge text-uppercase badge-pill " +
                            (item.status === "pending"
                              ? "badge-danger"
                              : item.status === "deployed"
                              ? "badge-success"
                              : "badge-danger")
                          }
                        >
                          {item.status === "pending" ||
                          item.status === "assigned"
                            ? "Deployment Pending "
                            : "Deployed"}
                        </span>
                      ),
                    },
                    {
                      heading: "6 Month Review",
                      renderer: (item) =>
                        item.status !== "assigned" ? (
                          <span>
                            {moment(date).format("M") === "7" ||
                            moment(date).format("M") === "1" ? (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={async () => {
                                  if (moment(date).format("M") === "7") {
                                    item?.reviewCount <= 2 &&
                                      (window.location.href =
                                        "/review/" + item.id);
                                  }
                                  if (moment(date).format("M") === "1") {
                                    item?.reviewCount <= 1 &&
                                      (window.location.href =
                                        "/review/" + item.id);
                                  }
                                }}
                                // disabled={moment(item.review_date).format("YYYY-MM-DD") >= moment(reviewDtae).format("YYYY-MM-DD")}
                                // className="badge text-uppercase badge-pill bg-danger text-white"
                                // className="text-secondary"
                                className={` ${
                                  moment(date).format("M") === "7" &&
                                  (item?.reviewCount < 2
                                    ? "text-secondary"
                                    : "text-success")
                                } `}
                              >
                                {moment(date).format("M") === "7" &&
                                  (item?.reviewCount < 2
                                    ? "Pending for july"
                                    : "Done -view report")}
                                {moment(date).format("M") === "1" &&
                                  (item?.reviewCount < 1
                                    ? "pending for january"
                                    : "Done -view report")}
                              </a>
                            ) : (
                              <span
                                className="text-secondary "
                                onClick={() => {
                                  window.location.href = "/review/" + item.id;
                                }}
                              >
                                {" "}
                                View{" "}
                              </span>
                            )}
                          </span>
                        ) : (
                          <span className="text-muted small">
                            Not Avaliable
                          </span>
                        ),
                    },
                    {
                      heading: "",
                      renderer: (item) => (
                        <div
                          className={
                            item.status === "deployed"
                              ? "btn  btn-sm"
                              : "btn btn-sm"
                          }
                          onClick={async () => {
                            window.location.href = "/deploy/" + item.id;
                          }}
                        >
                          {/* {item.status === "deployed" ? <PencilSquare className="h2 text-secondary" /> : `DEPLOY`} */}
                          {item.status === "deployed" ? (
                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                            >
                              <PencilSquare className="h2 text-secondary" />
                            </span>
                          ) : (
                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Deploy"
                            >
                              <FileEarmarkArrowUp className="h2 text-secondary" />
                            </span>
                          )}
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
              {console.log("assignmentData", assignmentData)}
            </div>
          </div>
          {showProfileModal && (
            <>
              <div className="modal-backdrop"></div>
              <div
                className="modal"
                id="modelId"
                tabIndex={-1}
                role="dialog"
                style={{ display: "block" }}
                aria-labelledby="modelTitleId"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-scrollable modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header bg-white">
                      <h5 className="modal-title">View Profile</h5>
                      <button
                        type="button"
                        className="close text-danger"
                        onClick={() => setShowProfileModal(false)}
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body bg-light">
                      <ViewVpProfile profileData={profileData} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </React.Fragment>
      )}
    </>
  );
}
