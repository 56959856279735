import React from 'react';
import logo from '../../assets/images/logo.png';

function PageNotFound() {
    return (
        <div className="d-flex justify-content-center h-100 align-items-center">
            <div>
                <img src={logo} alt="" />
                <div className="mt-5 text-muted">
                    <h1>Page Not Found</h1>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound
