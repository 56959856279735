import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoute } from "./types";

export const PATHS = {
  dashboard: "/dashboard",
  profile: "/profile",
  assignment: "/assignment",
  reports: "/reports",
  districtvp: "/reports/districtvp",
  totalvp: "/reports/totalvp",
  vpcategories: "/reports/vpcategories",
  paid: "/reports/paid",
  forcesdeployment: "/reports/forcesdeployment",
  rangewise: "/reports/rangewise",
  review: "/review/:id",
  files: "/files",
};

export const ROUTES = {
  assign: "/assign/:id",
  addProfile: "/add_Profile",
  deployment: "/deploy/:id",
  editProfile: "/editProfile/:id",
  editAssignment: "/editAssignment/:id",
  profileInspection: "/inspection/:id",
  review: "/review/:id",
};

export const NON_ADMIN = {
  deploy: "/deployment",
  inspection: "/inspection",
};

const adminRoutes = [
  {
    slug: "dashboard",
    label: "Dashboard",
    route: PATHS.dashboard,
    active: false,
  },
  {
    slug: "Profile",
    label: "Profile",
    route: PATHS.profile,
    active: false,
  },
  {
    slug: "assignment",
    label: "Assignment",
    route: PATHS.assignment,
    active: false,
  },
  {
    slug: "reports",
    label: "Reports",
    route: PATHS.reports,
    active: false,
  },
  {
    slug: "filtes",
    label: "Files",
    route: PATHS.files,
    active: false,
  },
];

const userRoutes = [
  {
    slug: "deploment",
    label: "Deployment",
    route: NON_ADMIN.deploy,
    active: false,
  },
  {
    slug: "inspection",
    label: "Inspection",
    route: NON_ADMIN.inspection,
    active: false,
  },
];

export const routeSlice = createSlice({
  name: "routes",
  initialState: {
    routes: [] as IRoute[],
  },
  reducers: {
    setRoutes: (state, { payload }: PayloadAction<"admin" | "user">) => {
      if (payload == "admin") {
        state.routes = adminRoutes;
      } else if (payload === "user") {
        state.routes = userRoutes;
      }
    },
    setActive: (state, action) => {
      if (action.payload?.slug) {
        state.routes.map((r) => {
          if (r.slug === action.payload.slug) {
            r.active = true;
          } else {
            r.active = false;
          }
          return r;
        });
      }
    },
  },
});

export const { setActive } = routeSlice.actions;

export default routeSlice.reducer;
