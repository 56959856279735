import { ROUTES, PATHS } from "./../../reducers/routes";
import {
  FileEarmarkArrowDown,
  ArrowRight,
  PencilSquare,
  Trash,
  FileEarmarkPlus,
} from "react-bootstrap-icons";
import React from "react";
import { useFetch } from "use-http";
import ETable from "../../common/components/ETable/ETable";
import { API_URL } from "../../index";
// import { MapComponent } from "../../common/components/MapComponent";//
import { MapComponent } from "../../common/components/MapComponent";
import { LoadingScreen } from "../../common/components/LoadingScreen";
import ViewVpProfile from "../../common/components/ViewVpProfile";
import moment from "moment";

export const Assignment = () => {
  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);

  const [state, setState] = React.useState<{
    page: number;
    query: string;
    sortBy: string;
    isAsc: boolean;
  }>({
    page: 1,
    query: "",
    sortBy: "id",
    isAsc: true,
  });
  const [declineID, setDeclineID] = React.useState({
    id: null,
    profile: { id: null },
  });
  const [viewLog, setViewLog] = React.useState(false);
  const [declineModel, setDeclineModel] = React.useState(false);
  const [viewModel, setViewModel] = React.useState(false);
  const [viewId, setViewId] = React.useState(null);
  const { get, data, loading: loadingProfile } = useFetch(
    `/items/assignments`,
    { cache: "no-cache" }
  );

  const { data: dataLogs, get: getLogs, loading: loadingLogs } = useFetch(
    `items/profile`
  );
  const {
    data: asignmentLogs,
    get: getasignmentLogs,
    loading: loadingaasignmentLogs,
  } = useFetch(`items/assignments`);

  console.log("aasignmentLogs", asignmentLogs);
  const { patch: profileDeclineStatus } = useFetch(`/items/profile`);
  const { del } = useFetch(`/items/assignments`);

  // // request for data to view deployed data
  const { get: getviewdata, data: profileData } = useFetch(
    `/items/assignments`
  );
  const { get: eachProfileReview, response: eachReview } = useFetch(
    `/items/profile_review`,
    {}
  );

  React.useEffect(() => {
    getviewdata(`${viewId}?fields=*.*.*&single=1`);
  }, [viewId]);

  React.useEffect(() => {
    let url = "?fields=*.*.*&limit=10";
    if (state?.query && state.query.length > 0)
      url += `&meta=filter_count&query=${state.query}`;
    if (state?.page)
      url += `&meta=filter_count&offset=${(state.page - 1) * 10}`;
    if (state?.sortBy)
      url += `&meta=filter_count&sort=${
        state.isAsc ? state.sortBy : "-" + state.sortBy
      }`;
    get(url);
  }, [get, state]);

  const [assignmentData, setAssignmentData] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    let dataAfterReviewcount: Array<object> = [];
    data?.data?.map((each) =>
      eachProfileReview(
        `?filter[profile]=${each?.profile?.id}&filter[review_year]=${moment(
          date
        )?.format("Y")}&meta=filter_count&limit=0`
      ).then((res) => {
        each.reviewCount = res?.meta?.filter_count;
        // dataAfterReviewcount.push(each)
        setAssignmentData((pre) => [...pre, each]);
      })
    );

    // setAssignmentData(dataAfterReviewcount)
  }, [data]);
  console.log("data?.data", data?.data);
  let date = moment();
  return (
    <>
      {!data || !eachReview ? (
        <LoadingScreen />
      ) : (
        <React.Fragment>
          <div className="main-header d-flex justify-content-between">
            <div>
              <h1>Assignments</h1>
            </div>
          </div>
          <div className="main-body">
            <div className="card h-auto">
              <div className="card-body">
                <ETable<any>
                  columns={[
                    // assignment id
                    { key: "id", label: "ID" },
                    // { key: "profile.id", label: "Profile ID" },
                    // { key: "profile.name", label: "Name" },
                    // { key: "profile.category.name", label: "Category" },
                    // { key: "profile.city", label: "City" },
                  ]}
                  totalPages={Math.ceil((data?.meta?.filter_count || 10) / 10)}
                  customColumns={[
                    {
                      heading: "Name",
                      renderer: (item) =>
                        item?.profile?.name ? (
                          <a
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View profile"
                            className="text-secondary "
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setViewId(item.id);
                              setViewModel(true);
                            }}
                          >
                            {item?.profile?.name}
                          </a>
                        ) : (
                          <span>No Document</span>
                        ),
                    },
                    {
                      heading: "Category",
                      renderer: (item) =>
                        item?.profile?.category?.name ? (
                          <span> {item?.profile?.category.name}</span>
                        ) : (
                          <span>Not Available </span>
                        ),
                    },
                    {
                      heading: "City",
                      renderer: (item) =>
                        item?.profile?.city ? (
                          <span> {item?.profile?.city}</span>
                        ) : (
                          <span>Not Available </span>
                        ),
                    },
                    // {
                    //   heading: "Document",
                    //   renderer: (item) => (
                    //     item.profile.document ? <a className="text-secondary" href={API_URL + item.profile.document} target="_blank" rel="noopener noreferrer">View</a> : <span>
                    //       No Document
                    //     </span>
                    //   ),
                    // },
                    {
                      heading: "Status",
                      renderer: (item) => (
                        <span
                          className={
                            "badge text-uppercase badge-pill " +
                            (item.status === "pending"
                              ? "badge-danger"
                              : item.status === "assigned"
                              ? "badge-warning"
                              : item.status === "deployed"
                              ? "badge-success"
                              : "badge-danger")
                          }
                        >
                          {item.status === "assigned"
                            ? "assigned to unit"
                            : item.status}
                        </span>
                      ),
                    },
                    {
                      heading: "6 Month Review",
                      renderer: (item) =>
                        item?.status !== "assigned" &&
                        item?.status !== "pending" ? (
                          <span style={{ cursor: "pointer" }}>
                            {moment(date).format("M") === "7" ||
                            moment(date).format("M") === "1" ? (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={async () => {
                                  if (moment(date).format("M") === "7") {
                                    item?.reviewCount <= 2 &&
                                      (window.location.href =
                                        "/review/" + item.id);
                                  }
                                  if (moment(date).format("M") === "1") {
                                    item?.reviewCount <= 1 &&
                                      (window.location.href =
                                        "/review/" + item.id);
                                  }
                                }}
                                className={`${
                                  moment(date).format("M") === "7" &&
                                  (item?.reviewCount < 2
                                    ? "text-secondary"
                                    : "text-success")
                                } `}
                              >
                                {moment(date).format("M") === "7" &&
                                  (item?.reviewCount < 2
                                    ? "Pending for july"
                                    : "Done -view report")}
                                {moment(date).format("M") === "1" &&
                                  (item?.reviewCount < 1
                                    ? "pending for january"
                                    : "Done -view report")}
                              </a>
                            ) : (
                              <span className="text-primary ">
                                {" "}
                                <span
                                  onClick={() => {
                                    window.location.href = "/review/" + item.id;
                                  }}
                                  className="text-secondary"
                                >
                                  View
                                </span>
                              </span>
                            )}
                          </span>
                        ) : (
                          <span className="text-muted">Not Avaliable</span>
                        ),
                    },
                    {
                      heading: "Report",
                      renderer: (item) => (
                        <div
                          data-toggle="tooltip"
                          data-placement="top"
                          title="View Inspection Report"
                          style={{ cursor: "pointer" }}
                          className="text-secondary"
                          onClick={async () => {
                            // setViewInspectionModel(true)
                            window.location.href = "/inspection/" + item.id;
                          }}
                        >
                          View
                        </div>
                      ),
                    },
                    {
                      heading: "logs",
                      renderer: (item) => (
                        <div
                          // data-toggle="tooltip" data-placement="top" title="View profile"
                          className="text-secondary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setViewId(item.id);
                            setViewLog(true);
                            getLogs(
                              `${item.profile.id}/revisions?fields=*.*,activity.action_by.first_name,activity.action,activity.action_on`
                            );
                            getasignmentLogs(
                              `${item.id}/revisions?fields=*.*,activity.action_by.first_name,activity.action,activity.action_on`
                            );
                          }}
                          // className="btn btn-link font-weight-300"
                        >
                          View
                        </div>
                      ),
                    },
                    {
                      heading: "",
                      renderer: (item) => (
                        <div>
                          {item.status === "pending" && (
                            <div className="d-flex align-items-center">
                              <a
                                style={{ cursor: "pointer" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Assign"
                                onClick={() => {
                                  window.location.href = "/assign/" + item.id;
                                }}
                              >
                                {/* Assign */}
                                <FileEarmarkPlus className="h2 text-primary" />
                              </a>
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Decline"
                                style={{ cursor: "pointer" }}
                                className="ml-2"
                                onClick={async () => {
                                  setDeclineID(item);
                                  setDeclineModel(true);
                                }}
                              >
                                {/* Decline */}
                                <Trash className="h2 text-danger" />
                              </a>
                            </div>
                          )}
                          {item.status === "deployed" && (
                            <div>
                              <a
                                style={{ cursor: "pointer" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit assignment"
                                onClick={async () => {
                                  window.location.href =
                                    "/editAssignment/" + item.id;
                                }}
                              >
                                <PencilSquare className="h2 text-secondary" />
                              </a>
                            </div>
                          )}
                          {item.status === "assigned" && (
                            <div>
                              <a
                                style={{ cursor: "pointer" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit assignment"
                                onClick={async () => {
                                  window.location.href =
                                    "/editAssignment/" + item.id;
                                }}
                              >
                                <PencilSquare className="h2 text-secondary" />
                              </a>
                            </div>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  onChange={(query, sortKey, isSortAsc, page) => {
                    setState({
                      query: query,
                      sortBy: sortKey,
                      isAsc: isSortAsc,
                      page,
                    });
                  }}
                  items={data?.data || []}
                  actionButtons={[]}
                />
              </div>
            </div>
          </div>

          {declineModel && (
            <>
              <div className="modal-backdrop"></div>
              <div
                className="modal"
                id="modelId"
                tabIndex={-1}
                role="dialog"
                style={{ display: "block" }}
                aria-labelledby="modelTitleId"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-scrollable modal-sm"
                  role="document"
                >
                  <div className="modal-content">
                    {/* <div className="modal-header">
                      <h5 className="modal-title">Confirm Decline Request !!</h5>
                      <button
                        type="button"
                        className="close"
                        onClick={() => setDeclineModel(false)}
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div> */}
                    <div className="modal-body">
                      Are you sure you want to decline this request?
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() => setDeclineModel(false)}
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={async () => {
                          await profileDeclineStatus(
                            `/${declineID.profile.id}`,
                            {
                              status: "rejected",
                            }
                          );
                          await del(`/${declineID.id}`);
                          window.location.href = PATHS.assignment;
                        }}
                        data-dismiss="modal"
                      >
                        Decline
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* View profile modal */}
          {viewModel && (
            <>
              <div className="modal-backdrop"></div>
              <div
                className="modal"
                id="modelId"
                tabIndex={-1}
                role="dialog"
                style={{ display: "block" }}
                aria-labelledby="modelTitleId"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-scrollable  modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Profile</h5>
                      <button
                        type="button"
                        className="close text-danger"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setViewModel(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body bg-light">
                      <ViewVpProfile profileData={profileData} />
                      {console.log("profileData", profileData)}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* View profile modal end*/}
          {/* View Log modal */}
          {viewLog && (
            <>
              <div className="modal-backdrop"></div>
              <div
                className="modal"
                id="modelId"
                tabIndex={-1}
                role="dialog"
                style={{ display: "block" }}
                aria-labelledby="modelTitleId"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-scrollable  modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Change Logs</h5>
                      <button
                        type="button"
                        className="close text-danger"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setViewLog(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body bg-light">
                      <div className="row">
                        {/* ENTRY LISTING */}
                        {loadingLogs ? (
                          <p className="text-center w-100">Loading Logs...</p>
                        ) : (
                          <div className="col-12">
                            {/* <h3>All Logs</h3>
                            <p className="text-muted">List of all logs</p> */}
                            <div className="logs">
                              <h3>Profile Logs</h3>
                              {dataLogs?.data &&
                                [...dataLogs?.data]
                                  ?.reverse()
                                  .flatMap((c, i) => (
                                    <>
                                      <div className="row log-item" key={i}>
                                        <div className="col-md-6">
                                          <b>
                                            {c?.activity?.action_by?.first_name}
                                          </b>{" "}
                                          {" -> "}
                                          <b>{c?.activity?.action}d </b> item on{" "}
                                          <b>{c?.activity?.action_on}</b> and
                                          changed {" -> "}
                                        </div>
                                        <div className="col-md-6">
                                          {Object.keys(c?.delta || {}).flatMap(
                                            (k: any, i) => (
                                              <>
                                                <span>
                                                  {/* {c?.delta[k] !=
                                                    c?.data[k] && ( */}
                                                  <span className="text-capitalize text-bold">
                                                    {k?.split("_").join(" ")}
                                                    {" -> "}
                                                  </span>
                                                  {/* )} */}
                                                  {/* {c?.delta[k] ===
                                                    Object(c?.delta[k]) ||
                                                  !Array.isArray(
                                                    c?.delta[k]
                                                  ) ? (
                                                    <>
                                                      {Object.keys(
                                                        c?.delta[k] || {}
                                                      ).flatMap((x: any) => (
                                                        <p>
                                                          <span className="text-capitalize text-bold ml-4">
                                                            {x
                                                              ?.split("_")
                                                              .join(" ")}
                                                          </span>{" "}
                                                          {" -> "}
                                                          <span>
                                                            {JSON.stringify(
                                                              c?.delta[k][x]
                                                            )}
                                                          </span>
                                                        </p>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <b>
                                                      {JSON.stringify(
                                                        c.delta[k]
                                                      ) || ""}
                                                    </b>
                                                  )} */}
                                                  <b>
                                                    {/* {c?.delta[k] !=
                                                      c?.data[k] && */}
                                                    {JSON.stringify(
                                                      c?.delta[k]
                                                    )}
                                                  </b>
                                                  <br />
                                                </span>
                                                {/* {i === 2 &&
                                                  console.log(
                                                    k,
                                                    "data",
                                                    c?.delta[k] == c?.data[k] &&
                                                      c?.delta
                                                  )} */}
                                              </>
                                            )
                                          )}
                                        </div>
                                      </div>
                                      <div className="spacer spacer--xs border border-bottom-1"></div>
                                    </>
                                  ))}
                            </div>

                            <div className="logs">
                              <h3>Assigmnent Logs</h3>
                              {asignmentLogs?.data &&
                                [...asignmentLogs?.data]
                                  ?.reverse()
                                  .flatMap((c, i) => (
                                    <>
                                      <div className="row log-item" key={i}>
                                        <div className="col-md-6">
                                          <b>
                                            {c?.activity?.action_by?.first_name}
                                          </b>{" "}
                                          {" -> "}
                                          <b>{c?.activity?.action}d </b> item on{" "}
                                          <b>{c?.activity?.action_on}</b> and
                                          changed {" -> "}
                                        </div>
                                        <div className="col-md-6">
                                          {Object.keys(c?.delta || {}).flatMap(
                                            (k: any, i) => (
                                              <>
                                                <span>
                                                  {/* {c?.delta[k] !=
                                                    c?.data[k] && ( */}
                                                  <span className="text-capitalize text-bold">
                                                    {k?.split("_").join(" ")}
                                                    {" -> "}
                                                  </span>
                                                  {/* )} */}
                                                  {/* {c?.delta[k] ===
                                                    Object(c?.delta[k]) ||
                                                  !Array.isArray(
                                                    c?.delta[k]
                                                  ) ? (
                                                    <>
                                                      {Object.keys(
                                                        c?.delta[k] || {}
                                                      ).flatMap((x: any) => (
                                                        <p>
                                                          <span className="text-capitalize text-bold ml-4">
                                                            {x
                                                              ?.split("_")
                                                              .join(" ")}
                                                          </span>{" "}
                                                          {" -> "}
                                                          <span>
                                                            {JSON.stringify(
                                                              c?.delta[k][x]
                                                            )}
                                                          </span>
                                                        </p>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <b>
                                                      {JSON.stringify(
                                                        c.delta[k]
                                                      ) || ""}
                                                    </b>
                                                  )} */}
                                                  <b>
                                                    {/* {c?.delta[k] !=
                                                      c?.data[k] && */}
                                                    {JSON.stringify(
                                                      c?.delta[k]
                                                    )}
                                                  </b>
                                                  <br />
                                                </span>
                                                {/* {i === 2 &&
                                                  console.log(
                                                    k,
                                                    "data",
                                                    c?.delta[k] == c?.data[k] &&
                                                      c?.delta
                                                  )} */}
                                              </>
                                            )
                                          )}
                                        </div>
                                      </div>
                                      <div className="spacer spacer--xs border border-bottom-1"></div>
                                    </>
                                  ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* View profile modal end*/}
        </React.Fragment>
      )}
    </>
  );
};
