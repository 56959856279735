import React from 'react'
import { ArrowLeftShort } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'

function Goback() {
    let history = useHistory();
    return (
        <div className="d-flex  align-items-center " onClick={history.goBack} style={{ cursor: "pointer" }}>
            <ArrowLeftShort className="ml-3 mt-2" style={{ fontSize: "2rem", cursor: "pointer" }} />
            {/* <span className="mt-2 ">Go back</span> */}
        </div>
    )
}

export default Goback
