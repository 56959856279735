import React, { useEffect, useState, useRef } from "react";
import { useFetch } from "use-http";
import { CUSTOM_URL } from "../..";
// import ForcesDeployment from "./forcesDeployment";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import {
  Arrow90degLeft,
  ArrowLeft,
  ArrowLeftShort,
  Printer,
} from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Goback from "../../common/components/Goback";
function Paid() {
  let history = useHistory();
  const { data: paidSecurity } = useFetch(
    `${CUSTOM_URL}/directus/custom/paidSecurity`,
    {},
    []
  );

  /* state for forces */
  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);

  const [forceState, setForceState] = useState<any>([]);
  const [rankState, setRankState] = useState<any>([]);
  const [otherforceState, setOtherforceState] = useState<any>([]);
  const [battalionState, setbattalionState] = useState<any>([]);
  /* state for forces */

  const [paidAfterNameData, setPaidAfterNameData] = useState<any>([]);
  useEffect(() => {
    setRankState(rank?.data);
  }, [rank]);

  useEffect(() => {
    setbattalionState(battalion?.data);
  }, [battalion]);

  useEffect(() => {
    setOtherforceState(otherforce?.data);
  }, [otherforce]);

  useEffect(() => {
    setForceState(force?.data);
  }, [force]);

  // function to group by array
  const groupByKey = (list, key) =>
    list?.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  useEffect(() => {
    //removing slashes
    const paidSecurityAfterSlash: any = [];
    paidSecurity?.data?.map((force) => {
      let str = force?.force_deployemnt.replace(/\\|\//g, "");
      let str1 = force?.other_deployment.replace(/\\|\//g, "");
      paidSecurityAfterSlash.push({
        name: force.name,
        address: force.address,
        data: JSON.parse(str),
        otherforce: JSON.parse(str1),
      });
    });

    // a have change name of force from number to string
    let paidAfterName = paidSecurityAfterSlash?.map((e: any) => {
      e?.data?.map((ee: any) => {
        ee.force = forceState && forceState[Number(ee?.force - 1)]?.name;
        ee.battalion =
          battalionState && battalionState[Number(ee?.battalion - 1)]?.name;
        ee.rank = rankState && rankState[Number(ee?.rank - 1)]?.name;
      });

      e?.otherforce?.map((ee: any) => {
        ee.force = forceState && forceState[Number(ee?.force - 1)]?.name;
        // ee.battalion = battalionState && battalionState[Number(ee?.battalion)]?.name;
        // ee.rank = rankState && rankState[Number(ee?.rank)]?.name;
      });

      return e;
    });
    setPaidAfterNameData(paidAfterName);
    console.log("a", paidAfterName);
    // console.log("paidSecurityAfterSlash", paidSecurityAfterSlash)
  }, [paidSecurity, forceState, battalionState]);

  const [finalData, setFinalData] = useState<any>([]);
  const [forceWiseSort, setForceWiseSort] = useState<any>({});
  const [forceData, setForceData] = useState<any>([]);
  const [dataGroupByName, setDataGroupByName] = useState<any>([]);

  console.log("dataGroupByName[key]", forceData);

  // total sum of count
  useEffect(() => {
    // console.log("paidAfterNameData", paidAfterNameData)
    // grouping data by force
    const allrankcount = paidAfterNameData.flatMap((each) => {
      const array: any = [];
      const data = groupByKey(each.data, "rank");
      const otherforce = groupByKey(each.otherforce, "force");

      console.log("otherforce", otherforce);
      // taking count of forces
      Object.keys(data).map((k) => {
        let frank = "";
        let finalCount = 0;
        let force = "";
        let battalion = "";
        data[k].map((forceData) => {
          let count = 0;
          rankState &&
            rankState.map((rank) => {
              if (forceData.rank == rank.name) {
                count += forceData.count;
                frank = forceData.rank;
                force = forceData.force;
                battalion = forceData.battalion;
              }
            });
          finalCount = count;
        });
        array.push({
          name: each.name,
          address: each.address,
          rank: frank,
          force: force,
          battalion: battalion,
          count: finalCount,
        });
      });
      return array;
    });

    setForceWiseSort(groupByKey(allrankcount, "force"));
    const dataGroupByVPName = groupByKey(allrankcount, "name");
    setDataGroupByName(dataGroupByVPName);

    // console.log("dataGroupByVPName", dataGroupByVPName)
    let Finaldata = Object.keys(dataGroupByVPName).map((k) => {
      const data = dataGroupByVPName[k].map(({ rank, count }) => ({
        rank,
        count,
      }));
      return {
        name: k,
        data: data,
      };
    });

    setFinalData(Finaldata.flat());
  }, [paidAfterNameData]);

  useEffect(() => {
    let rankwisefdata = Object.keys(forceWiseSort).map((key) => {
      let groupByRank = groupByKey(forceWiseSort[key], "rank");
      let dataObj = {};
      dataObj[key] = Object.keys(groupByRank).map((rankKey) => {
        let rankObj = {};
        rankObj[rankKey] = groupByRank[rankKey].reduce(
          (acc, { count }) => acc + Number(count),
          0
        );
        return rankObj;
      });
      return dataObj;
    });
    console.log("rankwisefdata");
    setForceData(rankwisefdata.flat());
  }, [forceWiseSort]);
  console.log("forceWiseSort", forceWiseSort);

  const [totalCountRank, settotalCountRank] = useState<any>([]);
  const [totalsum, setTotalSum] = useState<number>(0);
  useEffect(() => {
    const forces = groupByKey(
      finalData.map((data) => data.data).flat(),
      "rank"
    );
    // console.log("forces", forces);

    const totalCountByRank = Object.keys(forces).map((k) => {
      return {
        rank: k,
        count: forces[k].reduce((acc, { count }) => acc + Number(count), 0),
      };
    });

    let totalsum = totalCountByRank.reduce(
      (acc, { count }) => acc + Number(count),
      0
    );
    setTotalSum(totalsum);
    totalCountByRank.push({ rank: "Total count", count: totalsum });
    settotalCountRank(totalCountByRank);

    // console.log(totalCountRank[totalCountRank.length - 1])
    // console.log("totalCountByRank", totalCountByRank)
  }, [finalData]);

  // console.log("finalData PAID totalCountRank", finalData);
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Goback />
      <div className="px-4 w-100" ref={componentRef}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="mb-5 mt-5 text-uppercase">
            Static guard on Payment Basis in Punjab{" "}
          </h3>
          {/* <Printer className="mb-5 mt-5" onClick={handlePrint} /> */}
          <ReactToPrint
            bodyClass={`print-styles`}
            removeAfterPrint={true}
            pageStyle="@page { size: landscape; } "
            trigger={() => (
              <button
                className="btn float-right my-3 btn-sm btn-primary"
                // onClick={handlePrint}
              >
                <Printer className="mr-2" />
                PRINT REPORT
              </button>
            )}
            content={() => componentRef.current!}
          />
        </div>

        {/* <table className="table bg-white table-bordered table-sm text-left text-uppercase">
        <thead>
          <th style={{ width: "10%" }}>Sr.No</th>
          <th style={{ width: "33%" }}>Name of Building </th>
          <th className="row m-0 p-0 border-bottom-0 border-top-0" >
            <th className="" style={{ width: "50%" }}>Rank</th>
            <th className="" style={{ width: "50%" }}>Count</th>
          </th>
          <th style={{ width: "10%" }}>Total </th>
        </thead>
        {finalData.map((data, i) => (
          <tr key={i}>
            <td >{i + 1}</td>
            <td>{data.name}</td>
            {data.data.map((e, i) => (
              <tr key={i} className="row m-0 p-0 border-top-0">
                <td style={{ width: "50%" }}> {e.rank}</td>
                <td style={{ width: "50%" }}> {e.count}</td>
              </tr>
            ))}
            <td> {data.data.reduce((acc, { count }) => (acc += count), 0)}</td>
          </tr>
        ))}
        <tr>
          <th>Total</th>
          <td></td>
          <td></td>
          <th>{totalsum}</th>
        </tr>
      </table> */}

        {/* start testing */}

        <table className="table bg-white table-bordered table-sm text-left text-uppercase">
          <thead>
            <th style={{ width: "10%" }}>Sr.No</th>
            <th style={{ width: "30%" }}>Name of Building </th>
            <th
              className="row m-0 p-0 border-bottom-0 border-top-0"
              style={{ width: "100%" }}
            >
              <th style={{ width: "25%" }}>force</th>
              <th style={{ width: "25%" }}>battalion</th>
              <th style={{ width: "25%" }}>Rank</th>
              <th style={{ width: "25%" }}>Count</th>
            </th>
            <th style={{ width: "10%" }}>Total </th>
          </thead>
          {Object.keys(dataGroupByName).map((key, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{key}</td>
              {/* <td> */}
              {dataGroupByName[key].map((e, i) => (
                <tr key={i} className="row m-0 p-0 border-top-0">
                  <td style={{ width: "25%" }}>{e.force}</td>
                  <td style={{ width: "25%" }}>{e.battalion}</td>
                  <td style={{ width: "25%" }}>{e.rank}</td>
                  <td style={{ width: "25%" }}>{e.count}</td>
                </tr>
              ))}
              {/* </td> */}
              <td>
                {/* {data.data.reduce((acc, { count }) => acc + Number(count))} */}
                {/* {data.data.reduce((acc, { count }) => (acc += count), 0)} */}
                {dataGroupByName[key].reduce(
                  (acc, { count }) => (acc += Number(count)),
                  0
                )}
              </td>
            </tr>
          ))}
          <tr>
            <th>Total</th>
            <td></td>
            <td></td>
            <th>{totalsum}</th>
          </tr>
        </table>

        {/* start testing */}
        <h3 className="mb-5 mt-5 text-uppercase">Rank wise Breakup</h3>
        <table className="table bg-white table-bordered table-sm text-left text-uppercase">
          <thead className="p-0 m-0 w-100">
            <th style={{ width: "10%" }}>Sr.No</th>
            <th style={{ width: "33.33%" }}>Rank</th>
            <th style={{ width: "33.34%" }}>Count</th>
          </thead>
          {totalCountRank.map((data, i) => (
            <tr key={i} className="w-100 p-0 m-0">
              <td>{i + 1}</td>
              <td>{data.rank}</td>
              <td>{data.count}</td>
            </tr>
          ))}
        </table>

        <h3 className="mb-5 mt-5 text-uppercase">Force wise Breakup</h3>
        <table className="table bg-white table-bordered table-sm text-left text-uppercase">
          {/* console.log("each", each[0], each[1]) */}
          <thead>
            <th style={{ width: "10%" }}>Sr.No</th>
            <th style={{ width: "20%" }}>FORCE</th>
            <table className="m-0 p-0" style={{ width: "100%" }}>
              <th style={{ width: "50%" }}>RANK</th>
              <th style={{ width: "50%" }}>COUNT</th>
            </table>
          </thead>
          <tbody>
            {forceData?.flat().map((each, index) => (
              <>
                {Object.keys(each)?.map((key, i) => (
                  <tr key={i}>
                    <td>{index + 1}</td>
                    <td>{key}</td>
                    {each[key].map((rankKey) => (
                      <tr className="row m-0 p-0" style={{ width: "100%" }}>
                        {Object.entries(rankKey).map((each1) => (
                          <React.Fragment>
                            <td style={{ width: "50%" }}>{each1[0]}</td>
                            <td style={{ width: "50%" }}>{String(each1[1])}</td>
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                  </tr>
                ))}
              </>
            ))}
            {/* <tr>
            <td> total</td>
            <td></td>
            <td> 100</td>
          </tr> */}
          </tbody>

          {/* {
          forceData.map((data) => (
            <tr>
              {
                Object.keys(data).map((key) => (
                  <>
                    <td>{key}</td>
                    <td>{key}</td>
                    <>
                      ))
              }


                      ))
                    </tr>
        } */}
        </table>

        {/* <div className="w-50">
        <ForcesDeployment />
      </div> */}

        {/* new table */}
        {/* <table
        className="table table-bordered  bg-white modify-table text-uppercase "
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Name of building</th>

            <th colSpan={2}>ranks</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th></th>
            <th></th>

            <th>Positions</th>
            <th>count</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={4}>1.</td>
            <td rowSpan={4}>hdjashdkjasdskaj</td>
            <td>hdjashdkj</td>
            <td>10</td>
            <td rowSpan={4}>10</td>
          </tr>
          <tr>
            <td>hdjashdkj</td>
            <td>10</td>
          </tr>
          <tr>
            <td>hdjashdkj</td>
            <td>10</td>
          </tr>
        </tbody>
      </table>
  
   */}
      </div>
    </div>
  );
}

export default Paid;
