import React, { ReactElement, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import ETable from "../../common/components/ETable/ETable";
import { LoadingScreen } from "../../common/components/LoadingScreen";
import {
  BellFill,
  CardList,
  PeopleFill,
  PencilSquare,
  FileEarmarkArrowUp,
  Plus,
  Search,
  Truck,
  JournalBookmark,
} from "react-bootstrap-icons";
import { useFetch } from "use-http";
import { MapComponent } from "../../common/components/MapComponent";
import { PieChart } from "../../common/components/PieChartComponent";
import moment from "moment";
import { API_URL } from "../../index";
import { Doughnut } from "react-chartjs-2";
export default function HomePage(): ReactElement {
  const { get, data: totalProfile } = useFetch(
    `/items/profile?meta=total_count`,
    {},
    []
  );
  const { get: all_profile, data: allprofileData } = useFetch(
    `/items/profile`,
    {},
    []
  );
  const { get: all_category, data: allCategory } = useFetch(
    `/items/profile_category`,
    {},
    []
  );
  const { get: vp_category, data: vpCategory } = useFetch(
    `/items/vp_category`,
    {},
    []
  );
  const { get: force, data: allForce } = useFetch(`/items/force`, {}, []);
  const { get: otherforce, data: other_force } = useFetch(
    `/items/other_force`,
    {},
    []
  );

  const { get: assignments, data: totalAssignment } = useFetch(
    `/items/assignments?meta=total_count`,
    {},
    []
  );
  const {
    get: allAssignmentsData,
    data: allAssignmentsDataResult,
    loading: loadingAssignment,
  } = useFetch(`/items/assignments?fields=*.*.*`, {}, []);
  const { get: forceData, data: allForceData } = useFetch(
    `/items/assignments?fields=force_deployemnt`,
    {},
    []
  );
  const { get: otherDeployment, data: otherDeploymentData } = useFetch(
    `/items/assignments?fields=other_deployment`,
    {},
    []
  );

  const [secCategory, setSecCategory] = React.useState<any>([]);
  const [vp, setVp] = React.useState<any>([]);
  const [vp2, setVp2] = React.useState<any>([]);
  const [vp3, setVp3] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<number>(1);
  const [lableCategory, setLableCategory] = React.useState<any>([]);
  const [vplableCategory, setvpLableCategory] = React.useState<any>([]);
  const [showProfileModal, setShowProfileModal] = React.useState(false);
  const [totalReviewPending, setTotalReviewPending] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  //force deployment array
  const [arrr, setArrr] = React.useState<any>([]);
  const [forceChartArray, setForceChartArray] = React.useState<any>([]);
  const [otherForceArray, setOtherForceArray] = React.useState<any>([]);
  const [otherChartForceArray, setOtherChartForceArray] = React.useState<any>(
    []
  );

  {
    /* sorting chart data according to sequence */
  }

  let forceSequence = ["PAP", "IRB", "CDO", "DISTRICT POLICE", "CID", "PHG"];
  let otherForceSequence = ["CISF", "CRPF", "RPF", "PESCO", "PRIVATE SECURITY"];

  {
    /* function to sort array of object in sequence */
  }
  function sortFormationSequence(origSeq, reqSeq, key) {
    let smallcaseReqSeq = reqSeq?.map((name) => name.toLowerCase());
    origSeq.sort((a, b) => {
      let A = a[key].toLowerCase();
      let B = b[key].toLowerCase();
      if (smallcaseReqSeq.indexOf(A) > smallcaseReqSeq.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return origSeq;
  }
  useEffect(() => {
    let ordered_origSeqForce = sortFormationSequence(
      arrr,
      forceSequence,
      "name"
    );
    setForceChartArray(ordered_origSeqForce);
  }, [arrr]);
  useEffect(() => {
    let ordered_origSeqOtherForce = sortFormationSequence(
      otherForceArray,
      otherForceSequence,
      "name"
    );
    // filterring other data
    setOtherChartForceArray(
      ordered_origSeqOtherForce.filter((e) => e.name.toLowerCase() !== "others")
    );
  }, [otherForceArray]);

  // bar chart force
  useEffect(() => {
    allForce?.data?.map((force, i) => {
      // forces
      let ar = 0;
      let force_name = force.name;
      allForceData?.data?.map((alldata) => {
        //allassignForces
        alldata?.force_deployemnt?.map((deploy) => {
          if (deploy?.force == force.id) {
            ar += Number(deploy?.count);
            force_name = force.name;
          }
        });
      });
      let forceChartObj = {
        count: ar,
        name: force_name,
      };
      setArrr((prev) => [...prev, forceChartObj]);
    });
  }, [allForceData]);

  // barchart other force
  useEffect(() => {
    other_force?.data?.map((force, i) => {
      // forces
      let ar = 0;
      let force_name = force.name;
      otherDeploymentData?.data?.map((alldata) => {
        //allassignForces
        alldata?.other_deployment?.map((deploy) => {
          if (deploy?.force == force.id) {
            ar += Number(deploy?.count);
          }
        });
      });
      let forceChartObj = {
        count: ar,
        name: force_name,
      };
      setOtherForceArray((prev) => [...prev, forceChartObj]);
    });
  }, [otherDeploymentData]);

  React.useEffect(() => {
    // category names array
    let catnames: any[] = [];
    allCategory?.data?.map((i) => catnames.push(i));
    setLableCategory(catnames);
    // VP category names array
    let vpcatnames: any[] = [];
    vpCategory?.data?.map((i) => vpcatnames.push(i));
    setvpLableCategory(vpcatnames);
  }, [allprofileData, allCategory, vpCategory]);

  React.useEffect(() => {
    let cat1: any[] = [];
    cat1 = lableCategory.map((e) => {
      let count = allprofileData?.data.filter((x) => {
        return x.category == e.id;
      }).length;

      return {
        count: count,
        name: e.name,
      };
    });
    // cat1 = lableCategory.map((e) =>
    //   allprofileData?.data.filter((x) => {
    //     return x.category == e.id;
    //   }).length
    // );

    let secqurityCategorySequence = [
      "VP-Category 1",
      "VP-Category 2",
      "VP-Category 3",
      "Buildings with Static Guard",
      "Banks",
      "Protected Places",
      "Miscellaneous",
    ];
    let ordered_origSeqForce = sortFormationSequence(
      cat1,
      secqurityCategorySequence,
      "name"
    );
    setSecCategory(ordered_origSeqForce);
  }, [lableCategory]);

  React.useEffect(() => {
    lableCategory.map((ct) => {
      if (ct.name == "VP-Category 1") {
        let cat1: any[] = [];
        cat1 = vplableCategory.map((e) => {
          let count = allprofileData?.data.filter((x) => {
            console.log("AllProfileData x", ct.id);
            return x.vp_category == e.id && ct.id == x.category;
          }).length;
          return {
            count: count,
            name: e.name,
            Category: e,
          };
        });
        cat1.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else {
            return -1;
          }
        });
        setVp(cat1);
        console.log("cat 1", cat1);
      }
      if (ct.name == "VP-Category 2") {
        let cat1: any[] = [];
        cat1 = vplableCategory.map((e) => {
          let count = allprofileData?.data.filter((x) => {
            console.log("AllProfileData x", ct.id);
            return x.vp_category == e.id && ct.id == x.category;
          }).length;
          return {
            count: count,
            name: e.name,
            Category: e,
          };
        });
        cat1.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else {
            return -1;
          }
        });
        setVp2(cat1);
        console.log("cat 1", cat1);
      }
      if (ct.name == "VP-Category 3") {
        let cat1: any[] = [];
        cat1 = vplableCategory.map((e) => {
          let count = allprofileData?.data.filter((x) => {
            console.log("AllProfileData x", ct.id);
            return x.vp_category == e.id && ct.id == x.category;
          }).length;
          return {
            count: count,
            name: e.name,
            Category: e,
          };
        });
        cat1.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else {
            return -1;
          }
        });
        setVp3(cat1);
        console.log("cat 1", cat1);
      }
    });
  }, [vplableCategory]);

  //pie chart
  let state = {
    options: {
      colors: ["#F53066", "#2F33F5", "#E1E655", "#6D15F5", "#002C58"],
      // labels: ['category-1', 'category-2', 'category-3']
      labels: secCategory.map((e) => e.name),
    },
    series: secCategory.map((e) => e.count),
  };

  //pic cahrt
  let VPstate = {
    options: {
      colors: ["#1E1A65", "#FF9F40", "#0041E6", "#FD1C3A", "#044171"],
      // labels: ['VP-category-1', 'VP-category-2', 'VP-category-3']
      // labels: vplableCategory.map((e) => e.name),
      labels: vp.map((e) => e.name),
    },
    series: vp.map((e) => e.count),
  };

  let VP2state = {
    options: {
      colors: ["#1E1A65", "#FF9F40", "#0041E6", "#FD1C3A", "#044171"],
      // labels: ['VP-category-1', 'VP-category-2', 'VP-category-3']
      // labels: vplableCategory.map((e) => e.name),
      labels: vp2.map((e) => e.name),
    },
    series: vp2.map((e) => e.count),
  };

  let VP3state = {
    options: {
      colors: ["#1E1A65", "#FF9F40", "#0041E6", "#FD1C3A", "#044171"],
      // labels: ['VP-category-1', 'VP-category-2', 'VP-category-3']
      // labels: vplableCategory.map((e) => e.name),
      labels: vp3.map((e) => e.name),
    },
    series: vp3.map((e) => e.count),
  };
  React.useEffect(() => {
    let reviewDtae = moment(); //today date
    // let sixm = reviewDtae.add(6, 'M').format('YYYY-MM-DD'); //six month later date
    // let pendingArray = allAssignmentsDataResult?.data?.filter((each) => each.review_date === sixm);
    let pendingArray = allAssignmentsDataResult?.data?.filter(
      (each) =>
        moment(each.review_date).format("YYYY-MM-DD") <=
        moment(reviewDtae).format("YYYY-MM-DD")
    );
    setTotalReviewPending(pendingArray);
  }, [allAssignmentsDataResult]);

  // bar chart details of Force
  let barstate: any = {
    series: [
      {
        name: "count",
        // data: [...arrr],
        data: forceChartArray?.map((e) => e.count),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      colors: ["#0041E6"],
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        // categories: allForce?.data?.map((e) => e.name),
        categories: forceChartArray?.map((e) => e.name),
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      title: {
        text: "Force assignment",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  };

  // bar chart details of Force
  let otherForceState: any = {
    series: [
      {
        name: "count",
        // data: [...otherForceArray],
        data: otherChartForceArray.map((e) => e.count),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      colors: ["#0041E6"],
      plotOptions: {
        bar: {
          borderRadius: 10,
          barWidth: 30,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        // categories: other_force?.data?.map((e) => e.name),
        categories: otherChartForceArray?.map((e) => e.name),
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      title: {
        text: " Other Force",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  };

  return (
    <>
      {loadingAssignment ? (
        <LoadingScreen />
      ) : (
        <React.Fragment>
          <div className="container-fluid ">
            <div className="row w-100">
              <div className="col-lg-12">
                <h1 className="pb-4 pt-4">Dashboard</h1>
                <div className="row">
                  <div className="col-md-4">
                    <div className="card border-0 p-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h1>{totalProfile?.meta.total_count}</h1>
                          <p className="mb-0">Total Profile</p>
                        </div>
                        <div className="text-secondary display-3">
                          <PeopleFill />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card border-0 p-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h1>{totalAssignment?.meta.total_count}</h1>
                          <p className="mb-0">Total Assignment</p>
                        </div>
                        <div className="text-success display-3">
                          <CardList />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div
                      className="card border-0 p-3"
                      onClick={() => {
                        setShowProfileModal(true);
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div>

                          <h1>
                            {totalReviewPending?.length
                              ? totalReviewPending?.length
                              : 0}
                          </h1>

                          <p className="mb-0">Pending Reviews </p>
                        </div>
                        <div className="text-warning display-3">
                          <JournalBookmark />
                        </div>
                      </div>
                    </div>
                  </div>
               
                */}
                </div>
              </div>
            </div>
            {/* <div className="spacer spacer--sm"></div> */}

            <div className="row mt-5 w-100">
              <div className="col-12">
                {/* map view  */}
                <div className="card">
                  <div className="card-header font-weight-bold text-uppercase">
                    Punjab POLICE DEPLOYMENT
                  </div>
                  <div className="card-body p-0">
                    {/* <MapComponent height="60vh" /> */}
                    <Chart
                      options={barstate.options}
                      series={barstate.series}
                      type="bar"
                      height="300px"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-5">
                {/* map view  */}
                <div className="card ">
                  <div className="card-header font-weight-bold">
                    OTHER FORCE DEPLOYMENT
                  </div>
                  <div className="card-body p-0">
                    {/* <MapComponent height="60vh" /> */}
                    <Chart
                      options={otherForceState.options}
                      series={otherForceState.series}
                      type="bar"
                      height="300px"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="spacer spacer--sm"></div> */}
            {/* <div className="mb-5 mt-5 pt-3" > */}
            <div className="row my-5 w-100 ">
              <div className="col-md-12">
                <div className="card p-4">
                  <div className="col-md-12">
                    <div className="card-title">
                      <h3>Security Category</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-8">
                        <Chart
                          options={state.options}
                          series={state.series}
                          type="pie"
                          height="360px"
                        />
                        {/* <Doughnut
                          width={300}
                          height={300}
                          options={{
                            responsive: false,
                          }}
                          data={{
                            labels: state.options.labels,
                            datasets: [
                              {
                                label: "",
                                data: state.series,
                                backgroundColor: [
                                  "#FF0000",
                                  "#FFFF00",
                                  "#000080",
                                  "#FFA500",
                                  "#800000",
                                  "#008000",
                                ],
                              },
                            ],
                          }}
                        /> */}
                      </div>
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="col-lg-12">
                            <table className="table border-0">
                              <thead>
                                <tr>
                                  <th>Category</th>
                                  <th>Count</th>
                                </tr>
                              </thead>
                              <tbody>
                                {secCategory.map((e, i) => (
                                  <tr key={i}>
                                    <td>{e.name}</td>
                                    <td>{e.count}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-header w-100">
                <div className="col-md-12">
                  <div className="card-title">
                    <h3>Selected Category</h3>
                  </div>
                  <select
                    defaultValue={1}
                    onChange={(e) =>
                      setSelectedCategory(Number(e.target.value))
                    }
                    className="custom-select w-25"
                    name="sort"
                    id="sort"
                  >
                    <option value={1}>VP Category 1</option>
                    <option value={2}>VP Category 2</option>
                    <option value={3}>VP Category 3</option>
                  </select>
                </div>
              </div>
              {selectedCategory === 1 ? (
                <div className="col-md-12 mt-2 my-5">
                  <div className="card p-4">
                    <div className="col-md-12">
                      <div className="card-title">
                        <h3>VP Category 1</h3>
                      </div>
                      <div className="row">
                        <div className="col-lg-8">
                          <Chart
                            options={VPstate.options}
                            series={VPstate.series}
                            type="pie"
                            height="360px"
                          />
                          {/* <Doughnut
                          width={300}
                          height={500}
                          options={{
                            responsive: false,
                          }}
                          data={{
                            labels: VPstate.options.labels,
                            datasets: [
                              {
                                label: "",
                                data: VPstate.series,
                                backgroundColor: [
                                  "#FF0000",
                                  "#FFFF00",
                                  "#000080",
                                  "#FFA500",
                                  "#800000",
                                  "#008000",
                                ],
                              },
                            ],
                          }}
                        /> */}
                        </div>
                        <div className="col-lg-4">
                          <div className="row">
                            <div
                              className="col-lg-12"
                              style={{
                                height: "340px",
                                overflowY: "scroll",
                              }}
                            >
                              <table className="table border-0">
                                <thead>
                                  <tr>
                                    <th>Sub Category</th>
                                    <th>Count</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vp.map((e, i) => (
                                    <tr key={i}>
                                      <td>{e.name}</td>
                                      <td>{e.count}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : selectedCategory === 2 ? (
                <div className="col-md-12 mt-2 my-5">
                  <div className="card p-4">
                    <div className="col-md-12">
                      <div className="card-title">
                        <h3>VP Category 2</h3>
                      </div>
                      <div className="row">
                        <div className="col-lg-8">
                          <Chart
                            options={VP2state.options}
                            series={VP2state.series}
                            type="pie"
                            height="360px"
                          />
                          {/* <Doughnut
                          width={300}
                          height={500}
                          options={{
                            responsive: false,
                          }}
                          data={{
                            labels: VPstate.options.labels,
                            datasets: [
                              {
                                label: "",
                                data: VPstate.series,
                                backgroundColor: [
                                  "#FF0000",
                                  "#FFFF00",
                                  "#000080",
                                  "#FFA500",
                                  "#800000",
                                  "#008000",
                                ],
                              },
                            ],
                          }}
                        /> */}
                        </div>
                        <div className="col-lg-4">
                          <div className="row">
                            <div
                              className="col-lg-12"
                              style={{
                                height: "340px",
                                overflowY: "scroll",
                              }}
                            >
                              <table className="table border-0">
                                <thead>
                                  <tr>
                                    <th>Sub Category</th>
                                    <th>Count</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vp2.map((e, i) => (
                                    <tr key={i}>
                                      <td>{e.name}</td>
                                      <td>{e.count}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : selectedCategory === 3 ? (
                <div className="col-md-12 mt-2 my-5">
                  <div className="card p-4">
                    <div className="col-md-12">
                      <div className="card-title">
                        <h3>VP Category 3</h3>
                      </div>
                      <div className="row">
                        <div className="col-lg-8">
                          <Chart
                            options={VP3state.options}
                            series={VP3state.series}
                            type="pie"
                            height="360px"
                          />
                          {/* <Doughnut
                          width={300}
                          height={500}
                          options={{
                            responsive: false,
                          }}
                          data={{
                            labels: VPstate.options.labels,
                            datasets: [
                              {
                                label: "",
                                data: VPstate.series,
                                backgroundColor: [
                                  "#FF0000",
                                  "#FFFF00",
                                  "#000080",
                                  "#FFA500",
                                  "#800000",
                                  "#008000",
                                ],
                              },
                            ],
                          }}
                        /> */}
                        </div>
                        <div className="col-lg-4">
                          <div className="row">
                            <div
                              className="col-lg-12"
                              style={{
                                height: "340px",
                                overflowY: "scroll",
                              }}
                            >
                              <table className="table border-0">
                                <thead>
                                  <tr>
                                    <th>Sub Category</th>
                                    <th>Count</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vp3.map((e, i) => (
                                    <tr key={i}>
                                      <td>{e.name}</td>
                                      <td>{e.count}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* </div> */}
          </div>
          {showProfileModal && (
            <>
              <div className="modal-backdrop"></div>
              <div
                className="modal"
                id="modelId"
                tabIndex={-1}
                role="dialog"
                style={{ display: "block" }}
                aria-labelledby="modelTitleId"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-scrollable modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header bg-white">
                      <h5 className="modal-title">Pending profiles</h5>
                      <button
                        type="button"
                        className="close"
                        onClick={() => setShowProfileModal(false)}
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    {/* body start */}

                    <div className="modal-body bg-light">
                      <ETable<any>
                        columns={[
                          // { key: "id", label: "Assignment ID" },
                          { key: "id", label: "ID" },
                          // { key: "profile.name", label: "Profile Name" },
                          { key: "profile.name", label: "Name" },
                          { key: "profile.category.name", label: "Category" },
                          { key: "profile.city", label: "City" },
                        ]}
                        totalPages={Math.ceil(
                          (totalReviewPending?.length || 10) / 10
                        )}
                        onChange={(query, sortKey, isSortAsc, page) => {
                          // setState({
                          //   query,
                          //   sortBy: sortKey,
                          //   isAsc: isSortAsc,
                          //   page,
                          // });
                        }}
                        items={totalReviewPending ? totalReviewPending : []}
                        actionButtons={[]}
                        customColumns={[
                          {
                            heading: "Document",
                            renderer: (item) =>
                              item.document_on_deployment ? (
                                <a
                                  className="text-secondary"
                                  href={API_URL + item.document_on_deployment}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View
                                </a>
                              ) : (
                                <span>Not available</span>
                              ),
                          },
                          {
                            heading: "Status",
                            renderer: (item) => (
                              <span
                                className={
                                  "badge text-uppercase badge-pill " +
                                  (item.status === "pending"
                                    ? "badge-danger"
                                    : item.status === "deployed"
                                    ? "badge-success"
                                    : "badge-danger")
                                }
                              >
                                {item.status === "pending" ||
                                item.status === "assigned"
                                  ? "Deployment Pending "
                                  : "Review Pending"}
                              </span>
                            ),
                          },
                        ]}
                      />
                      {/* {

                    totalReviewPending?.map((each: any, index) => (
                      <li>{each?.profile?.name}</li>
                    ))
                  } */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </React.Fragment>
      )}
    </>

    //   else {
    //   return <LoadingScreen />
    // }
  );
}
