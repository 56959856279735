import React, { ReactElement } from "react";
import useFetch from "use-http";

interface Props {
  onUpload: (url: string, id: number) => void;
  setrefresh?: any;
  refresh?: boolean,
  type?: string

}

export default function EFileUpload({ onUpload, setrefresh, refresh = false, type = "image/*" }: Props): ReactElement {
  const { post } = useFetch("/files?fields=private_hash&t=" + new Date().valueOf(), { cache: "no-cache", });

  const ref = React.useRef<HTMLInputElement>(null);
  const uploadFile = async (file?: File | null) => {
    const data = new FormData();
    if (file && file.size < 2000000) {
      console.log("file", file)
      data.append("file", file);
      const res = await post(data);
      onUpload(`_/assets/${res.data.private_hash}`, res?.data?.id);
      if (refresh) {
        console.log(refresh)
        if (ref.current) {
          ref.current!.value = ""
          setrefresh();
        };

      }
    } else {
      alert("Please select Image less than 2MB")
    }
  };
  return (
    <div>
      <div className="form-group">
        <input
          // style={{ display: "none" }}
          // className="custom-file-input"
          ref={ref}
          type="file"
          name="imageFile"
          onChange={(e) => {
            uploadFile(e.currentTarget.files?.item(0));
          }}
          id="imageFile"
          placeholder="Upload Image"
          accept={type}
        />
        {/* <div className="row pl-3 d-flex align-items-center" >
          <label className="btn btn-primary btn-sm" htmlFor="imageFile">
            Select file
          </label>
          <div className="text-muted small ml-2">
            test
          </div>
        </div> */}
        {/* <label className="custom-file-label" htmlFor="imageFile">Choose file</label> */}
      </div>
    </div>
  );
}
