import React, { useState, useEffect } from "react";
import {
  ArrowRight,
  DistributeVertical,
  FileEarmarkText,
  Plus,
} from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IRoute, IUser } from "../../reducers/types";
import { FileEarmarkArrowDown } from "react-bootstrap-icons";
import { Trash, Archive, PersonFill, PlusSquare } from "react-bootstrap-icons";
import useFetch from "use-http";
import ViewVpProfile from "../../common/components/ViewVpProfile";
import { API_URL } from "../..";
import moment from "moment";
import { LoadingScreen } from "../../common/components/LoadingScreen";
import EFileUpload from "../../common/components/EFileUpload/EFileUpload";
import logo from "../../assets/images/logo.png";

function Review() {
  // http://128.199.18.110:6060/admin/activity?meta=filter_count&offset=2000&limit100

  // http://128.199.18.110:6060/admin/revisions?fields=*,activity.id&meta=filter_count&offset=1600&limit100

  const { id } = useParams<{ id: string }>();
  const [user]: [IUser] = useSelector((state: any) => [state.user.user]);
  const [profileData, setprofileData] = useState<any>();
  const [review, setReview] = useState<any>([]);
  const [filteredReview, setFilteredReview] = useState<any>();
  const [viewInspectionModel, setViewInspectionModel] = useState(false);

  const [year, setYear] = useState<string>("-1");
  const [count, setCount] = useState<number>(0);

  const [
    viewMoreInspectionData,
    setViewMoreInspectionData,
  ] = React.useState<any>();

  const [refresh, setRefresh] = React.useState(false);
  const [viewReportModal, setViewReportModal] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(1);

  let date = moment();

  const [inspectionDate, setInspectionDate] = useState("");
  const [sanctionDeployementFeild, setSanctionDeployementFeild] = useState<any>(
    { isOk: "", comment: "" }
  );
  const [documentsUploadedFeild, setDocumentsUploadedFeild] = useState<any>({
    isOk: "",
    comment: "",
  });
  const [deployByUnitFeild, setDeployByUnitFeild] = useState<any>({
    isOk: "",
    comment: "",
  });
  const [deployByOtherForceFeild, setDeployByOtherForceFeild] = useState<any>({
    isOk: "",
    comment: "",
  });
  const [securityEquipmentsFeild, setSecurityEquipmentsFeild] = useState<any>({
    isOk: "",
    comment: "",
  });

  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);
  const { data: ranges } = useFetch(`items/ranges`, {}, []);

  const { get, loading: loadingProfile } = useFetch(
    `/items/assignments/${id}?fields=*.*.*&single=1`
  );
  const { post, response: reviewdone } = useFetch(`/items/profile_review`, {});
  const { get: eachProfileReview, response: eachReview } = useFetch(
    `/items/profile_review`,
    {}
  );
  const {
    get: singleProfiledata,
    response: eachProfileReviewAlldata,
    loading: reviewProfile,
  } = useFetch(`/items/profile_review`, {});

  const patchInspection = async (id) => {
    // await setSanctionDeployement([sanctionDeployementFeild])
    let postObject = {
      profile: id,
      sanctioned_deployment: [sanctionDeployementFeild],
      documents_inspection: [documentsUploadedFeild],
      deploy_by_unit_inspection: [deployByUnitFeild],
      deploy_by_otherforce_inspection: [deployByOtherForceFeild],
      security_equipments_inspection: [securityEquipmentsFeild],
      date_of_review: inspectionDate,
      review_year: year,
    };
    await post(postObject);
    if (reviewdone.ok) {
      alert("Review done successfully");
      setSanctionDeployementFeild({ isOk: "", comment: "" });
      setDocumentsUploadedFeild({ isOk: "", comment: "" });
      setViewInspectionModel(false);
    } else {
      alert("Something Went Wrong");
    }
  };

  console.log(year, count);
  useEffect(() => {
    get().then((res) => {
      setprofileData(res);
      console.log("res", res);

      singleProfiledata(`?filter[profile]=${res?.data?.profile?.id}`).then(
        () => {
          setReview(eachProfileReviewAlldata?.data?.data);
        }
      );

      // getAllInspection(`?filter[profile]=${res?.data?.profile?.id}`).then((res) => {
      //     setInspection(res?.data)
      // })
      eachProfileReview(
        `?filter[profile]=${
          res?.data?.profile?.id
        }&filter[review_year]=${moment(date)?.format(
          "Y"
        )}&meta=filter_count&limit=0`
      ).then((res) => {
        console.log("res", res?.meta?.filter_count);
        setCount(res?.meta?.filter_count);
      });
    });
    // taking today date
    setInspectionDate(moment(date).format("YYYY-MM-DD"));
    setYear(moment(date).format("Y"));
  }, []);

  const [viewReportType, setViewReportType] = useState<string>("7");

  useEffect(() => {
    setFilteredReview(
      review?.filter(
        (ins) => viewReportType == moment(ins.date_of_review).format("M")
      )
    );
  }, [viewReportType, review]);

  return (
    <>
      {loadingProfile || reviewProfile ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="">
            <div className="main-header d-flex justify-content-between ">
              <div className="card-title text-uppercase">
                <h1>
                  6 months Review:{" "}
                  <span className="text-secondary">
                    {profileData?.data?.profile.name}
                  </span>
                </h1>
              </div>
              {
                // user?.role === "1" && <div className="float-right">
                //     <a className="btn btn-secondary text-uppercase" onClick={() => { setViewInspectionModel(true) }}><Plus />New Inspection</a>
                // </div>
                <div className="float-right">
                  {/* if month jan  and count is less than 1  */}
                  {/* if month july  and count is less than 2  */}
                  {moment(date).format("M") === "1" && count < 1 ? (
                    <button
                      className="btn btn-secondary text-uppercase"
                      onClick={() => {
                        setViewInspectionModel(true);
                      }}
                    >
                      <Plus />
                      New Review
                    </button>
                  ) : (
                    <></>
                  )}
                  {moment(date).format("M") === "8" && count < 2 ? (
                    <button
                      className="btn btn-secondary text-uppercase"
                      onClick={() => {
                        setViewInspectionModel(true);
                      }}
                    >
                      <Plus />
                      New Review
                    </button>
                  ) : (
                    <></>
                  )}

                  {/* {(moment(date).format("M") === "1") && (count == 1) || (moment(date).format("M") === "7") && (count < 2) ?
                                            <button className="btn btn-secondary text-uppercase" onClick={() => { setViewInspectionModel(true) }}><Plus />New Review</button>
                                            : <></>
                                        } */}
                </div>
              }
            </div>

            <div className="main-body w-100">
              <div className="row pl-4">
                <p className="small text-muted">
                  Review can be done only in the month of January and july
                </p>
              </div>
              <div className="card h-auto">
                <div className="card-header">
                  <ul
                    className="nav nav-tabs border-0"
                    id="myTab"
                    role="tablist"
                  >
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={() => {
                        setActiveTab(1);
                        setViewReportType("1");
                      }}
                    >
                      <a
                        className={`${
                          activeTab == 1 && "bg-white active "
                        } nav-link`}
                        id="home-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        January
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={() => {
                        setActiveTab(2);
                        setViewReportType("7");
                      }}
                    >
                      <a
                        className={`${
                          activeTab == 2 && "bg-white  active"
                        } nav-link`}
                        id="profile-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        July{" "}
                      </a>
                    </li>
                    {/* <li className="nav-item" role="presentation" onClick={() => { setActiveTab(3) }}>
                                                <a
                                                    className={`${activeTab == 3 && "bg-white active"} nav-link`}
                                                    id="contact-tab" data-toggle="tab" role="tab" aria-controls="contact" aria-selected="false">THREAT BASIS </a>
                                            
                                                <input type="date" className="form-control form-control-sm" />
                                            </li> */}
                  </ul>
                </div>
                <div className="card-body">
                  <div>
                    <div
                      className="tab-content"
                      id="myTabContent"
                      style={{ minHeight: "40vh" }}
                    >
                      {(filteredReview?.length <= 0 ||
                        review === undefined) && (
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          <img
                            src={logo}
                            alt=""
                            width="100px"
                            height="100px"
                            style={{
                              marginTop: "20px",
                              opacity: ".6",
                              objectFit: "contain",
                            }}
                          />
                          <h3 className="w-100  d-flex align-items-center justify-content-center m-4 text-muted">
                            {" "}
                            No Review done
                          </h3>
                        </div>
                      )}
                      {filteredReview?.map((ins, i) => (
                        <div
                          className="pt-3 m-2 mb-0"
                          key={i}
                          style={{ borderRadius: "5px" }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="mb-0">
                              <strong> Date of Inspection:</strong>{" "}
                              <span className=""> {ins.date_of_review}</span>
                            </div>
                            <div
                              className="btn btn-sm btn-outline-primary small float-right m-1"
                              onClick={() => {
                                setViewReportModal(true);
                                setViewMoreInspectionData(ins);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              View Report{" "}
                            </div>
                          </div>
                          <div className="pt-0"></div>
                          {ins?.jsr_documents?.jsr.length > 0 && (
                            <div>
                              <small>
                                <strong className="text-muted">
                                  Documents uploaded
                                </strong>
                              </small>
                            </div>
                          )}
                          {ins?.jsr_documents?.jsr?.map((e, i) => (
                            <li
                              key={i}
                              className="nav d-flex algn-items-center  pl-0  ml-0 text-secondary"
                            >
                              <FileEarmarkText className="mr-2 h2" />
                              <a
                                href={API_URL + e?.url}
                                className="text-secondary"
                                target="_blank"
                              >
                                {e?.name}
                              </a>
                            </li>
                          ))}
                          <hr className="m-2" />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {viewInspectionModel && (
              <>
                <div className="modal-backdrop"></div>
                <div
                  className="modal"
                  id="modelId"
                  tabIndex={-1}
                  role="dialog"
                  style={{ display: "block" }}
                  aria-labelledby="modelTitleId"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable  modal-xl"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title text-uppercase">
                          New Review
                        </h5>
                        <button
                          type="button"
                          className="close text-danger"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setViewInspectionModel(false)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body bg-light">
                        <div>
                          <div className="main-header d-flex justify-content-between text-uppercase p-0 m-0">
                            <h1>Profile Details</h1>
                            <a
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                patchInspection(
                                  `${profileData?.data?.profile.id}`
                                ).then(() => {
                                  window.location.reload();
                                });
                              }}
                            >
                              {" "}
                              Save
                            </a>
                          </div>
                          {/* </div> */}
                          <div className="row mx-1">
                            <div className="col-lg-12  pt-3 pb-3 card">
                              <div className="row">
                                <div className="col-lg-6">
                                  <h2 className="text-uppercase">
                                    Date of Review
                                  </h2>
                                  <div className="row">
                                    <div className="col-4">
                                      <input
                                        value={inspectionDate}
                                        onChange={(val) => {
                                          let date = val.target.value;
                                          setInspectionDate(
                                            moment(date).format("YYYY-MM-DD")
                                          );
                                        }}
                                        className="form-control form-control-sm"
                                        type="date"
                                        name=""
                                        id=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-lg-6">
                                                                        <h2 className="mb-2 text-uppercase">select review type</h2>
                                                                        <select className="form-control form-control-sm" onChange={(e) => {
                                                                            if (e.target.value) {
                                                                                setInspectionReportType(e.target.value)
                                                                            }
                                                                        }}>

                                                                            {user?.role === "1" && <option selected value="JSR">JSR</option>}
                                                                            {user?.role !== "1" && (
                                                                                <>
                                                                                    <option value="MONTHLY" selected>Quarterly Checking Report </option>
                                                                                    <option value="THREAT">THREAT</option>
                                                                                </>
                                                                            )
                                                                            }
                                                                        </select>
                                                                    </div> */}
                              </div>
                            </div>

                            <div className="col-lg-12 card mt-3">
                              <div className="row ">
                                <div className="col-md-8">
                                  <div className="">
                                    <div className="d-flex align-items-center">
                                      <div className="row px-4 pt-4 pb-2">
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            {" "}
                                            <b>Name</b> :{" "}
                                            {profileData?.data?.profile
                                              ?.name ? (
                                              profileData?.data?.profile?.name
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            {" "}
                                            <b>Address</b> :{" "}
                                            {profileData?.data?.profile
                                              ?.address ? (
                                              profileData?.data?.profile
                                                ?.address
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            {" "}
                                            <b> City</b> :{" "}
                                            {profileData?.data?.profile
                                              ?.city ? (
                                              profileData?.data?.profile?.city
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}{" "}
                                          </p>
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            {" "}
                                            <b>District</b> :
                                            {profileData?.data?.profile
                                              ?.district ? (
                                              profileData?.data?.profile
                                                ?.district
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            {" "}
                                            <b>State </b> :{" "}
                                            {profileData?.data?.profile
                                              ?.state ? (
                                              profileData?.data?.profile?.state
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}{" "}
                                          </p>
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            {" "}
                                            <b>Pincode</b> :{" "}
                                            {profileData?.data?.profile
                                              ?.pincode ? (
                                              profileData?.data?.profile
                                                ?.pincode
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}{" "}
                                          </p>
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            <b>Latitude</b> :{" "}
                                            {profileData?.data?.profile
                                              ?.latitude ? (
                                              profileData?.data?.profile
                                                ?.latitude
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            <b>Longitude</b> :{" "}
                                            {profileData?.data?.profile
                                              ?.longitude ? (
                                              profileData?.data?.profile
                                                ?.longitude
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            {" "}
                                            <b> Category :</b>{" "}
                                            <span>
                                              {profileData?.data?.profile
                                                ?.category?.name ? (
                                                profileData?.data?.profile
                                                  ?.category?.name
                                              ) : (
                                                <span className="text-muted">
                                                  Not Available
                                                </span>
                                              )}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        {profileData?.data?.profile?.vp_category
                                          ?.name && (
                                          <div className="col-lg-6 pl-0">
                                            <p>
                                              {" "}
                                              <b> VP Category : </b>{" "}
                                              {profileData?.data?.profile
                                                ?.vp_category?.name ? (
                                                profileData?.data?.profile
                                                  ?.vp_category?.name
                                              ) : (
                                                <span className="text-muted">
                                                  Not Available
                                                </span>
                                              )}{" "}
                                            </p>
                                          </div>
                                        )}
                                        {profileData?.data?.profile
                                          ?.bank_category?.name && (
                                          <div className="col-lg-6 pl-0">
                                            <p>
                                              {" "}
                                              <b> Bank Category : </b>{" "}
                                              {profileData?.data?.profile
                                                ?.bank_category?.name ? (
                                                profileData?.data?.profile
                                                  ?.bank_category?.name
                                              ) : (
                                                <span className="text-muted">
                                                  Not Available
                                                </span>
                                              )}{" "}
                                            </p>
                                          </div>
                                        )}
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            {" "}
                                            <b>Type:</b>{" "}
                                            {profileData?.data?.profile?.type
                                              ?.name ? (
                                              profileData?.data?.profile?.type
                                                ?.name
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                          <p>
                                            {" "}
                                            <b>Paid:</b>{" "}
                                            {profileData?.data?.profile?.paid ==
                                            false
                                              ? "No"
                                              : "Yes"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="">
                                    <div className="">
                                      {profileData?.data?.profile
                                        ?.profile_image && (
                                        <img
                                          style={{
                                            objectFit: "contain",
                                            // width: "200px",
                                            height: "260px",
                                            maxWidth: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            // height: "100%",
                                            margin: "0 auto",
                                          }}
                                          src={
                                            API_URL +
                                            profileData?.data?.profile
                                              ?.profile_image
                                          }
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mx-1 pt-3 text-uppercase">
                            <div className="col-lg-12 card p-4">
                              <div className="col-lg-12 pb-1 pl-0">
                                <h2 className="text-uppercase">
                                  Satellite Image
                                </h2>
                              </div>
                              <div
                                style={{
                                  height: "300px",
                                }}
                              >
                                <img
                                  style={{
                                    maxWidth: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    margin: "0 auto",
                                  }}
                                  src={
                                    API_URL +
                                    profileData?.data?.profile?.satellite_image
                                  }
                                  alt=""
                                  height="300px"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mx-1 mt-2 pt-3">
                            <div className="col-lg-12 card p-4 mb-2">
                              <div className="col-lg-12 pb-1 pl-0 text-uppercase">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <h2>Documents Uploaded</h2>
                                  </div>
                                </div>
                              </div>

                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Profile</th>
                                    <th>Assignment</th>
                                    <th>Unit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{ verticalAlign: "top" }}>
                                      {(profileData?.data?.profile
                                        ?.profile_documents == null ||
                                        profileData?.data?.profile
                                          ?.profile_documents.length <= 0) && (
                                        <li className="d-flex py-1 text-secondary">
                                          <span className="text-muted">
                                            Not Available
                                          </span>
                                        </li>
                                      )}
                                      {profileData?.data?.profile?.profile_documents?.forEach(
                                        (doc) => (
                                          <li className="d-flex py-1 text-secondary">
                                            <FileEarmarkArrowDown fontSize="20px" />
                                            <a
                                              href={API_URL + doc?.url}
                                              className="text-secondary"
                                              target="_blank"
                                            >
                                              {doc.name}
                                            </a>
                                          </li>
                                        )
                                      )}
                                    </td>
                                    <td style={{ verticalAlign: "top" }}>
                                      {(profileData?.data
                                        ?.assignment_documents == null ||
                                        profileData?.data?.assignment_documents
                                          .length <= 0) && (
                                        <li className="d-flex py-1 text-secondary">
                                          <span className="text-muted">
                                            Not Available
                                          </span>
                                        </li>
                                      )}
                                      {profileData?.data?.assignment_documents?.map(
                                        (doc) => (
                                          <li className="d-flex py-1 text-secondary">
                                            <FileEarmarkArrowDown fontSize="20px" />
                                            <a
                                              href={API_URL + doc?.url}
                                              className="text-secondary"
                                              target="_blank"
                                            >
                                              {doc.name}
                                            </a>
                                          </li>
                                        )
                                      )}
                                    </td>
                                    <td style={{ verticalAlign: "top" }}>
                                      {(profileData?.data?.unit_cctv_document ==
                                        null ||
                                        profileData?.data?.unit_cctv_document
                                          .length <= 0) && (
                                        <li className="d-flex py-1 text-secondary">
                                          <span className="text-muted">
                                            Not Available
                                          </span>
                                        </li>
                                      )}
                                      {profileData?.data?.unit_cctv_document?.map(
                                        (doc) => (
                                          <li className="d-flex py-1 text-secondary">
                                            <FileEarmarkArrowDown fontSize="20px" />
                                            <a
                                              href={API_URL + doc?.url}
                                              className="text-secondary"
                                              target="_blank"
                                            >
                                              {doc.name}
                                            </a>
                                          </li>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <hr />
                              <div className="row text-uppercase ">
                                <div className="col-lg-12 ">
                                  <h4>Are uploaded documents correct?</h4>
                                </div>
                                <div className="col-lg-2 d-flex justify-content-between">
                                  <div>
                                    <label htmlFor="" className="h5 text-muted">
                                      yes
                                    </label>{" "}
                                    {"  "}
                                    <input
                                      type="radio"
                                      name="docinsp"
                                      id=""
                                      onChange={() =>
                                        setDocumentsUploadedFeild((prev) => ({
                                          ...prev,
                                          isOk: "Yes",
                                        }))
                                      }
                                    />
                                  </div>
                                  <div>
                                    <label htmlFor="" className="h5 text-muted">
                                      No
                                    </label>{" "}
                                    <input
                                      type="radio"
                                      name="docinsp"
                                      id=""
                                      onChange={() =>
                                        setDocumentsUploadedFeild((prev) => ({
                                          ...prev,
                                          isOk: "No",
                                        }))
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <label htmlFor="" className="text-muted ">
                                    inspection comment
                                  </label>
                                  <textarea
                                    style={{
                                      resize: "none",
                                    }}
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      setDocumentsUploadedFeild((prev) => ({
                                        ...prev,
                                        comment: val,
                                      }));
                                    }}
                                    rows={5}
                                    placeholder="Enter Comment"
                                    className="form-control form-control-sm"
                                    name=""
                                    id=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CCTV */}
                          {/* <div className="row mx-1 pt-3">
                                            <div className="col-lg-12 card p-4 mb-4">
                                                <h2>CCTV</h2>

                                                <table className="table has-action-buttons">
                                                    <thead>
                                                        <tr>
                                                            <th>SANCTIONED Count</th>
                                                            <th>Deployed Count</th>
                                                            <th>Comment</th>
                                                            <th>Document</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{profileData?.data?.cctv ? profileData?.data?.cctv : <span className="text-muted">Not Available</span>}</td>
                                                            <td>{profileData?.data?.cctv_deployed ? profileData?.data?.cctv_deployed : <span className="text-muted">Not Available</span>}</td>
                                                            <td data-toggle="tooltip" data-placement="top" title={profileData?.data?.deployed_exceed_comment}>
                                                                {profileData?.data?.deployed_exceed_comment ? (profileData?.data?.deployed_exceed_comment?.length > 20 ? profileData?.data?.deployed_exceed_comment?.slice(20) + '...' : profileData?.data?.deployed_exceed_comment) : (<span className="text-muted">Not Available</span>)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <p>Are CCTV DEPLOYMENT correct?</p>
                                                    </div>
                                                    <div className="col-lg-2 d-flex justify-content-between">
                                                        <div>
                                                            <label htmlFor="" className="h4">yes</label> {'  '}
                                                            <input type="radio" name="CCTV" id="" />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="" className="h4">No</label> {' '}
                                                            <input type="radio" name="CCTV" id="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label htmlFor="" className="h4">inspection comment</label>
                                                        <textarea style={{
                                                            resize: 'none',
                                                        }} rows={5} placeholder="Enter Comment" className="form-control form-control-sm" name="" id="" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div> */}

                          <div className="row mx-1 mt-3 text-uppercase">
                            {/* assignment by rank */}
                            <div className="col-lg-12 card p-4 mb-4">
                              <div className="col-lg-12 pl-0">
                                <h2>SANCTIONED DEPLOYMENT</h2>
                              </div>
                              <table className="table ">
                                <thead>
                                  <tr>
                                    <th>Rank</th>
                                    <th>Count</th>
                                  </tr>
                                </thead>
                                {(profileData?.data?.assignment_by_rank ==
                                  null ||
                                  profileData?.data?.assignment_by_rank
                                    .length <= 0) && (
                                  <tr>
                                    <td>
                                      <span className="text-muted">
                                        Not Available
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-muted">
                                        Not Available
                                      </span>
                                    </td>
                                  </tr>
                                )}
                                {profileData?.data?.assignment_by_rank?.map(
                                  (item, index) => (
                                    <tr>
                                      <td>
                                        {item.rank ? (
                                          item.rank
                                        ) : (
                                          <span className="text-muted">
                                            Not Available
                                          </span>
                                        )}{" "}
                                      </td>
                                      <td>
                                        {item.count ? (
                                          item.count
                                        ) : (
                                          <span className="text-muted">
                                            Not Available
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </table>
                              <hr />
                              <div className="row">
                                <div className="col-lg-12">
                                  <h4>Are SANCTIONED DEPLOYMENT correct?</h4>
                                </div>
                                <div className="col-lg-2 d-flex justify-content-between">
                                  <div>
                                    <label htmlFor="" className="h5 text-muted">
                                      yes
                                    </label>{" "}
                                    {"  "}
                                    <input
                                      type="radio"
                                      name="SD"
                                      id=""
                                      onChange={() =>
                                        setSanctionDeployementFeild((prev) => ({
                                          ...prev,
                                          isOk: "Yes",
                                        }))
                                      }
                                    />
                                  </div>
                                  <div>
                                    <label htmlFor="" className="h5 text-muted">
                                      No
                                    </label>{" "}
                                    <input
                                      type="radio"
                                      name="SD"
                                      onChange={() =>
                                        setSanctionDeployementFeild((prev) => ({
                                          ...prev,
                                          isOk: "No",
                                        }))
                                      }
                                      id=""
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <label htmlFor="" className="text-muted">
                                    inspection comment
                                  </label>
                                  <textarea
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      setSanctionDeployementFeild((prev) => ({
                                        ...prev,
                                        comment: val,
                                      }));
                                    }}
                                    style={{
                                      resize: "none",
                                    }}
                                    rows={5}
                                    placeholder="Enter Comment"
                                    className="form-control form-control-sm"
                                    name=""
                                    id=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 card p-4 mb-4">
                              <h2>Deployed By Unit</h2>
                              <table className="table has-action-buttons">
                                <thead>
                                  <tr>
                                    <th>Force</th>
                                    <th>Battalion</th>
                                    <th>Rank</th>
                                    <th>Count</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(profileData?.data?.force_deployemnt ==
                                    null ||
                                    profileData?.data?.force_deployemnt
                                      .length <= 0) && (
                                    <tr>
                                      <td>
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {profileData?.data?.force_deployemnt?.map(
                                    (e) => (
                                      <tr>
                                        <td>
                                          {
                                            force?.data?.find(
                                              (f) => f.id == e.force
                                            ).name
                                          }
                                        </td>
                                        <td>
                                          {
                                            battalion?.data
                                              ?.filter(
                                                (b) => b.force == e.force
                                              )
                                              .find((f) => f.id == e.battalion)
                                              .name
                                          }
                                        </td>
                                        <td>
                                          {" "}
                                          {
                                            rank?.data?.find(
                                              (f) => f.id == e.rank
                                            )?.name
                                          }
                                        </td>
                                        <td> {e.count} </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>

                              <p>
                                {profileData?.data
                                  ?.force_deployment_comment && (
                                  <div className="p-3 bg-light rounded">
                                    {profileData?.data
                                      ?.force_deployment_comment && (
                                      <h6 className="font-weight-bold">
                                        Additional Notes
                                      </h6>
                                    )}
                                    <p className="small mb-0 pb-0">
                                      {" "}
                                      {
                                        profileData?.data
                                          ?.force_deployment_comment
                                      }{" "}
                                    </p>
                                  </div>
                                )}
                              </p>

                              <hr />
                              <div className="row">
                                <div className="col-lg-12">
                                  <h4>Are Deployed By Unit correct?</h4>
                                </div>
                                <div className="col-lg-2 d-flex justify-content-between">
                                  <div>
                                    <label htmlFor="" className="h5 text-muted">
                                      yes
                                    </label>{" "}
                                    {"  "}
                                    <input
                                      type="radio"
                                      onChange={() => {
                                        setDeployByUnitFeild((prev) => ({
                                          ...prev,
                                          isOk: "Yes",
                                        }));
                                      }}
                                      name="DBU"
                                      id=""
                                    />
                                  </div>
                                  <div>
                                    <label htmlFor="" className="h5 text-muted">
                                      No
                                    </label>{" "}
                                    <input
                                      type="radio"
                                      onChange={() => {
                                        setDeployByUnitFeild((prev) => ({
                                          ...prev,
                                          isOk: "No",
                                        }));
                                      }}
                                      name="DBU"
                                      id=""
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <label htmlFor="" className="text-muted">
                                    inspection comment
                                  </label>
                                  <textarea
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      setDeployByUnitFeild((prev) => ({
                                        ...prev,
                                        comment: val,
                                      }));
                                    }}
                                    style={{
                                      resize: "none",
                                    }}
                                    rows={5}
                                    placeholder="Enter Comment"
                                    className="form-control form-control-sm"
                                    name=""
                                    id=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 card p-4 mb-4">
                              <h2>DEPLOYED OTHER FORCES</h2>
                              <table className="table has-action-buttons">
                                <thead>
                                  <tr>
                                    <th>Force</th>
                                    <th>Count</th>
                                    <th>NAME</th>
                                    <th>ADDRESS</th>
                                    <th>CONTACT</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(profileData?.data?.other_deployment ==
                                    null ||
                                    profileData?.data?.other_deployment
                                      .length <= 0) && (
                                    <tr>
                                      <td>
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {profileData?.data?.other_deployment?.map(
                                    (e) => (
                                      <tr>
                                        <td>
                                          {" "}
                                          {
                                            otherforce?.data?.find(
                                              (f) => f.id == e.force
                                            )?.name
                                          }{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {e.count ? (
                                            e.count
                                          ) : (
                                            <span className="text-muted">
                                              Not Available
                                            </span>
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {e.private_security_name ? (
                                            e.private_security_name
                                          ) : (
                                            <span className="text-muted">
                                              Not Available
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          {e.private_security_address ? (
                                            e.private_security_address
                                          ) : (
                                            <span className="text-muted">
                                              Not Available
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          {" "}
                                          {e.private_security_contact ? (
                                            e.private_security_contact
                                          ) : (
                                            <span className="text-muted">
                                              Not Available
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                              <hr />
                              <div className="row">
                                <div className="col-lg-12">
                                  <h4>Are DEPLOYED OTHER FORCES correct?</h4>
                                </div>
                                <div className="col-lg-2 d-flex justify-content-between">
                                  <div>
                                    <label htmlFor="" className="h5 text-muted">
                                      yes
                                    </label>{" "}
                                    {"  "}
                                    <input
                                      type="radio"
                                      onChange={() =>
                                        setDeployByOtherForceFeild((prev) => ({
                                          ...prev,
                                          isOk: "Yes",
                                        }))
                                      }
                                      name="DOF"
                                      id=""
                                    />
                                  </div>
                                  <div>
                                    <label htmlFor="" className="h5 text-muted">
                                      No
                                    </label>{" "}
                                    <input
                                      type="radio"
                                      onChange={() =>
                                        setDeployByOtherForceFeild((prev) => ({
                                          ...prev,
                                          isOk: "No",
                                        }))
                                      }
                                      name="DOF"
                                      id=""
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <label htmlFor="" className="text-muted">
                                    inspection comment
                                  </label>
                                  <textarea
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      setDeployByOtherForceFeild((prev) => ({
                                        ...prev,
                                        comment: val,
                                      }));
                                    }}
                                    style={{
                                      resize: "none",
                                    }}
                                    rows={5}
                                    placeholder="Enter Comment"
                                    className="form-control form-control-sm"
                                    name=""
                                    id=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Supervisioning Officer */}
                            <div className="col-lg-12 card p-4 mb-4">
                              <h2 className="mb-3">SUPERVISIONING OFFICER </h2>
                              <table className="table has-action-buttons">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Rank</th>
                                    <th>Phone</th>
                                    <th>Order Number</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {profileData?.data?.supervisor_officer
                                        ?.name ? (
                                        profileData?.data?.supervisor_officer
                                          ?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.supervisor_officer
                                        ?.rank ? (
                                        profileData?.data?.supervisor_officer
                                          ?.rank
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.supervisor_officer
                                        ?.phone ? (
                                        profileData?.data?.supervisor_officer
                                          ?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.supervisor_officer
                                        ?.order_no ? (
                                        profileData?.data?.supervisor_officer
                                          ?.order_no
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            {/* {on gaurd incharge start} */}
                            <div className="col-lg-12 card p-4 mb-4">
                              <h2>ESTABLISHMENT OFFICER</h2>
                              <table className="table has-action-buttons">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Rank</th>
                                    <th>Phone</th>
                                    <th>Order Number</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {profileData?.data?.on_guard_incharge
                                        ?.name ? (
                                        profileData?.data?.on_guard_incharge
                                          ?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.on_guard_incharge
                                        ?.rank ? (
                                        profileData?.data?.on_guard_incharge
                                          ?.rank
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.on_guard_incharge
                                        ?.phone ? (
                                        profileData?.data?.on_guard_incharge
                                          ?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}{" "}
                                    </td>
                                    <td>
                                      {profileData?.data?.on_guard_incharge
                                        ?.order_no ? (
                                        profileData?.data?.on_guard_incharge
                                          ?.order_no
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}{" "}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/* security Equipment */}
                            {profileData?.data?.security_equipment?.length <
                              1 && (
                              <div className="col-lg-12 card p-4 mb-4">
                                <h2>Security Equipment</h2>
                                <h4>
                                  {" "}
                                  <span className="text-muted">
                                    Not Available
                                  </span>{" "}
                                </h4>
                              </div>
                            )}
                            {profileData?.data?.security_equipment?.length >
                              0 && (
                              <div className="col-lg-12 card p-4 mb-4">
                                <h2>Security Equipment</h2>
                                <table className="table has-action-buttons">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>SANCTIONED Count</th>
                                      <th>Deployed Count</th>
                                      <th>Comment</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {profileData?.data?.security_equipment?.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td>
                                            {item.equipment_name ? (
                                              item.equipment_name
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {item.eqipment_count ? (
                                              item.eqipment_count
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {item.equipment_deployed ? (
                                              item.equipment_deployed
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}{" "}
                                          </td>
                                          <td
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={item.deployed_more_comment}
                                          >
                                            {item.deployed_more_comment ? (
                                              item.deployed_more_comment
                                                ?.length > 20 ? (
                                                item.deployed_more_comment?.slice(
                                                  20
                                                ) + "..."
                                              ) : (
                                                item.deployed_more_comment
                                              )
                                            ) : (
                                              <span className="text-muted">
                                                Not Available
                                              </span>
                                            )}
                                            {/* {item.deployed_more_comment ? item.deployed_more_comment : <span className="text-muted">Not Available</span>}  */}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                                <hr />
                                <div className="row">
                                  <div className="col-lg-12">
                                    <h4>Are Security Equipment correct?</h4>
                                  </div>
                                  <div className="col-lg-2 d-flex justify-content-between">
                                    <div>
                                      <label
                                        htmlFor=""
                                        className="h5 text-muted"
                                      >
                                        yes
                                      </label>{" "}
                                      {"  "}
                                      <input
                                        type="radio"
                                        onChange={() =>
                                          setSecurityEquipmentsFeild(
                                            (prev) => ({ ...prev, isOk: "Yes" })
                                          )
                                        }
                                        name="SE"
                                        id=""
                                      />
                                    </div>
                                    <div>
                                      <label
                                        htmlFor=""
                                        className="h5 text-muted"
                                      >
                                        No
                                      </label>{" "}
                                      <input
                                        type="radio"
                                        onChange={() =>
                                          setSecurityEquipmentsFeild(
                                            (prev) => ({ ...prev, isOk: "No" })
                                          )
                                        }
                                        name="SE"
                                        id=""
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <label htmlFor="" className="text-muted">
                                      inspection comment
                                    </label>
                                    <textarea
                                      onChange={(e) => {
                                        let val = e.target.value;
                                        setSecurityEquipmentsFeild((prev) => ({
                                          ...prev,
                                          comment: val,
                                        }));
                                      }}
                                      style={{ resize: "none" }}
                                      rows={5}
                                      placeholder="Enter Comment"
                                      className="form-control form-control-sm"
                                      name=""
                                      id=""
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="col-lg-12 card p-4 mb-4">
                              <h2>NEARBY LANDMARKS</h2>
                              <table className="table has-action-buttons">
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Phone</th>
                                    <th>Distance (in KM )</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Concerned SSP</td>
                                    <td>
                                      {profileData?.data?.profile?.ssp?.name ? (
                                        profileData?.data?.profile?.ssp?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.ssp
                                        ?.address ? (
                                        profileData?.data?.profile?.ssp?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.ssp
                                        ?.phone ? (
                                        profileData?.data?.profile?.ssp?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.ssp
                                        ?.distance ? (
                                        profileData?.data?.profile?.ssp
                                          ?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Police Station</td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.police_station?.name ? (
                                        profileData?.data?.profile
                                          ?.police_station?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.police_station?.address ? (
                                        profileData?.data?.profile
                                          ?.police_station?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.police_station?.phone ? (
                                        profileData?.data?.profile
                                          ?.police_station?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.police_station?.distance ? (
                                        profileData?.data?.profile
                                          ?.police_station?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Railway Station</td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.railway_station?.name ? (
                                        profileData?.data?.profile
                                          ?.railway_station?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.railway_station?.address ? (
                                        profileData?.data?.profile
                                          ?.railway_station?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.railway_station?.phone ? (
                                        profileData?.data?.profile
                                          ?.railway_station?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.railway_station?.distance ? (
                                        profileData?.data?.profile
                                          ?.railway_station?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>AirPort</td>
                                    <td>
                                      {profileData?.data?.profile?.airport
                                        ?.name ? (
                                        profileData?.data?.profile?.airport
                                          ?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.airport
                                        ?.address ? (
                                        profileData?.data?.profile?.airport
                                          ?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.airport
                                        ?.phone ? (
                                        profileData?.data?.profile?.airport
                                          ?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.airport
                                        ?.distance ? (
                                        profileData?.data?.profile?.airport
                                          ?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>AirBase</td>
                                    <td>
                                      {profileData?.data?.profile?.airbase
                                        ?.name ? (
                                        profileData?.data?.profile?.airbase
                                          ?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.airbase
                                        ?.address ? (
                                        profileData?.data?.profile?.airbase
                                          ?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.airbase
                                        ?.phone ? (
                                        profileData?.data?.profile?.airbase
                                          ?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.airbase
                                        ?.distance ? (
                                        profileData?.data?.profile?.airbase
                                          ?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>HeliPad</td>
                                    <td>
                                      {profileData?.data?.profile?.helipad
                                        ?.name ? (
                                        profileData?.data?.profile?.helipad
                                          ?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.helipad
                                        ?.address ? (
                                        profileData?.data?.profile?.helipad
                                          ?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.helipad
                                        ?.phone ? (
                                        profileData?.data?.profile?.helipad
                                          ?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.helipad
                                        ?.distance ? (
                                        profileData?.data?.profile?.helipad
                                          ?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>NDRF</td>
                                    <td>
                                      {profileData?.data?.profile?.ndrf
                                        ?.name ? (
                                        profileData?.data?.profile?.ndrf?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.ndrf
                                        ?.address ? (
                                        profileData?.data?.profile?.ndrf
                                          ?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.ndrf
                                        ?.phone ? (
                                        profileData?.data?.profile?.ndrf?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.ndrf
                                        ?.distance ? (
                                        profileData?.data?.profile?.ndrf
                                          ?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>District Police Control Room</th>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.District_Police_Control_Room?.name ? (
                                        profileData?.data?.profile
                                          ?.District_Police_Control_Room?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.District_Police_Control_Room
                                        ?.address ? (
                                        profileData?.data?.profile
                                          ?.District_Police_Control_Room
                                          ?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.District_Police_Control_Room
                                        ?.phone ? (
                                        profileData?.data?.profile
                                          ?.District_Police_Control_Room?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.District_Police_Control_Room
                                        ?.distance ? (
                                        profileData?.data?.profile
                                          ?.District_Police_Control_Room
                                          ?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Fire Alram</th>
                                    <td>
                                      {profileData?.data?.profile?.Fire_Alram
                                        ?.name ? (
                                        profileData?.data?.profile?.Fire_Alram
                                          ?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.Fire_Alram
                                        ?.address ? (
                                        profileData?.data?.profile?.Fire_Alram
                                          ?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.Fire_Alram
                                        ?.phone ? (
                                        profileData?.data?.profile?.Fire_Alram
                                          ?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.Fire_Alram
                                        ?.distance ? (
                                        profileData?.data?.profile?.Fire_Alram
                                          ?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Magistrate Office</th>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.Magistrate_Office?.name ? (
                                        profileData?.data?.profile
                                          ?.Magistrate_Office?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.Magistrate_Office?.address ? (
                                        profileData?.data?.profile
                                          ?.Magistrate_Office?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.Magistrate_Office?.phone ? (
                                        profileData?.data?.profile
                                          ?.Magistrate_Office?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile
                                        ?.Magistrate_Office?.distance ? (
                                        profileData?.data?.profile
                                          ?.Magistrate_Office?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Hospital</th>
                                    <td>
                                      {profileData?.data?.profile?.Hospital
                                        ?.name ? (
                                        profileData?.data?.profile?.Hospital
                                          ?.name
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.Hospital
                                        ?.address ? (
                                        profileData?.data?.profile?.Hospital
                                          ?.address
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.Hospital
                                        ?.phone ? (
                                        profileData?.data?.profile?.Hospital
                                          ?.phone
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {profileData?.data?.profile?.Hospital
                                        ?.distance ? (
                                        profileData?.data?.profile?.Hospital
                                          ?.distance
                                      ) : (
                                        <span className="text-muted">
                                          Not Available
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            {/* 
          {profileData?.data?.assignment_by_rank?.map((item, index) => (
            <div className="col-lg-12 card p-4 mb-4" key={index}>
              <table className="table has-action-buttons">
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Count</th>
                    <th>Allocated</th>
                    <th>Deployed by armed</th>
                    <th>Deployed by district</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {item.rank ? item.rank : <span className="text-muted">Not Available</span>}
                    </td>
                    <td>
                      {item.count ? item.count : <span className="text-muted">Not Available</span>}
                    </td>
                    <td>
                      {item.allocated ? item.allocated : <span className="text-muted">Not Available</span>}
                    </td>
                    <td>
                      {item.desployed_by_armed ? item.desployed_by_armed : <span className="text-muted">Not Available</span>}
                    </td>
                    <td>
                      {item.desployed_by_district ? item.desployed_by_district : <span className="text-muted">Not Available</span>}
                    </td>
                  </tr>
                </tbody>

              </table>
              {
                item.comment_for_allocate_more_than_sanctioned && (
                  <div className="p-3 bg-light rounded">
                    {
                      item.comment_for_allocate_more_than_sanctioned && <h6 className="font-weight-bold">Additional Notes</h6>
                    }
                    <p className="small mb-0 pb-0"> {item.comment_for_allocate_more_than_sanctioned && item.comment_for_allocate_more_than_sanctioned} </p>
                  </div>
                )
              }
              <div className="spacer spacer--sm"></div>

              <h4>Private Security</h4>
              {item.private_security == true ? (
                <table className="table has-action-buttons">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>assigned</th>
                      <th>address</th>
                      <th>contact</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {item.name_private_security ? item.name_private_security : <span className="text-muted">Not Available</span>}
                      </td>
                      <td>
                        {item.assigned_private_security ? item.assigned_private_security : <span className="text-muted">Not Available</span>}
                      </td>
                      <td>
                        {item.address_private_security ? item.address_private_security : <span className="text-muted">Not Available</span>}
                      </td>
                      <td>
                        {item.contact_private_security ? item.contact_private_security : <span className="text-muted">Not Available</span>}
                      </td>

                    </tr>
                  </tbody>

                </table>
              ) : (
                <h5>No private security</h5>
              )}
            </div>
          ))} */}
                          </div>
                        </div>

                        <a
                          className="btn btn-sm btn-primary float-right"
                          onClick={() => {
                            patchInspection(
                              `${profileData?.data?.profile.id}`
                            ).then(() => {
                              window.location.reload();
                            });
                          }}
                        >
                          {" "}
                          Save
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {viewReportModal && (
              <>
                <div className="modal-backdrop"></div>
                <div
                  className="modal text-uppercase"
                  id="modelId"
                  tabIndex={-1}
                  role="dialog"
                  style={{ display: "block" }}
                  aria-labelledby="modelTitleId"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable  modal-xl"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <div>
                          <h5 className="modal-title">
                            {viewMoreInspectionData?.inspection_type} report
                          </h5>
                          <h5>{viewMoreInspectionData?.date_of_inspection}</h5>
                        </div>
                        <button
                          type="button"
                          className="close text-danger"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setViewReportModal(false)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body bg-light text-uppercase">
                        <div className="row mx-1">
                          <div className="col-lg-12 p-3 card mb-3">
                            <h3>Uploaded documents</h3>
                            <div className="my-1">
                              <strong className="h5">
                                documents verified:{" "}
                              </strong>{" "}
                              {viewMoreInspectionData?.documents_inspection[0]
                                ?.isOk ? (
                                <span className="h4">
                                  {" "}
                                  {
                                    viewMoreInspectionData
                                      ?.documents_inspection[0]?.isOk
                                  }
                                </span>
                              ) : (
                                <span className="small text-muted">
                                  NOT AVAILABLE
                                </span>
                              )}
                            </div>
                            <h6 className="font-weight-bold mt-2">Remarks</h6>
                            <div className="p-3 border  rounded">
                              <p className="small mb-0 pb-0">
                                {viewMoreInspectionData?.documents_inspection[0]
                                  ?.comment ? (
                                  viewMoreInspectionData
                                    ?.documents_inspection[0]?.comment
                                ) : (
                                  <span className="small text-muted">
                                    NOT AVAILABLE
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-12 p-3 card mb-3">
                            <h3>SANCTIONED DEPLOYMENT</h3>
                            <div className="my-1">
                              <strong className="h5">
                                SANCTIONED DEPLOYMENT verified:{" "}
                              </strong>{" "}
                              {viewMoreInspectionData?.sanctioned_deployment[0]
                                ?.isOk ? (
                                <span className="h4">
                                  {" "}
                                  {
                                    viewMoreInspectionData
                                      ?.sanctioned_deployment[0]?.isOk
                                  }
                                </span>
                              ) : (
                                <span className="small text-muted">
                                  NOT AVAILABLE
                                </span>
                              )}
                            </div>
                            <h6 className="font-weight-bold mt-2">Remarks</h6>
                            <div className="p-3 border  rounded">
                              <p className="small mb-0 pb-0">
                                {viewMoreInspectionData
                                  ?.sanctioned_deployment[0]?.comment ? (
                                  viewMoreInspectionData
                                    ?.sanctioned_deployment[0]?.comment
                                ) : (
                                  <span className="small text-muted">
                                    NOT AVAILABLE
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-12 p-3 card mb-3">
                            <h3>DEPLOYED BY UNIT</h3>
                            <div className="my-1">
                              <strong className="h5">
                                DEPLOYED BY UNIT verified:{" "}
                              </strong>{" "}
                              {viewMoreInspectionData
                                ?.deploy_by_unit_inspection[0]?.isOk ? (
                                <span className="h4">
                                  {" "}
                                  {
                                    viewMoreInspectionData
                                      ?.deploy_by_unit_inspection[0]?.isOk
                                  }
                                </span>
                              ) : (
                                <span className="small text-muted">
                                  NOT AVAILABLE
                                </span>
                              )}
                            </div>
                            <h6 className="font-weight-bold mt-2">Remarks</h6>
                            <div className="p-3 border  rounded">
                              <p className="small mb-0 pb-0">
                                {viewMoreInspectionData
                                  ?.deploy_by_unit_inspection[0]?.comment ? (
                                  viewMoreInspectionData
                                    ?.deploy_by_unit_inspection[0]?.comment
                                ) : (
                                  <span className="small text-muted">
                                    NOT AVAILABLE
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-12 p-3 card mb-3">
                            <h3>DEPLOYED OTHER FORCES</h3>
                            <div className="my-1">
                              <strong className="h5">
                                DEPLOYED OTHER FORCES verified:{" "}
                              </strong>{" "}
                              {viewMoreInspectionData
                                ?.deploy_by_otherforce_inspection[0]?.isOk ? (
                                <span className="h4">
                                  {" "}
                                  {
                                    viewMoreInspectionData
                                      ?.deploy_by_otherforce_inspection[0]?.isOk
                                  }
                                </span>
                              ) : (
                                <span className="small text-muted">
                                  NOT AVAILABLE
                                </span>
                              )}
                            </div>
                            <h6 className="font-weight-bold mt-2">Remarks</h6>
                            <div className="p-3 border  rounded">
                              <p className="small mb-0 pb-0">
                                {viewMoreInspectionData
                                  ?.deploy_by_otherforce_inspection[0]
                                  ?.comment ? (
                                  viewMoreInspectionData
                                    ?.deploy_by_otherforce_inspection[0]
                                    ?.comment
                                ) : (
                                  <span className="small text-muted">
                                    NOT AVAILABLE
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-12 p-3 card mb-3">
                            <h3>SECURITY EQUIPMENT</h3>
                            <div className="my-1">
                              <strong className="h5">
                                SECURITY EQUIPMENT verified:{" "}
                              </strong>{" "}
                              {viewMoreInspectionData
                                ?.security_equipments_inspection[0]?.isOk ? (
                                <span className="h4">
                                  {" "}
                                  {
                                    viewMoreInspectionData
                                      ?.security_equipments_inspection[0]?.isOk
                                  }
                                </span>
                              ) : (
                                <span className="small text-muted">
                                  NOT AVAILABLE
                                </span>
                              )}
                            </div>
                            <h6 className="font-weight-bold mt-2">Remarks</h6>
                            <div className="p-3 border  rounded">
                              <p className="small mb-0 pb-0">
                                {viewMoreInspectionData
                                  ?.security_equipments_inspection[0]
                                  ?.comment ? (
                                  viewMoreInspectionData
                                    ?.security_equipments_inspection[0]?.comment
                                ) : (
                                  <span className="small text-muted">
                                    NOT AVAILABLE
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Review;
