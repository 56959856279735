import React, { useState, useEffect, useRef } from "react";
import { useFetch } from "use-http";
import _, { map } from "underscore";
import { CUSTOM_URL } from "../..";
import { Printer } from "react-bootstrap-icons";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { LoadingScreen } from "../../common/components/LoadingScreen";
import Goback from "../../common/components/Goback";
function VpCategories() {
  const { data: totalVP } = useFetch(
    `${CUSTOM_URL}/directus/custom/totalVp`,
    {},
    []
  );

  /* state for forces */
  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);

  const [forceState, setForceState] = useState<any>([]);
  const [rankState, setRankState] = useState<any>([]);
  const [otherforceState, setOtherforceState] = useState<any>([]);
  const [battalionState, setbattalionState] = useState<any>([]);
  /* state for forces */

  const [totalVPAfterNameState, settotalVPAfterNameState] = useState<any>([]);
  useEffect(() => {
    setRankState(rank?.data);
  }, [rank]);

  useEffect(() => {
    setbattalionState(battalion?.data);
  }, [battalion]);

  useEffect(() => {
    setOtherforceState(otherforce?.data);
  }, [otherforce]);

  useEffect(() => {
    setForceState(force?.data);
  }, [force]);

  // function to group by array
  const groupByKey = (list, key) =>
    list?.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  useEffect(() => {
    //removing slashes
    const totalVPAfterSlash: any = [];
    totalVP?.data?.map((force) => {
      totalVPAfterSlash.push({
        name: force.name,
        category: force.category,
        force: JSON.parse(force?.force_deployemnt),
        otherforce: JSON.parse(force?.other_deployment),
      });
    });

    // a have change name of force from number to string
    let districtVPAfterName = totalVPAfterSlash?.map((e: any) => {
      e?.force?.map((ee: any) => {
        ee.force = forceState && forceState[Number(ee?.force - 1)]?.name;
        ee.battalion =
          battalionState && battalionState[Number(ee?.battalion - 1)]?.name;
        ee.rank = rankState && rankState[Number(ee?.rank - 1)]?.name;
      });
      e?.otherforce?.map((ee: any) => {
        ee.force =
          otherforceState && otherforceState[Number(ee?.force - 1)]?.name;
      });
      return e;
    });
    settotalVPAfterNameState(districtVPAfterName);
  }, [totalVP, forceState, rankState, battalionState]);

  // console.log("totalVP", totalVP);

  // console.log("totalVPAfterNameState", totalVPAfterNameState);
  const [finalData, setfinalData] = useState<any>([]);
  const [finalDataMore, setfinalDataMore] = useState<any>([]);

  const [forceCount, setForceCount] = useState<number>(0);
  const [otherForceCount, setotherForceCount] = useState<number>(0);
  useEffect(() => {
    let sortedByforceAndOtherForce = totalVPAfterNameState.flatMap(
      (each, i) => {
        return {
          name: each.name,
          category: each.category,
          force: groupByKey(each.force, "force"),
          otherforce: groupByKey(each.otherforce, "force"),
        };
      }
    );

    // console.log("sortedByforceAndOtherForce", sortedByforceAndOtherForce)
    const totalSumByForceAndVp = sortedByforceAndOtherForce.map(
      (sortedForce) => {
        // console.log("sortedForce", sortedForce)
        let fullarrywithname: any = [];
        let arrByForce: any = [];
        let arrByOtherForce: any = [];

        // for force
        Object.keys(sortedForce?.force)?.map((key) => {
          let count = 0;
          let force = "";
          let rank = "";
          let battalion = "";
          sortedForce?.force[key].map((e) => {
            force = e.force;
            rank = e.rank;
            battalion = e.battalion;
            count += e.count;
          });
          let countByForce = {
            force: force,
            count: count,
            rank: rank,
            battalion: battalion,
          };
          arrByForce.push(countByForce);
        });
        // console.log("arrByForce", arrByForce)

        // for other force
        Object.keys(sortedForce?.otherforce)?.map((key) => {
          let count = 0;
          let force = "";

          sortedForce?.otherforce[key].map((e) => {
            force = e.force;
            count += e.count;
          });
          let countByForce = {
            force: force,
            count: count,
          };
          arrByOtherForce.push(countByForce);
        });

        fullarrywithname.push({
          name: sortedForce.name,
          category: sortedForce.category,
          force: arrByForce,
          otherforce: arrByOtherForce,
        });
        return fullarrywithname;
      }
    );
    // console.log("districtVPAfterNameState", sortedByforceAndOtherForce)
    // console.log("totalSumByForceAndVp", totalSumByForceAndVp) //final data

    setfinalData(groupByKey(totalSumByForceAndVp.flat(2), "category"));
    setfinalDataMore(groupByKey(totalSumByForceAndVp.flat(2), "category"));
  }, [totalVPAfterNameState]);

  console.log("index finalData", finalData);
  const [filteredFinalDate, setFilteredFinalDate] = useState<any>([]);
  useEffect(() => {
    const filteredDataByForce = Object.keys(finalData).map((k) => {
      let forces = finalData[k].flatMap((x) => x.force);
      let otherforceforces = finalData[k].flatMap((x) => x.otherforce);

      let filtered = forces.reduce((acc, d) => {
        const found = acc.find((a) => a.force === d.force);
        console.log("sdcin", acc);
        if (!found) {
          acc.push({ force: d.force, rank: d.rank, count: d.count });
        } else {
          found.count = found.count + Number(d.count);
        }
        return acc;
      }, []);

      // for otherforce
      let filteredOther = otherforceforces.reduce((acc, d) => {
        const found = acc.find((a) => a.force === d.force);
        console.log("sdcin", acc);
        if (!found) {
          acc.push({ force: d.force, count: d.count });
        } else {
          found.count = found.count + Number(d.count);
        }
        return acc;
      }, []);

      let returnObj = {};
      returnObj[k] = { forces: filtered, otherforces: filteredOther };
      return returnObj;
    });

    setFilteredFinalDate(filteredDataByForce);
    console.log("abc", filteredDataByForce);
  }, [finalData]);

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  console.log("finalDataMore", finalDataMore);
  if (totalVP == undefined) {
    return <LoadingScreen />;
  } else {
    return (
      <div>
        <Goback />
        <div className="px-4" ref={componentRef}>
          {/* <h1>VP Categories  <Printer onClick={handlePrint} /></h1>
            <h3>DETAILS OF VP CATEGORIES TOTAL FORCES COUNT</h3> */}
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mb-5 mt-5 text-uppercase">
              Total Number of forces deployement on Vulnerable Points{" "}
            </h3>
            {/* <Printer className="mb-5 mt-5" onClick={handlePrint} /> */}
            <ReactToPrint
              bodyClass={`print-styles`}
              removeAfterPrint={true}
              pageStyle="@page { size: landscape; } "
              trigger={() => (
                <button
                  className="btn float-right my-3 btn-sm btn-primary"
                  // onClick={handlePrint}
                >
                  <Printer className="mr-2" />
                  PRINT REPORT
                </button>
              )}
              content={() => componentRef.current!}
            />
          </div>
          <table className="table table-sm border bg-white table-bordered text-left">
            <thead>
              <th style={{ width: "10%" }}>Sr.No</th>
              <th style={{ width: "20%" }}>CATEGORY</th>
              <th className="m-0 border-0 p-0" style={{ width: "30%" }}>
                <th className="col-12 m-0 p-0 text-center d-flex justify-content-center font-weight-bold">
                  FORCE
                </th>
                <div className="row w-100 p-0 m-0">
                  <th style={{ width: "50%" }}>FORCE</th>
                  <th style={{ width: "50%" }}>COUNT</th>
                </div>
              </th>
              <th style={{ width: "10%" }}>TOTAL</th>
              <th className="m-0 p-0" style={{ width: "30%" }}>
                <th className="col-12 m-0 p-0 text-center d-flex justify-content-center font-weight-bold">
                  OTHER FORCE
                </th>
                <div className="row w-100 p-0 m-0">
                  <th style={{ width: "50%" }}>FORCE</th>
                  <th style={{ width: "50%" }}>COUNT</th>
                </div>
              </th>
              <th style={{ width: "10%" }}>TOTAL</th>
              <th style={{ width: "10%" }}>GRAND TOTAL</th>
            </thead>

            <tbody>
              {filteredFinalDate.map((e, i) => {
                return (
                  <tr>
                    {Object.keys(e).map((f) => (
                      <>
                        <td>{i + 1}</td>
                        <td>{f}</td>

                        {e[f].forces.map((ele) => (
                          <tr className="row w-100 m-0 p-0">
                            <td style={{ width: "50%" }}>{ele.force}</td>
                            <td style={{ width: "50%" }}>{ele.count}</td>
                          </tr>
                        ))}

                        <td>
                          {e[f].forces.reduce(
                            (acc, { count }) => acc + count,
                            0
                          )}
                        </td>
                        {/* <td> */}
                        {e[f].otherforces.map((ele) => (
                          <tr className="row w-100 m-0 p-0">
                            <td style={{ width: "50%" }}>{ele.force}</td>
                            <td style={{ width: "50%" }}>{ele.count}</td>
                          </tr>
                        ))}
                        {/* </td> */}
                        <td>
                          {e[f].otherforces.reduce(
                            (acc, { count }) => acc + count,
                            0
                          )}
                        </td>
                        <td>
                          {e[f].forces.reduce(
                            (acc, { count }) => acc + count,
                            0
                          ) +
                            e[f].otherforces.reduce(
                              (acc, { count }) => acc + count,
                              0
                            )}
                        </td>
                      </>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <h3>DETAILS OF VP CATEGORIES</h3>
          <table className="table table-sm table-bordered  bg-white text-left">
            <thead>
              <th style={{ width: "10%" }}>Sr.No</th>
              <th style={{ width: "20%" }}>CATEGORY</th>
              <th className="m-0 border-0 p-0 " style={{ width: "30%" }}>
                <th className="col-12 m-0 p-0 text-center d-flex justify-content-center font-weight-bold">
                  FORCE
                </th>
                <div className="row w-100 p-0 m-0">
                  <th style={{ width: "33.33%" }}>FORCE</th>
                  <th style={{ width: "33.33%" }}>Rank</th>
                  <th style={{ width: "33.33%" }}>COUNT</th>
                </div>
              </th>
              <th className="col-4 m-0 p-0" style={{ width: "30%" }}>
                <th className="col-12 m-0 p-0 text-center d-flex justify-content-center font-weight-bold">
                  OTHER FORCE
                </th>
                <div className="w-100 row p-0 m-0">
                  <th style={{ width: "50%" }}>FORCE</th>
                  <th style={{ width: "50%" }}>COUNT</th>
                </div>
              </th>
            </thead>
            {Object.keys(finalDataMore).map((e, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{e}</td>
                {finalDataMore[e].map((ele) =>
                  ele.force.map((f) => (
                    <tr className="row m-0 p-0">
                      <td style={{ width: "33.33%" }}>{f.force}</td>
                      <td style={{ width: "33.33%" }}>{f.rank}</td>
                      <td style={{ width: "33.34%" }}>{f.count}</td>
                    </tr>
                  ))
                )}
                <td className="border-0">
                  {finalDataMore[e].map((ele) =>
                    ele.otherforce.map((f) => (
                      <tr className="row m-0 p-0">
                        <td style={{ width: "50%" }}>{f.force}</td>
                        <td style={{ width: "50%" }}>{f.count}</td>
                      </tr>
                    ))
                  )}
                </td>
              </tr>
            ))}
          </table>
          {/* new table */}
          {/* <table
        className="table table-bordered  bg-white modify-table text-uppercase "
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th>S.No.</th>

            <th>category</th>
            <th colSpan={2}>FORCE</th>

            <th colSpan={2}>OTHER FORCE</th>
          </tr>
          <tr>
            <th></th>

            <th></th>
            <th>Rank</th>

            <th>Count</th>
            <th>Rank</th>

            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={4} scope="row">
              1.
            </td>
            <td rowSpan={4}>hdsjhjhdsjahjdh</td>
            <td>hwdwhdkw</td>
            <td>10</td>
            <td>hwdwhdkw</td>
            <td>10</td>
          </tr>
          <tr>
            <td>hwdwhdkw</td>
            <td>10</td>
            <td>hwdwhdkw</td>
            <td>10</td>
          </tr>
          <tr>
            <td>hwdwhdkw</td>
            <td>10</td>
            <td>hwdwhdkw</td>
            <td>10</td>
          </tr>
          <tr>
            <td>hwdwhdkw</td>
            <td>10</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table> */}
        </div>
      </div>
    );
  }
}

export default VpCategories;
