import React, { useState, useEffect, useRef } from "react";
import { Printer } from "react-bootstrap-icons";
import { useFetch } from "use-http";
import { CUSTOM_URL } from "../..";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { count } from "console";
import Goback from "../../common/components/Goback";
function TotalVp() {
  const { data: totalVP } = useFetch(
    `${CUSTOM_URL}/directus/custom/totalVp`,
    {},
    []
  );

  /* state for forces */
  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);

  const [forceState, setForceState] = useState<any>([]);
  const [rankState, setRankState] = useState<any>([]);
  const [otherforceState, setOtherforceState] = useState<any>([]);
  const [battalionState, setbattalionState] = useState<any>([]);
  /* state for forces */

  const [totalVPAfterNameState, settotalVPAfterNameState] = useState<any>([]);
  useEffect(() => {
    setRankState(rank?.data);
  }, [rank]);

  useEffect(() => {
    setbattalionState(battalion?.data);
  }, [battalion]);

  useEffect(() => {
    setOtherforceState(otherforce?.data);
  }, [otherforce]);

  useEffect(() => {
    setForceState(force?.data);
  }, [force]);

  // function to group by array
  const groupByKey = (list, key) =>
    list?.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  //console.log("TOTAL VP", groupByKey(totalVP?.data, "type"));

  const [dataWithForceCount, setDataWithForceCount] = useState<any>([]);

  useEffect(() => {
    let sortedByVP = groupByKey(totalVP?.data, "type"); // grouping vps by type
    // console.log("sortedByVpCategory", sortedByVP);
    // console.log("sortedByVP", Object.keys(sortedByVP));

    // here we collecting all forces og particular profile
    let allCategoryWise =
      sortedByVP &&
      Object.keys(sortedByVP)?.map((k) => {
        let force_array: any = [];
        let otherforce_array: any = [];

        sortedByVP[k]?.map((data) => {
          console.log(
            "Parsed other Deployment",
            JSON.parse(data?.force_deployemnt)
          );
          force_array.push(JSON.parse(data?.force_deployemnt));
          otherforce_array.push(JSON.parse(data?.other_deployment));
        });
        let obj = {
          name: k,
          totalpoint: sortedByVP[k].length,
          force: force_array.flat(),
          otherforce: otherforce_array.flat(),
        };
        // console.log("force_array", force_array.flat());
        // console.log("force_array", obj);
        return obj;
      });
    //console.log("allCategoryWise", allCategoryWise);

    // converting force numbers into names
    let districtVPAfterName = allCategoryWise?.map((e: any) => {
      e?.force?.map((data) => {
        data.force = forceState && forceState[Number(data?.force) - 1]?.name;
      });
      e.otherforce.map((data) => {
        data.force =
          otherforceState && otherforceState[Number(data?.force) - 1]?.name;
      });
      return e;
    });

    // grouping by force to get count

    let dataWithForceCount = districtVPAfterName?.map((e) => {
      let forceWise = groupByKey(e.force, "force");
      let otherforceWise = groupByKey(e.otherforce, "force");

      let forceCount = Object.keys(forceWise)?.map((k) => {
        let obj = {
          force: k,
          count: forceWise[k].reduce(
            (acc, { count }) => acc + Number(count),
            0
          ),
        };
        return obj;
      });
      let otherforceWiseCount = Object.keys(otherforceWise)?.map((k) => {
        let obj = {
          force: k,
          count: otherforceWise[k].reduce(
            (acc, { count }) => acc + Number(count),
            0
          ),
        };
        return obj;
      });

      let obj = {
        name: e.name,
        totalpoint: e.totalpoint,
        force: forceCount,
        otherforce: otherforceWiseCount,
      };
      //console.log("obj", obj);
      return obj;
    });

    setDataWithForceCount(dataWithForceCount);
    //console.log("districtVPAfterName", dataWithForceCount);
  }, [totalVP, forceState, otherforceState]);

  useEffect(() => {
    //removing slashes
    const totalVPAfterSlash: any = [];
    totalVP?.data?.map((force) => {
      totalVPAfterSlash.push({
        name: force.name,
        category: force.category,
        force: JSON.parse(force?.force_deployemnt),
        otherforce: JSON.parse(force?.other_deployment),
      });
    });

    // a have change name of force from number to string
    let districtVPAfterName = totalVPAfterSlash?.map((e: any) => {
      e?.force?.map((ee: any) => {
        ee.force = forceState && forceState[Number(ee?.force - 1)]?.name;
        ee.battalion =
          battalionState && battalionState[Number(ee?.battalion - 1)]?.name;
        ee.rank = rankState && rankState[Number(ee?.rank - 1)]?.name;
      });
      e?.otherforce?.map((ee: any) => {
        ee.force =
          otherforceState && otherforceState[Number(ee?.force - 1)]?.name;
      });
      return e;
    });

    settotalVPAfterNameState(districtVPAfterName);
  }, [totalVP, forceState, rankState, battalionState]);

  // console.log("totalVP", totalVP);

  // console.log("totalVPAfterNameState", totalVPAfterNameState);
  const [finalData, setfinalData] = useState<any>([]);

  const [forceCount, setForceCount] = useState<number>(0);
  const [otherForceCount, setotherForceCount] = useState<number>(0);
  useEffect(() => {
    let sortedByforceAndOtherForce = totalVPAfterNameState.flatMap(
      (each, i) => {
        return {
          name: each.name,
          category: each.category,
          force: groupByKey(each.force, "force"),
          otherforce: groupByKey(each.otherforce, "force"),
        };
      }
    );

    // console.log("sortedByforceAndOtherForce", sortedByforceAndOtherForce)
    const totalSumByForceAndVp = sortedByforceAndOtherForce.map(
      (sortedForce) => {
        // console.log("sortedForce", sortedForce)
        let fullarrywithname: any = [];
        let arrByForce: any = [];
        let arrByOtherForce: any = [];

        // for force
        Object.keys(sortedForce?.force)?.map((key) => {
          let count = 0;
          let force = "";
          let battalion = "";
          sortedForce?.force[key].map((e) => {
            force = e.force;
            battalion = e.battalion;
            count += e.count;
          });
          let countByForce = {
            force: force,
            count: count,
            battalion: battalion,
          };
          arrByForce.push(countByForce);
        });
        // console.log("arrByForce", arrByForce)

        // for other force
        Object.keys(sortedForce?.otherforce)?.map((key) => {
          let count = 0;
          let force = "";

          sortedForce?.otherforce[key].map((e) => {
            force = e.force;
            count += e.count;
          });
          let countByForce = {
            force: force,
            count: count,
          };
          arrByOtherForce.push(countByForce);
        });

        fullarrywithname.push({
          name: sortedForce.name,
          category: sortedForce.category,
          force: arrByForce,
          otherforce: arrByOtherForce,
        });
        return fullarrywithname;
      }
    );
    // console.log("districtVPAfterNameState", sortedByforceAndOtherForce)
    // console.log("totalSumByForceAndVp", totalSumByForceAndVp) //final data
    setfinalData(totalSumByForceAndVp.flat(2));
  }, [totalVPAfterNameState]);

  // console.log("totalSumByForceAndVp",)

  // console.log("finalData TOtal VP", finalData)

  const [groupByVp, setGroupByVp] = useState<any>();
  useEffect(() => {
    const groupByVP = groupByKey(finalData, "category");
    // console.log("groupByVP", groupByVP)

    let forces = Object.keys(groupByVP).map((k) => {
      const gbk = groupByVP[k].map((f) => {
        let obj = {};
        obj[k] = f.force.map((e) => e);
        return obj;
      });
      return gbk.flat();
      let category = "";
      // groupByVP[k].map((eachVp) => {
      //   eachVp.force.map((f) => {
      //     category = k;
      //     forceState.map((fs) => {
      //       if (fs.name == f.name) {

      //       }
      //     })

      //   })
      // })
    });

    // console.log("forces", forces);
    setGroupByVp(groupByVP);
    // console.log("group By VP", groupByVP);
  }, [finalData]);

  useEffect(() => {
    setForceCount(
      finalData.map((e) =>
        e.force.reduce((acc: number, { count }) => (acc += Number(count)), 0)
      )
    );
    setotherForceCount(
      finalData.map((e) =>
        e.otherforce.reduce(
          (acc: number, { count }) => (acc += Number(count)),
          0
        )
      )
    );
  }, [finalData]);
  // console.log(forceCount, otherForceCount)
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [totalForceCount, setTotalForceCount] = useState<number>(0);

  useEffect(() => {
    let total: number = 0;
    finalData?.forEach((e: any, i: number) => {
      total += Number(otherForceCount[i]);
      total += Number(forceCount[i]);
    });
    setTotalForceCount(total);
  }, [finalData, forceCount, otherForceCount]);

  //console.log("dataWithForceCount", dataWithForceCount);
  // console.log("group by force", groupByKey(dataWithForceCount, "force"))

  const [eachForceData, setEachForceData] = useState<any>([]);

  useEffect(() => {
    let total_force_array: any = [];
    dataWithForceCount?.map((e, i) => {
      total_force_array.push(groupByKey(e.force, "force"));
    });

    let eachforceArrray: any = [];
    total_force_array?.map((e) => {
      // console.log("total_key", Object.keys(e)[0])
      Object.keys(e).map((each) => {
        eachforceArrray?.push(e[each][0]);
      });
    });

    let eachForceGroupBY = groupByKey(eachforceArrray, "force");
    // console.log(" total_each", Object.keys(groupByKey(eachforceArrray, "force")))
    let countForEachForce: any = [];
    Object.keys(eachForceGroupBY)?.map((key) => {
      // console.log("total_each", key, eachForceGroupBY[key].reduce((ac, { count }) => ac + count, 0))
      countForEachForce?.push({
        force: key,
        count: eachForceGroupBY[key].reduce(
          (ac, { count }) => ac + Number(count),
          0
        ),
      });
    });

    setEachForceData(countForEachForce);
  }, [dataWithForceCount]);

  useEffect(() => {}, [eachForceData]);
  //console.log("each", eachForceData);

  return (
    <div>
      <Goback />
      <div className="px-4" ref={componentRef}>
        {/* <h3 className="mb-5 mt-5">
        Total VP <Printer />
      </h3> */}
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="mb-5 mt-5">
            SUMMARY OF FORCE DEPLOYED ON VP CATEGORIES IN PUNJAB, CHANDIGARH AND
            DELHI
          </h3>
          {/* <Printer className="mb-5 mt-5" /> */}
          <ReactToPrint
            bodyClass={`print-styles`}
            removeAfterPrint={true}
            pageStyle="@page { size: landscape; } "
            trigger={() => (
              <button
                className="btn float-right my-3 btn-sm btn-primary"
                // onClick={handlePrint}
              >
                <Printer className="mr-2" />
                PRINT REPORT
              </button>
            )}
            content={() => componentRef.current!}
          />
        </div>
        {
          <table
            style={{ maxWidth: "100vw" }}
            className="table bg-white table-bordered  text-left  text-uppercase"
          >
            <thead>
              <th style={{ width: "10%" }}>Sr.No</th>
              <th style={{ width: "10%" }}>VP Name</th>
              <th style={{ width: "10%" }}>category</th>
              {/* <th>Total Points</th> */}
              <table className="w-100 bg-white">
                <tr className="col-12 py-2 text-center d-flex justify-content-center font-weight-bold">
                  FORCE
                </tr>
                <tr className="w-100 border-top border-bottom-0 ">
                  <th style={{ width: "50%" }}>FORCE</th>
                  {/* <th style={{ width: "33%" }}>battalion</th> */}
                  <th style={{ width: "50%" }}>Count</th>
                </tr>
              </table>
              <th style={{ width: "10%" }}>TOTAL </th>
              {/* <th className="">OTHER FORCE</th> */}
              <table className="w-100">
                <tr className="col-12 py-2 text-center d-flex justify-content-center font-weight-bold">
                  OTHER FORCE
                </tr>
                <tr className="border-top w-100">
                  <th style={{ width: "50%" }}>FORCE</th>
                  <th style={{ width: "50%" }}>COUNT</th>
                </tr>
              </table>
              <th style={{ width: "10%" }}>TOTAL </th>
              <th style={{ width: "10%" }}>GRAND TOTAL</th>
              {/* new code */}
            </thead>
            {finalData &&
              finalData.map((e, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{e.name}</td>
                    <td> {e.category}</td>
                    {/* <td> {Object.keys(groupByVp).reduce(function (previous, key) { return groupByVp[key].length }, 0)}</td> */}
                    <table className="table vat mb-0 border-0 text-uppercase">
                      {/* <th style={{ width: "200px" }}>FORCE</th>
                    <th style={{ width: "200px" }}>battalion</th>
                    <th style={{ width: "200px" }}>COUNT</th> */}
                      {e.force.map((ee) => (
                        <tr className="w-100">
                          <td style={{ width: "50%" }}>{ee.force}</td>
                          {/* <td>{ee.battalion}</td> */}
                          <td style={{ width: "50%" }}>{ee.count}</td>
                        </tr>
                      ))}
                    </table>
                    <td>{forceCount[i]}</td>
                    <td className="m-0 p-0">
                      <table className="table mb-0 border-0 text-uppercase">
                        {/* <th style={{ width: "200px" }}>FORCE</th>
                      <th style={{ width: "200px" }}>COUNT</th> */}
                        {e.otherforce.map((ee) => (
                          <tr className="w-100">
                            <td style={{ width: "50%" }}>{ee.force}</td>
                            <td style={{ width: "50%" }}>{ee.count}</td>
                          </tr>
                        ))}
                      </table>
                    </td>
                    <td>{otherForceCount[i]}</td>
                    <td>{otherForceCount[i] + forceCount[i]}</td>
                  </tr>
                );
              })}
            <tr>
              <th>Total</th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <th>{totalForceCount}</th>
            </tr>
          </table>
        }
        <h3 className="text-uppercase mb-5 mt-5">
          Category and force wise breakup
        </h3>
        {
          <table className="table bg-white table-bordered  text-left vat text-uppercase">
            <thead>
              {/* <th className="col-lg-1">Sr.no</th>
            <th className="col-lg-4">Name</th>
            <th className="col-lg-4 p-0 m-0">
              <tr className="text-center d-flex justify-content-center font-weight-bold">
                FORCE
              </tr>
              <tr className="border-top border-bottom-0 row p-0 m-0">
                <th className="col-6">FORCE</th>
                <th className="col-6">Count</th>
              </tr>

            </th>
            <th>TOTAL</th>
            <th className="col-lg-4 m-0 p-0 bg-white">
              <tr className="text-center d-flex justify-content-center font-weight-bold">OTHER FORCE</tr>
              <tr className="border-top row border-bottom-0 p-0 m-0">
                <th className="col-6">FORCE</th>
                <th className="col-6">Count</th>
              </tr>
            </th>
            <th>TOTAL</th>
            <th>GRAND TOTAL</th> */}
              <th className="" style={{ width: "5%" }}>
                Sr.no
              </th>
              <th className="" style={{ width: "20%" }}>
                Name
              </th>
              <th className="" style={{ width: "5%" }}>
                Total Points
              </th>
              <th className="p-0 m-0" style={{ width: "25%" }}>
                <tr className="text-center d-flex justify-content-center font-weight-bold">
                  FORCE
                </tr>
                <tr className="border-top border-bottom-0 row p-0 m-0">
                  <th className="col-6">FORCE</th>
                  <th className="col-6">Count</th>
                </tr>
              </th>
              <th style={{ width: "5%" }}>TOTAL</th>
              <th className=" m-0 p-0 bg-white">
                <tr className="text-center d-flex justify-content-center font-weight-bold">
                  OTHER FORCE
                </tr>
                <tr className="border-top row border-bottom-0 p-0 m-0">
                  <th style={{ width: "50%" }}>FORCE</th>
                  <th style={{ width: "50%" }}>Count</th>
                </tr>
              </th>
              <th style={{ width: "5%" }}>TOTAL</th>
              <th style={{ width: "5%" }}>GRAND TOTAL</th>
            </thead>
            {dataWithForceCount &&
              dataWithForceCount?.map((data, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{data.name}</td>
                  <td>{data?.totalpoint}</td>
                  <td className="p-0 m-0">
                    {data?.force.map((d) => (
                      <tr className="row p-0 m-0">
                        <td className="col-lg-6">{d.force}</td>
                        <td className="col-lg-6">{d.count}</td>
                      </tr>
                    ))}
                  </td>
                  <td>
                    {data?.force.reduce(
                      (acc, { count }) => acc + Number(count),
                      0
                    )}
                  </td>
                  <td className="p-0 m-0">
                    {data?.otherforce.map((d) => (
                      <tr className="row p-0 m-0">
                        <td className="col-lg-6">{d.force}</td>
                        <td className="col-lg-6">{d.count}</td>
                      </tr>
                    ))}
                  </td>
                  <td>
                    {data?.otherforce.reduce(
                      (acc, { count }) => acc + Number(count),
                      0
                    )}
                  </td>
                  <td style={{ width: "10%" }}>
                    {data?.force.reduce(
                      (acc, { count }) => acc + Number(count),
                      0
                    ) +
                      data?.otherforce.reduce(
                        (acc, { count }) => acc + Number(count),
                        0
                      )}
                  </td>
                </tr>
              ))}
          </table>
        }

        <h3 className="text-uppercase mb-5 mt-5">force wise breakup</h3>

        <table className=" bg-white table-bordered text-left vat text-uppercase mb-4">
          <thead>
            <th style={{ width: "20%" }} className="p-2">
              Force
            </th>
            <th style={{ width: "20%" }} className="p-2">
              Count
            </th>
          </thead>
          <tbody>
            {eachForceData &&
              eachForceData?.map((e) => (
                <tr>
                  <td className="p-2">{e.force}</td>
                  <td className="p-2">{e.count}</td>
                </tr>
              ))}
          </tbody>
        </table>

        {/* new table */}
        {/* <table
        className="table table-bordered  bg-white modify-table text-uppercase "
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th>S.No.</th>
            <th>VP Name</th>
            <th>category</th>
            <th colSpan={3}>FORCE</th>
            <th>TOTAL FORCE</th>
            <th colSpan={2}>OTHER FORCE</th>
            <th>TOTAL OTHER FORCE</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Force</th>
            <th>Battalion</th>
            <th>count</th>
            <th></th>
            <th>Force</th>
            <th>count</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={4} scope="row">
              1.
            </td>
            <td rowSpan={4}>hdsjhjhdsjahjdh</td>
            <td rowSpan={4}>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td rowSpan={4}>60</td>
            <td>hdksjf</td>
            <td>35</td>
            <td rowSpan={4}>60</td>
            <td rowSpan={4}>60</td>
          </tr>
          <tr>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdksjf</td>
            <td>35</td>
          </tr>
          <tr>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdksjf</td>
            <td>35</td>
          </tr>
          <tr>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td rowSpan={4} scope="row">
              1.
            </td>
            <td rowSpan={4}>hdsjhjhdsjahjdh</td>
            <td rowSpan={4}>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td rowSpan={4}>60</td>
            <td>hdksjf</td>
            <td>35</td>
            <td rowSpan={4}>60</td>
            <td rowSpan={4}>60</td>
          </tr>
          <tr>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdksjf</td>
            <td>35</td>
          </tr>
          <tr>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdksjf</td>
            <td>35</td>
          </tr>
          <tr>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td>hdsjhjhdsjahjdh</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    */}
      </div>
    </div>
  );
}

export default TotalVp;
