import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "./types";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null as IUser | null,
    isLoggedIn: false,
    token: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setToken: (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
