import '../../scss/style.scss';
import logo from '../../assets/images/logo.png';
export const LoadingScreen = () =>{
        return(
            <div>
                <div className="loading-screen">
                    <img src={logo} alt="Punjab Police"></img>
                </div>
            </div>
        )
}