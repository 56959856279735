import React from "react";
import LoginPage from "./pages/login/login";
// import HomePage from "./pages/home/Home";
import HomePage from "./pages/home/home";
import { Profile } from "./pages/profile/Profile";
import { Assignment } from "./pages/assignment/Assignment";
import { useSelector } from "react-redux";
import AppWrapper from "./common/components/AppWrapper";
import { IRoute, IUser } from "./reducers/types";
import logo from "./assets/images/logo.png";
import { userSlice } from "./reducers/user";
import { ArrowRightShort, Power } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { PATHS, routeSlice, ROUTES, NON_ADMIN } from "./reducers/routes";
import "overlayscrollbars/css/OverlayScrollbars.css";
import { Provider } from "use-http";
import { API_URL } from "./index";
import { AddProfile } from "./pages/profile/AddProfile";
import { Assign } from "./pages/assignment/Assign";
import Deployment from "./pages/non_admin/deployment/deployment";
import Deploy from "./pages/non_admin/deployment/deploy";
import PageNotFound from "./pages/PageNotFound/pageNotFound";
import EditProfile from "./pages/profile/EditProfile";
import EditAssignment from "./pages/assignment/EditAssignment";
import Reports from "./pages/home/reports";
import Inspection from "./pages/inspection/Inspection";
import Review from "./pages/inspection/review";
import ProfileInspection from "./pages/inspection/profileInspection";
import ForcesDeployment from "./pages/home/forcesDeployment";
import Paid from "./pages/home/paid";
import VpCategories from "./pages/home/vpCategories";
import TotalVp from "./pages/home/totalVp";
import DistrictVp from "./pages/home/districtVp";
import RangeWise from "./pages/home/rangeWise";
import Files from "./pages/Files/Files";

function App() {
  const [declineModel, setDeclineModel] = React.useState(false); //modal for logout

  const [isLoggedIn, routes, user, token, userDetail]: [
    boolean,
    IRoute[],
    IUser,
    string,
    any
  ] = useSelector((state: any) => [
    state.user.isLoggedIn,
    state.routes.routes,
    state.user.user,
    state.user.token,
    state.user.userDetail,
  ]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isLoggedIn) {
      if (user.role === "1") {
        dispatch(routeSlice.actions.setRoutes("admin"));
      } else {
        dispatch(routeSlice.actions.setRoutes("user"));
      }
    }
  }, [isLoggedIn, dispatch, user?.role]);

  const isLogedIn = () => {
    if (localStorage.getItem("token")) {
      return true;
    }
    return false;
  };

  const refresh = () => {
    console.log(localStorage.getItem("token"));
    fetch(API_URL + "directus/auth/refresh", {
      body: JSON.stringify({ token: localStorage.getItem("token") }),
      method: "post",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => {
        localStorage.removeItem("refreshing");
        res.json().then((a) => {
          // console.log("res ", a?.data?.token)
          localStorage.setItem("token", a?.data?.token);
          dispatch(userSlice.actions.setToken(a?.data?.token));
        });
      })
      .catch((e) => console.log("ERRR in refreshing", e));
  };
  // setInterval(() => {
  //   if (isLogedIn()) {
  //     refresh();
  //   }
  // }, 1080000);

  return (
    <Provider
      url={API_URL + "directus"}
      options={{
        headers: {
          Authorization: `Bearer ${token}`,
        },
        interceptors: {
          response: async ({ response }) => {
            if (response.status === 401) {
              dispatch({ type: "logout" });
              window.location.href = "/";
            } else if (response.status >= 200 && response.status < 400) {
              // if (!localStorage.getItem("refreshing")) {
              //   localStorage.setItem("refreshing", "1");
              //   fetch(API_URL + "directus/auth/refresh", {
              //     body: JSON.stringify({ token: JSON.parse(localStorage.getItem("persistantState") || "")?.user?.token }),
              //     method: "post",
              //     headers: {
              //       "Content-type": "application/json",
              //     },
              //   }).then((res) => {
              //     localStorage.removeItem("refreshing");
              //     res.json().then((a) => dispatch(userSlice.actions.setToken));
              //   }).catch((e) => console.log("ERRR in refreshing", e));
              // }
              // refresh()
            }
            return response;
          },
        },
      }}
    >
      <>
        {isLoggedIn ? (
          <AppWrapper
            sideBar={
              <React.Fragment>
                <div className="header">
                  <div className="branding">
                    <div className="logo">
                      <img className="img-fluid" src={logo} alt="" />
                    </div>
                    <div>
                      <p className="mb-0" style={{ lineHeight: "1.25" }}>
                        <span className="text-bold">
                          Institutional Security
                        </span>
                        <br />
                        <span className="text-bold">Allocation Portal</span>
                      </p>
                    </div>
                  </div>
                  <div className="username">
                    <small className="text-faded pb-0">Welcome back,</small>
                    <p className="mb-0">{user.first_name}</p>
                    {/* {userDetail && (
                      <small>
                        {userDetail.unit
                          ? userDetail.unit.title + " Unit"
                          : userDetail.formation.title + " Formation"}
                      </small>
                    )} */}
                  </div>
                </div>
                <div className="nav">
                  {routes.flatMap((route: IRoute) => (
                    <div
                      key={route.slug}
                      onClick={() => {
                        dispatch(routeSlice.actions.setActive(route));
                        window.location.href = route.route;
                      }}
                      className={`nav--item ${route.active ? "active" : ""}`}
                    >
                      {route.label}
                      <div className="icon">
                        <ArrowRightShort />
                      </div>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            }
            headerRight={
              <React.Fragment>
                {/* <div className="item">
                  <Bell />
                </div> */}
                {/* ///logout  */}
                <div
                  onClick={() => {
                    // dispatch({ type: "logout" });
                    // window.location.href = "/";
                    setDeclineModel(true);
                  }}
                  className="item"
                >
                  <Power />
                </div>
                {declineModel && (
                  <>
                    <div className="modal-backdrop"></div>
                    <div
                      className="modal"
                      id="modelId"
                      tabIndex={-1}
                      role="dialog"
                      style={{ display: "block" }}
                      aria-labelledby="modelTitleId"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-scrollable modal-sm"
                        role="document"
                      >
                        <div className="modal-content">
                          {/* <div className="modal-header">
                            <h5 className="modal-title">Logout confirm request</h5>
                            <button
                              type="button"
                              className="close"
                              onClick={() => setDeclineModel(false)}
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div> */}
                          <div className="modal-body">
                            Are you sure you want to Logout?
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-light btn-sm"
                              onClick={() => setDeclineModel(false)}
                              data-dismiss="modal"
                            >
                              No
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={async () => {
                                dispatch({ type: "logout" });
                                window.location.href = "/";
                              }}
                              data-dismiss="modal"
                            >
                              Logout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </React.Fragment>
            }
          >
            <Router>
              {user?.role === "1" ? (
                <Switch>
                  <Route exact path={PATHS.dashboard}>
                    <HomePage />
                  </Route>
                  <Route exact path={ROUTES.addProfile}>
                    <AddProfile />
                  </Route>
                  <Route exact path={PATHS.profile}>
                    <Profile />
                  </Route>
                  <Route exact path={ROUTES.editProfile}>
                    <EditProfile />
                  </Route>
                  <Route exact path={ROUTES.editAssignment}>
                    <EditAssignment />
                  </Route>
                  <Route exact path={ROUTES.assign}>
                    <Assign />
                  </Route>
                  <Route exact path={PATHS.assignment}>
                    <Assignment />
                  </Route>
                  <Route exact path="/">
                    <Redirect to={PATHS.dashboard} />
                  </Route>
                  <Route exact path={PATHS.reports}>
                    <Reports />
                  </Route>
                  <Route exact path={PATHS.files}>
                    <Files />
                  </Route>
                  {/* single reports  */}

                  <Route exact path={PATHS.forcesdeployment}>
                    <ForcesDeployment />
                  </Route>
                  <Route exact path={PATHS.paid}>
                    <Paid />
                  </Route>
                  <Route exact path={PATHS.vpcategories}>
                    <VpCategories />
                  </Route>
                  <Route exact path={PATHS.totalvp}>
                    <TotalVp />
                  </Route>
                  <Route exact path={PATHS.districtvp}>
                    <DistrictVp />
                  </Route>
                  <Route exact path={PATHS.rangewise}>
                    <RangeWise />
                  </Route>

                  {/* single reports  */}
                  <Route exact path={ROUTES.profileInspection}>
                    <ProfileInspection />
                  </Route>
                  <Route exact path={ROUTES.review}>
                    <Review />
                  </Route>

                  <Route component={PageNotFound} />
                </Switch>
              ) : (
                <Switch>
                  <Route exact path={NON_ADMIN.deploy}>
                    <Deployment />
                  </Route>
                  <Route exact path={ROUTES.deployment}>
                    <Deploy />
                  </Route>
                  <Route exact path={ROUTES.profileInspection}>
                    <ProfileInspection />
                  </Route>
                  <Route exact path={ROUTES.review}>
                    <Review />
                  </Route>
                  <Route exact path="/">
                    <Redirect to={NON_ADMIN.deploy} />
                  </Route>
                  <Route exact path={NON_ADMIN.inspection}>
                    <Inspection />
                  </Route>

                  <Route component={PageNotFound} />
                </Switch>
              )}
            </Router>
          </AppWrapper>
        ) : (
          <LoginPage />
        )}
      </>
    </Provider>
  );
}

export default App;
