import React, { ReactElement } from "react";

interface Props {
  title: string;
  desc?: string;
  actionButton?: HTMLButtonElement;
}

export default function Empty({
  title,
  desc,
  actionButton,
}: Props): ReactElement {
  return (
    <div className="error-msg">
      <h2 className="">{title ? title : "No Data Available"}</h2>
      <p className="text-muted">{desc && desc}</p>
      {actionButton && actionButton}
    </div>
  );
}
