import React, { useEffect, useState, useRef } from "react";
import { Printer } from "react-bootstrap-icons";
import { useFetch } from "use-http";
import { CUSTOM_URL } from "../..";

import ReactToPrint, { useReactToPrint } from "react-to-print";
import ForcesDeployment from "./forcesDeployment";
import { LoadingScreen } from "../../common/components/LoadingScreen";
import Goback from "../../common/components/Goback";
import { count } from "console";
function DistrictVp() {
  const { data: districtVP } = useFetch(
    `${CUSTOM_URL}/directus/custom/districtVp`,
    {},
    []
  );

  /* state for forces */
  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);

  const [forceState, setForceState] = useState<any>([]);
  const [rankState, setRankState] = useState<any>([]);
  const [otherforceState, setOtherforceState] = useState<any>([]);
  const [battalionState, setbattalionState] = useState<any>([]);
  /* state for forces */

  const [districtVPAfterNameState, setdistrictVPAfterNameState] = useState<any>(
    []
  );
  useEffect(() => {
    setRankState(rank?.data);
  }, [rank]);

  useEffect(() => {
    setbattalionState(battalion?.data);
  }, [battalion]);

  useEffect(() => {
    setOtherforceState(otherforce?.data);
  }, [otherforce]);

  useEffect(() => {
    setForceState(force?.data);
  }, [force]);

  // function to group by array
  const groupByKey = (list, key) =>
    list?.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  console.log("districtVP", districtVP);
  useEffect(() => {
    //removing slashes
    const districtVPAfterSlash: any = [];
    districtVP?.data?.map((force) => {
      let str = force?.force_deployemnt.replace(/\\|\//g, "");
      let str1 = force?.other_deployment.replace(/\\|\//g, "");
      districtVPAfterSlash.push({
        name: force.name,
        district: force.district,
        force: JSON.parse(str),
        otherforce: JSON.parse(str1),
      });
    });

    console.log("districtVPAfterSlash", districtVPAfterSlash);
    // a have change name of force from number to string
    let districtVPAfterName = districtVPAfterSlash?.map((e: any) => {
      e?.force?.map((ee: any) => {
        ee.force = forceState && forceState[Number(ee?.force - 1)]?.name;
        ee.battalion =
          battalionState && battalionState[Number(ee?.battalion - 1)]?.name;
        ee.rank = rankState && rankState[Number(ee?.rank - 1)]?.name;
      });

      e?.otherforce?.map((ee: any) => {
        ee.force =
          otherforceState && otherforceState[Number(ee?.force - 1)]?.name;
      });
      console.log("e", e);
      return e;
    });
    setdistrictVPAfterNameState(districtVPAfterName);
  }, [districtVP, forceState, rankState, battalionState]);

  console.log("districtVPAfterName", districtVPAfterNameState);

  const [finalData, setfinalData] = useState<any>([]);

  useEffect(() => {
    let sortedByforceAndOtherForce = districtVPAfterNameState.flatMap(
      (each, i) => {
        return {
          name: each.name,
          district: each.district,
          force: groupByKey(each.force, "force"),
          otherforce: groupByKey(each.otherforce, "force"),
        };
      }
    );
    // console.log("sortedByforceAndOtherForce", sortedByforceAndOtherForce)
    const totalSumByForceAndVp = sortedByforceAndOtherForce.map(
      (sortedForce) => {
        // console.log("sortedForce", sortedForce)
        let fullarrywithname: any = [];
        let arrByForce: any = [];
        Object.keys(sortedForce?.force)?.map((key) => {
          let count = 0;
          let force = "";
          let battalion = "";
          let rank = "";
          sortedForce?.force[key].map((e) => {
            // console.log("e", sortedForce.name, e.force)
            force = e.force;
            rank = e.rank;
            battalion = e.battalion;
            count += e.count;
          });
          let countByForce = {
            force: force,
            rank: rank,
            battalion: battalion,
            count: count,
          };
          arrByForce.push(countByForce);
        });
        // console.log("arrByForce", arrByForce)
        fullarrywithname.push({
          name: sortedForce.name,
          district: sortedForce.district,
          force: arrByForce,
        });

        return fullarrywithname;
      }
    );
    // console.log("districtVPAfterNameState", sortedByforceAndOtherForce)
    // console.log("totalSumByForceAndVp", totalSumByForceAndVp) //final data
    setfinalData(totalSumByForceAndVp.flat());
  }, [districtVPAfterNameState]);

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [totalSum, SetTotalSum] = useState(0);

  const calTotalSum = () => {
    let arr = finalData.map((e) =>
      e.force.reduce((acc, { count }) => (acc += Number(count)), 0)
    );
    SetTotalSum(arr.reduce((acc, val) => acc + Number(val), 0));
  };
  const [rankCounts, setRankCounts] = useState<
    { rank: string; count: number }[]
  >([]);
  const [forceCounts, setForceCounts] = useState<any[]>([]);
  useEffect(() => {
    calTotalSum();
    let groupedByRank: any = {};
    let groupedByForce: any = {};
    finalData.forEach(({ force }) => {
      let gByRank = groupByKey(force, "rank");
      let gByForce = groupByKey(force, "force");
      Object.keys(gByRank).forEach((e) => {
        groupedByRank?.[e]
          ? (groupedByRank[e] = [...groupedByRank[e], ...gByRank[e]])
          : (groupedByRank[e] = gByRank[e]);
      });
      Object.keys(gByForce).forEach((e) => {
        groupedByForce?.[e]
          ? (groupedByForce[e] = [...groupedByForce[e], ...gByForce[e]])
          : (groupedByForce[e] = gByForce[e]);
      });
    });
    console.log(groupedByRank);
    setRankCounts(
      Object.keys(groupedByRank).map((e) => {
        return Object.assign({
          rank: e,
          count: groupedByRank[e]?.reduce(
            (acc: number, { count }) => acc + Number(count),
            0
          ),
        });
      })
    );
    setForceCounts(
      Object.keys(groupedByForce)?.map((e) => {
        let forceWiseRank = groupByKey(groupedByForce[e], "rank");
        return Object.assign({
          force: e,
          ranks: Object.keys(forceWiseRank).map((r) => {
            return Object.assign({
              rank: forceWiseRank[r][0].rank,
              count: forceWiseRank[r]?.reduce(
                (acc: number, { count }) => acc + Number(count),
                0
              ),
            });
          }),
        });
      })
    );
    console.log(forceCounts);
  }, [finalData]);
  if (districtVP == undefined) {
    return <LoadingScreen />;
  } else {
    return (
      <div>
        <Goback />
        <div className="px-4" ref={componentRef}>
          <div className="d-flex justify-content-between align-items-center text-uppercase">
            <h3 className="mb-5 mt-5">
              Static guard on Govt Building in Punjab{" "}
            </h3>
            {/* <Printer className="mb-5 mt-5" onClick={handlePrint} /> */}
            <ReactToPrint
              bodyClass={`print-styles`}
              removeAfterPrint={true}
              pageStyle="@page { size: landscape; } "
              trigger={() => (
                <button
                  className="btn float-right my-3 btn-sm btn-primary"
                  // onClick={handlePrint}
                >
                  <Printer className="mr-2" />
                  PRINT REPORT
                </button>
              )}
              content={() => componentRef.current!}
            />
          </div>
          <table
            className="table table-bordered  bg-white custom-border-table text-left text-uppercase "
            style={{
              maxWidth: "95vw",
            }}
          >
            <thead>
              <th className="" style={{ width: "10%" }}>
                Sr.No
              </th>
              <th className="" style={{ width: "20%" }}>
                DESCRIPTION OF
              </th>
              <th className="" style={{ width: "20%" }}>
                DISTT.
              </th>
              <table className="w-100">
                {/* <th style={{ width: "20%" }}>FORCE</th> */}
                {/* <th className="col-4">Kind of Force</th> */}
                <th style={{ width: "50%" }}>RANK</th>
                {/* <th style={{ width: "50%" }}>battalion</th> */}
                <th style={{ width: "50%" }}>COUNT</th>
              </table>
              <th className="" style={{ width: "10%" }}>
                TOTAL
              </th>
            </thead>
            {finalData &&
              finalData.map((e, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{e.name}</td>
                    <td> {e.district}</td>
                    <table className="table mb-0  text-uppercase">
                      {e.force.map((ee, i) => (
                        <tr key={i}>
                          {/* <td style={{ width: "20%" }}>{ee.force}</td> */}
                          {/* <td className="col-4">{ee.force}</td> */}
                          <td
                            className=""
                            style={{
                              // borderRight: "1px solid ",
                              width: "50%",
                            }}
                          >
                            {ee.rank}
                          </td>
                          <td
                            style={{
                              width: "50%",
                              // borderRight: "1px solid "
                            }}
                            className=""
                          >
                            {ee.count}
                          </td>
                        </tr>
                      ))}
                    </table>
                    <td>
                      {e.force.reduce(
                        (acc, { count }) => (acc += Number(count)),
                        0
                      )}
                    </td>
                  </tr>
                );
              })}
            <tr className="bg-light">
              <th></th>
              <td>Total</td>
              <td></td>
              <td></td>
              <td>{totalSum}</td>
            </tr>
          </table>
          {/* 
          <div className="w-50">
            <ForcesDeployment />
          </div> */}
          <h3 className="mb-5 mt-5 text-uppercase">Rank wise Breakup</h3>
          <table className="table bg-white table-bordered table-sm text-left text-uppercase col-6">
            <thead className="p-0 m-0 w-100">
              <th style={{ width: "10%" }}>Sr.No</th>
              <th style={{ width: "33.33%" }}>Rank</th>
              <th style={{ width: "33.34%" }}>Count</th>
            </thead>
            {rankCounts.map((data, i) => (
              <tr key={i} className="w-100 p-0 m-0">
                <td>{i + 1}</td>
                <td>{data?.rank}</td>
                <td>{data?.count}</td>
              </tr>
            ))}
          </table>

          <h3 className="mb-5 mt-5 text-uppercase">Force wise Breakup</h3>
          <table className="table bg-white table-bordered table-sm text-left text-uppercase col-6">
            {/* console.log("each", each[0], each[1]) */}
            <thead>
              <th style={{ width: "10%" }}>Sr.No</th>
              <th style={{ width: "20%" }}>FORCE</th>
              <table className="m-0 p-0" style={{ width: "100%" }}>
                <th style={{ width: "50%" }}>RANK</th>
                <th style={{ width: "50%" }}>COUNT</th>
              </table>
            </thead>
            <tbody>
              {forceCounts?.map((each, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{each?.force}</td>
                  {each?.ranks?.map((e) => (
                    <tr className="row m-0 p-0" style={{ width: "100%" }}>
                      <td style={{ width: "50%" }}>{e?.rank}</td>
                      <td style={{ width: "50%" }}>{e?.count}</td>
                    </tr>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default DistrictVp;
