import React, { useState, useEffect, useRef } from "react";
import { useFetch } from "use-http";
import Select from "react-select";
import { CUSTOM_URL } from "../..";
import ReactToPrint, { useReactToPrint } from "react-to-print";

import { LoadingScreen } from "../../common/components/LoadingScreen";
import Goback from "../../common/components/Goback";
import { BorderTop, Printer } from "react-bootstrap-icons";
import TotalForces from "./totalForces";
import Deployment from "./../non_admin/deployment/deployment";
function RangeWise() {
  const { data: ranges, loading: loadRanges } = useFetch(
    `/items/ranges`,
    {},
    []
  ); // for ranges
  const { data: category, loading: loadCat } = useFetch(
    `/items/profile_category`,
    {},
    []
  );

  const { data: allProfile, loading: loadProfile } = useFetch(
    `${CUSTOM_URL}/directus/custom/getAllVp`,
    {},
    []
  );
  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);

  const { data: jurisdiction, loading: loadjurisdiction } = useFetch(
    `items/ranges?fields=jurisdiction.jurisdiction_id.*,name,id`,
    {},
    []
  ); // for  jurisdiction
  const [jurisdictionData, setJurisdictionData] = useState<any>([]);

  console.log("jurisdictionData", jurisdictionData);
  const [forceState, setForceState] = useState<any>([]);
  const [rankState, setRankState] = useState<any>([]);
  const [otherforceState, setOtherforceState] = useState<any>([]);
  const [battalionState, setbattalionState] = useState<any>([]);

  const [Allprofile, setAllProfile] = useState<any>();
  const [filterByRange, setFilterByRange] = useState<any>();
  const [filterByCategory, setFilterByCategory] = useState<any>();
  const [dataAfterFilter, setDataAfterFilter] = useState<any>([]);

  const [selectedRange, setSelectedRange] = useState<string>("");
  const [selectedDistricts, setSelectedDistricts] = useState<string>("");
  const [selectedVpCategory, setSelectedVpCategory] = useState<string>("");
  const [selectedPoint, setSelectedPoint] = useState<string>("");

  useEffect(() => {
    setRankState(rank?.data);
  }, [rank]);

  useEffect(() => {
    setbattalionState(battalion?.data);
  }, [battalion]);

  useEffect(() => {
    setForceState(force?.data);
  }, [force]);

  useEffect(() => {
    setAllProfile(allProfile?.data);
  }, [allProfile]);

  const [finalData, setFinalData] = useState<any>([]);

  useEffect(() => {
    let dataAfterjson: any = [];
    Allprofile?.map((e) => {
      dataAfterjson.push({
        range: e.range,
        category: e.category,
        jurisdiction:
          jurisdictionData &&
          jurisdictionData[Number(e?.jurisdiction) - 1]?.name,
        profile: e.profile,
        force_deployemnt: JSON.parse(e?.force_deployemnt),
        other_deployment: JSON.parse(e?.other_deployment),
        assignment_by_rank: JSON.parse(e?.assignment_by_rank),
      });
    });

    let AfterName = dataAfterjson
      ?.filter((e) => e?.force_deployemnt !== null)
      // ?.filter((e) => JSON.parse(e?.force_deployemnt).length !== 0)
      ?.map((e: any) => {
        e?.force_deployemnt?.map((ee: any) => {
          ee.force = forceState && forceState[Number(ee?.force - 1)]?.name;
          ee.battalion =
            battalionState && battalionState[Number(ee?.battalion - 1)]?.name;
          ee.rank = rankState && rankState[Number(ee?.rank - 1)]?.name;
        });
        e?.other_deployment?.map((ee: any) => {
          ee.force =
            otherforce &&
            otherforce?.data.find((e) => e.id == [Number(ee?.force)])?.name;
          ee.rank = rankState && rankState[Number(ee?.rank - 1)]?.name;
        });
        return e;
      });

    setFinalData(AfterName);
    // console.log(finalData);

    setDataAfterFilter(AfterName);
  }, [Allprofile, rankState, forceState, battalionState, jurisdictionData]);

  // console.log("selectedDistricts", selectedDistricts);
  // console.log("selectedRange", selectedRange);
  // console.log("selectedVpCategory", selectedVpCategory);
  console.log("asdfghjkjhgfdss", { finalData });
  React.useEffect(() => {
    if (selectedRange == "ALL") {
    } else {
      setJurisdictionData(
        jurisdiction?.data
          ?.filter((each) => each.name == selectedRange)[0]
          ?.jurisdiction?.map((e) => e?.jurisdiction_id)
      );
    }
  }, [selectedRange]);
  const resetRange = useRef<any>();
  const resetDistrict = useRef<any>();
  const resetCategory = useRef<any>();
  const resetPoint = useRef<any>();

  const onReset = () => {
    resetRange?.current.select.clearValue();
    resetDistrict?.current.select.clearValue();
    resetCategory?.current.select.clearValue();
    resetPoint?.current.select.clearValue();
    setDataAfterFilter(finalData);
    window.location.reload();
    // setDataAfterFilter(Allprofile);
  };

  const onFilter = () => {
    let filterData: any = [];
    // all feilds are all
    if (
      selectedRange == "ALL" &&
      selectedVpCategory == "ALL" &&
      selectedDistricts == "ALL"
    ) {
      setDataAfterFilter(finalData);
    } else if (selectedDistricts == "ALL" && selectedRange == "ALL") {
      setDataAfterFilter(finalData);
    }
    // else if (selectedDistricts == "ALL" && selectedRange == "ALL") {
    //     setDataAfterFilter(finalData);
    // }

    // else if (selectedRange == "ALL") {
    //     filterData = finalData?.filter((each) => each.category == selectedVpCategory)
    //     setDataAfterFilter(filterData)
    // }
    // else if (selectedVpCategory == "ALL") {
    //     filterData = finalData?.filter((each) => each.range == selectedRange);
    //     setDataAfterFilter(filterData)
    // }
    // else {
    //     filterData = finalData?.filter((each) => each?.range == selectedRange).filter((each) => each?.category == selectedVpCategory);
    //     setDataAfterFilter(filterData)
    // }
    else {
      filterData = finalData
        ?.filter((each) => each?.range == selectedRange)
        ?.filter((each) => each?.jurisdiction == selectedDistricts)
        ?.filter((each) => each?.category == selectedVpCategory);

      setDataAfterFilter(filterData);
    }
  };

  React.useEffect(() => {
    setJurisdictionData(
      jurisdiction?.data
        ?.map((each) => each.jurisdiction)
        .flat(1)
        .map((e) => e.jurisdiction_id)
    );
  }, [jurisdiction]);

  const [pointName, setPointName] = useState([]);

  // will run when selectedRange change
  // const [dataInRange, setDataInRange] = useState<any>([])
  useEffect(() => {
    let filterData: any = [];
    if (selectedRange == "ALL") {
      setDataAfterFilter(finalData);
      setPointName(
        finalData.map((each) => {
          return { name: each.profile };
        })
      );
    } else {
      filterData = finalData?.filter((each) => each.range == selectedRange);
      setDataAfterFilter(filterData);
      setPointName(
        filterData.map((each) => {
          return { name: each.profile };
        })
      );
    }
  }, [selectedRange]);

  // // will run when selectedDistricts change

  console.log("pointName", pointName);
  useEffect(() => {
    let filterData: any = [];
    if (selectedDistricts == "ALL") {
      setDataAfterFilter(dataAfterFilter);
      setPointName(
        dataAfterFilter.map((each) => {
          return { name: each.profile };
        })
      );
    } else {
      filterData = dataAfterFilter?.filter(
        (each) => each.jurisdiction == selectedDistricts
      );
      setDataAfterFilter(filterData);
      setPointName(
        filterData.map((each) => {
          return { name: each.profile };
        })
      );
    }
  }, [selectedDistricts]);

  // // will run when selectedVpCategory change

  useEffect(() => {
    let filterData: any = [];
    if (selectedVpCategory == "ALL") {
      setDataAfterFilter(dataAfterFilter);
      setPointName(
        dataAfterFilter.map((each) => {
          return { name: each.profile };
        })
      );
    } else {
      filterData = dataAfterFilter?.filter(
        (each) => each.category == selectedVpCategory
      );
      setDataAfterFilter(filterData);
      setPointName(
        filterData.map((each) => {
          return { name: each.profile };
        })
      );
    }
  }, [selectedVpCategory]);

  // will run when points change
  useEffect(() => {
    let filterData: any = [];
    if (selectedPoint == "ALL") {
      setDataAfterFilter(dataAfterFilter);
      setPointName(
        dataAfterFilter.map((each) => {
          return { name: each.profile };
        })
      );
    } else {
      filterData = dataAfterFilter?.filter(
        (each) => each.profile == selectedPoint
      );
      setDataAfterFilter(filterData);
      setPointName(
        filterData.map((each) => {
          return { name: each.profile };
        })
      );
    }
  }, [selectedPoint]);

  const [totalSanction, setTotalSanction] = useState<Number>(0);
  const [totalDeploy, setTotalDeploy] = useState<number>(0);
  const [totalOtherDeploy, setTotalOtherDeploy] = useState<number>(0);
  useEffect(() => {
    let TotalSanctioned: number = 0;
    let deployed: number = 0;
    let otherdeployed: number = 0;
    dataAfterFilter?.map((e) => {
      if (e?.assignment_by_rank == null) {
        TotalSanctioned += 0;
      } else {
        TotalSanctioned += e?.assignment_by_rank?.reduce(
          (acc, { count }) => acc + Number(count),
          0
        );
      }
      if (e?.force_deployemnt == null) {
        deployed += 0;
      } else {
        deployed += e?.force_deployemnt?.reduce(
          (acc, { count }) => acc + Number(count),
          0
        );
      }
      if (e?.other_deployment == null) {
        otherdeployed += 0;
      } else {
        otherdeployed += e?.other_deployment?.reduce(
          (acc, { count }) => acc + Number(count),
          0
        );
      }
    });
    setTotalSanction(TotalSanctioned);
    setTotalDeploy(deployed);
    setTotalOtherDeploy(otherdeployed);
  }, [dataAfterFilter]);

  // console.log("allProfile?.data", allProfile?.data)
  // console.log("filterByRange", filterByRange)
  // console.log("filterByCategory", filterByCategory)
  console.log("dataAfterFilter", dataAfterFilter);
  console.log("finalData", finalData);

  // function to group by array
  const groupByKey = (list, key) =>
    list?.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );
  // run when final data change

  const [formationData, setFormationData] = useState<any>([]);
  const [formationDataRank, setFormationDataRank] = useState<any>([]);
  useEffect(() => {
    const AllforceArray: any = [];
    dataAfterFilter.map((each) => {
      each?.force_deployemnt?.map((e) => AllforceArray.push(e));
    });
    let dataGroupByForce: any = groupByKey(AllforceArray, "force"); // group by force
    let dataGroupByRank: any = groupByKey(AllforceArray, "rank"); // group by rank
    let formationData = Object.keys(dataGroupByForce)?.map((e) => {
      let count = dataGroupByForce[e]?.reduce(
        (acc, { count }) => acc + Number(count),
        0
      );
      let obj = {
        force: e,
        count: count,
      };
      return obj;
    });
    setFormationData(formationData);

    // overall rank count
    let formationRank = Object.keys(dataGroupByRank)?.map((e) => {
      let count = dataGroupByRank[e]?.reduce(
        (acc, { count }) => acc + Number(count),
        0
      );
      let obj = {
        rank: e,
        count: count,
      };
      return obj;
    });
    setFormationDataRank(formationRank);
  }, [dataAfterFilter]);

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (allProfile == undefined) {
    return <LoadingScreen />;
  } else {
    return (
      <div className="print">
        <style type="text/css" media="print">
          {"\
   @page { size: landscape; }\
"}
        </style>

        <Goback />
        <div className="px-4 text-uppercase">
          <div className="card p-4 card-sm mt-3 h-auto">
            <h2>Range wise Report</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Range</label>
                  <Select
                    ref={resetRange}
                    isDisabled={selectedRange}
                    onChange={(items) => {
                      setSelectedRange(items?.name);
                    }}
                    getOptionLabel={(option) => `${option?.name}`}
                    getOptionValue={(option) => `${option?.name}`}
                    options={[{ name: "ALL" }].concat(ranges?.data)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Districts</label>
                  <Select
                    ref={resetDistrict}
                    isDisabled={selectedDistricts}
                    onChange={(items) => {
                      setSelectedDistricts(items?.name);
                    }}
                    getOptionLabel={(option) => `${option?.name}`}
                    getOptionValue={(option) => `${option?.id}`}
                    options={[{ name: "ALL" }].concat(jurisdictionData)}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Category</label>
                  <Select
                    ref={resetCategory}
                    isDisabled={selectedVpCategory}
                    onChange={(items) => {
                      setSelectedVpCategory(items?.name);
                    }}
                    getOptionLabel={(option) => `${option?.name}`}
                    getOptionValue={(option) => `${option?.name}`}
                    options={[{ name: "ALL" }].concat(category?.data)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Points</label>
                  <Select
                    ref={resetPoint}
                    isDisabled={selectedPoint}
                    onChange={(items) => {
                      setSelectedPoint(items?.name);
                    }}
                    getOptionLabel={(option) => `${option?.name}`}
                    getOptionValue={(option) => `${option?.name}`}
                    options={[{ name: "ALL" }].concat(pointName)}
                  />
                </div>
              </div>
              {console.log("selectedpoint", selectedPoint)}

              {/* <div className="col-md-2">
                                <div onClick={onFilter} className="mt-4 btn btn-primary">FILTER</div>
                            </div> */}
              <div className="col-md-2">
                <button
                  onClick={onReset}
                  className="mt-4 btn btn-primary"
                  disabled={selectedRange == "" ? true : false}
                >
                  RESET
                </button>
              </div>
            </div>
          </div>
          {dataAfterFilter ? (
            <div className="card p-4 mt-3 h-auto " ref={componentRef}>
              <div className="col-lg-12">
                <ReactToPrint
                  bodyClass={`print-styles`}
                  removeAfterPrint={true}
                  pageStyle="@page { size: landscape; }, "
                  trigger={() => (
                    <button
                      className="btn float-right my-3 btn-sm btn-primary"
                      // onClick={handlePrint}
                    >
                      <Printer className="mr-2" />
                      PRINT REPORT
                    </button>
                  )}
                  content={() => componentRef.current!}
                />
              </div>
              <div className="col-lg-12 p-0 my-2 ">
                <h4>
                  {selectedRange == "ALL" ? (
                    <>
                      <span>Report for</span>
                      <span className="text-secondary"> All range </span>
                    </>
                  ) : selectedRange ? (
                    <>
                      <span>Report for </span>
                      <span className="text-secondary">{selectedRange}</span>
                    </>
                  ) : (
                    <></>
                  )}
                  {selectedVpCategory == "ALL" ? (
                    <>
                      <span>and</span>{" "}
                      <span className="text-secondary">All category </span>
                    </>
                  ) : selectedVpCategory ? (
                    <>
                      <span>and</span>
                      <span className="text-secondary">
                        {selectedVpCategory}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </h4>
              </div>
              <div
                className="table bg-white table-bordered text-left vat text-uppercase mb-5 "
                // style={{ width: "150vw", overflowX: "scroll" }}
              >
                <thead>
                  <th style={{ width: "5%", fontSize: ".8rem" }}>S.No</th>
                  <th style={{ width: "8%", fontSize: ".8rem" }}>Name</th>
                  <th style={{ width: "8%", fontSize: ".8rem" }}>category</th>
                  <th style={{ width: "8%", fontSize: ".8rem" }}>Range</th>
                  <th style={{ width: "8%", fontSize: ".8rem" }}>District</th>
                  <th className="m-0 p-0 " style={{ width: "15%" }}>
                    <th className="border-0 m-0 p-0 text-center d-flex justify-content-center font-weight-bold">
                      FORCE SANCTIONED
                    </th>
                    <div className="row p-0 m-0">
                      <th style={{ width: "60%", fontSize: ".8rem" }}>Rank</th>
                      <th style={{ width: "40%", fontSize: ".8rem" }}>COUNT</th>
                    </div>
                  </th>
                  {/* <th style={{ width: "5%" }}>Total</th> */}
                  <th className="m-0 p-0 " style={{ width: "20%" }}>
                    <th className="col-12 border-0 m-0 p-0 text-center d-flex justify-content-center font-weight-bold">
                      FORCE DEPLOYED
                    </th>
                    <div className="row w-100 p-0 m-0">
                      <th style={{ width: "35%", fontSize: ".8rem" }}>force</th>
                      <th style={{ width: "35%", fontSize: ".8rem" }}>Rank</th>
                      <th style={{ width: "30%", fontSize: ".8rem" }}>COUNT</th>
                    </div>
                  </th>
                  <th className="m-0 p-0 " style={{ width: "20%" }}>
                    <th className="col-12 border-0 m-0 p-0 text-center d-flex justify-content-center font-weight-bold">
                      OTHER DEPLOYMENT
                    </th>
                    <div className="row w-100 p-0 m-0">
                      <th style={{ width: "35%", fontSize: ".8rem" }}>Force</th>
                      <th style={{ width: "35%", fontSize: ".8rem" }}>Rank</th>
                      <th style={{ width: "30%", fontSize: ".8rem" }}>COUNT</th>
                    </div>
                  </th>
                  <th style={{ fontSize: ".8rem" }}>Total</th>
                  {/* <th style={{ width: "5%" }}>Grand Total</th> */}
                </thead>
                <tbody className="custom-border">
                  {dataAfterFilter?.length == 0 && (
                    <p className="text-center">no data found</p>
                  )}

                  {dataAfterFilter?.map((each, i) => (
                    <>
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td style={{ fontSize: ".8rem" }}>{each.profile}</td>
                        <td style={{ fontSize: ".8rem" }}>{each.category}</td>
                        <td style={{ fontSize: ".8rem" }}>{each.range}</td>
                        <td style={{ fontSize: ".8rem" }}>
                          {each.jurisdiction}
                        </td>

                        <td
                          className="p-0 m-0"
                          style={{
                            backgroundColor: "rgb(230 235 242 / 72%)",
                          }}
                        >
                          {each?.assignment_by_rank?.map((e) => (
                            <tr className="row border-0 p-0 m-0">
                              <td style={{ width: "60%", fontSize: "0.8rem" }}>
                                {e.rank}
                              </td>
                              <td
                                style={{
                                  width: "40%",
                                  fontSize: "0.8rem",
                                  textAlign: "end",
                                }}
                              >
                                {e.count}
                              </td>
                            </tr>
                          ))}
                          {/* <tr className="row border-top p-0 m-0">
                            <td
                              style={{
                                width: "60%",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                              }}
                            >
                              Total
                            </td>
                            <td
                              style={{
                                width: "40%",
                                fontSize: "0.8rem",
                                fontWeight: "bold",
                              }}
                            >
                              {each?.assignment_by_rank?.reduce(
                                (acc, { count }) => acc + Number(count),
                                0
                              )}
                            </td>
                          </tr> */}
                        </td>
                        {each?.force_deployemnt?.length > 0 ? (
                          <td className="p-0 m-0">
                            {each?.force_deployemnt?.map((e) => (
                              <tr className="row border-0 p-0 m-0">
                                <td
                                  style={{ width: "32%", fontSize: "0.8rem" }}
                                >
                                  {e.force}
                                </td>
                                <td
                                  style={{ width: "38%", fontSize: "0.8rem" }}
                                >
                                  {e.rank}
                                </td>
                                <td
                                  style={{
                                    width: "30%",
                                    fontSize: "0.8rem",
                                    textAlign: "end",
                                  }}
                                >
                                  {e.count}
                                </td>
                              </tr>
                            ))}

                            {/* {each?.force_deployemnt?.length > 0 ? (
                            <tr className="row  border-top p-0 m-0">
                              <td
                                style={{
                                  width: "70%",
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                }}
                              >
                                Total
                              </td>
                              <td
                                className=""
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                }}
                              >
                                {each.force_deployemnt !== null
                                  ? each?.force_deployemnt?.reduce(
                                      (acc, { count }) => acc + Number(count),
                                      0
                                    )
                                  : 0}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td
                                style={{
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                }}
                              >
                                No Deployment
                              </td>
                            </tr>
                          )
                          } */}
                          </td>
                        ) : (
                          <td
                            style={{
                              fontSize: "0.8rem",
                              textAlign: "center",
                            }}
                          >
                            No Deployment
                          </td>
                        )}
                        {each?.other_deployment?.length > 0 ? (
                          <td className="p-0 m-0">
                            {each?.other_deployment?.map((e) => (
                              <tr className="row border-0 p-0 m-0">
                                <td
                                  style={{ width: "35%", fontSize: "0.8rem" }}
                                >
                                  {e.force}
                                </td>
                                <td
                                  style={{ width: "35%", fontSize: "0.8rem" }}
                                >
                                  {e.rank || "---"}
                                </td>
                                <td
                                  style={{
                                    width: "30%",
                                    fontSize: "0.8rem",
                                    textAlign: "end",
                                  }}
                                >
                                  {e.count}
                                </td>
                              </tr>
                            ))}
                            {/* { (
                            <tr className="row border-top p-0 m-0">
                              <td
                                style={{
                                  width: "70%",
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                }}
                              >
                                Total
                              </td>
                              <td
                                style={{
                                  width: "30%",
                                  fontSize: "0.8rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {each.other_deployment !== null
                                  ? each?.other_deployment?.reduce(
                                      (acc, { count }) => acc + Number(count),
                                      0
                                    )
                                  : 0}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td
                                style={{
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                }}
                              >
                                
                              </td>
                            </tr>
                          )} */}
                          </td>
                        ) : (
                          <td
                            style={{
                              fontSize: "0.8rem",
                              textAlign: "center",
                            }}
                          >
                            No Deployment
                          </td>
                        )}
                        <td
                          style={{
                            fontSize: ".8rem",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                          }}
                        >
                          {each.force_deployemnt !== null &&
                          each.other_deployment !== null
                            ? each?.force_deployemnt?.reduce(
                                (acc, { count }) => acc + Number(count),
                                0
                              ) +
                              each?.other_deployment?.reduce(
                                (acc, { count }) => acc + Number(count),
                                0
                              )
                            : 0}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: "0.8rem",
                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "end",
                            fontSize: "0.8rem",
                            fontWeight: "bold",
                          }}
                        >
                          {each?.assignment_by_rank?.reduce(
                            (acc, { count }) => acc + Number(count),
                            0
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "end",
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}
                        >
                          {each.force_deployemnt !== null
                            ? each?.force_deployemnt?.reduce(
                                (acc, { count }) => acc + Number(count),
                                0
                              )
                            : 0}
                        </td>
                        <td
                          style={{
                            textAlign: "end",
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}
                        >
                          {each.other_deployment !== null
                            ? each?.other_deployment?.reduce(
                                (acc, { count }) => acc + Number(count),
                                0
                              )
                            : 0}
                        </td>
                        <td></td>
                      </tr>
                    </>
                  ))}
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontWeight: "bold", textAlign: "end" }}>
                      {totalSanction}
                    </td>
                    {/* <td></td> */}
                    <td style={{ fontWeight: "bold", textAlign: "end" }}>
                      {totalDeploy}
                    </td>
                    <td style={{ fontWeight: "bold", textAlign: "end" }}>
                      {totalOtherDeploy}
                    </td>
                    <td style={{ fontWeight: "bold", textAlign: "end" }}>{`${
                      totalOtherDeploy + totalDeploy
                    }`}</td>
                    {/* <td>{Number(totalSanction) + Number(totalDeploy)}</td> */}
                  </tr>
                </tbody>
              </div>
              <div className="my-5 page-break"></div>
              <h4 className="p-0 my-2 mt-5">Formation</h4>
              {formationData.length !== 0 ? (
                <table className="table w-50 bg-white table-bordered table-sm text-left text-uppercase">
                  <thead>
                    <th>FORCE</th>
                    <th>COUNT</th>
                  </thead>
                  <tbody>
                    {formationData.map((each) => (
                      <tr>
                        <td>{each.force}</td>
                        <td>{each.count}</td>
                      </tr>
                    ))}
                    <tr>
                      <th>TOTAL</th>
                      <th>
                        {formationData?.reduce(
                          (acc, { count }) => acc + Number(count),
                          0
                        )}
                      </th>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <p className="p-4 text-center h4">
                  select range and category to view report
                </p>
              )}
              <br />
              <h4 className="p-0  my-2 mt-5">Rank Wise</h4>
              {formationDataRank.length !== 0 ? (
                <table className="table w-50 bg-white table-bordered table-sm text-left text-uppercase">
                  <thead>
                    <tr>
                      <th>RANK</th>
                      <th>COUNT</th>
                    </tr>
                  </thead>

                  <tbody>
                    {formationDataRank.map((each) => (
                      <tr>
                        <td>{each.rank}</td>
                        <td>{each.count}</td>
                      </tr>
                    ))}
                    <tr>
                      <th>TOTAL</th>
                      <th>
                        {formationDataRank?.reduce(
                          (acc, { count }) => acc + Number(count),
                          0
                        )}
                      </th>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <p className="p-4 text-center h4">
                  select range and category to view report
                </p>
              )}
            </div>
          ) : (
            <p className="p-4 text-center h4">
              select range and category to view report
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default RangeWise;
