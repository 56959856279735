import { ROUTES, PATHS } from "./../../reducers/routes";
import {
  FileEarmarkPlusFill,
  FileEarmark,
  FilePlusFill,
  PencilSquare,
  Plus,
  Trash,
  EyeFill,
} from "react-bootstrap-icons";
import React, { useState } from "react";
import { useFetch } from "use-http";
import ETable from "../../common/components/ETable/ETable";
import { LoadingScreen } from "../../common/components/LoadingScreen";
export const Profile = () => {
  const [state, setState] = React.useState<{
    page: number;
    query: string;
    sortBy: string;
    isAsc: boolean;
  }>({
    page: 1,
    query: "",
    sortBy: "id",
    isAsc: true,
  });
  const [viewInspectionModel, setViewInspectionModel] = useState(false);
  const [assignId, setAssignId] = useState(-1);

  const { patch, response } = useFetch(`/items/profile`);
  const { post } = useFetch(`/items/assignments`);
  const { get, data, loading: loadingProfile } = useFetch(`/items/profile`, {
    cache: "no-cache",
  });

  React.useEffect(() => {
    let url = "?fields=*.*&limit=10";
    if (state?.query && state.query.length > 0)
      url += `&meta=filter_count&query=${state.query}`;
    if (state?.page)
      url += `&meta=filter_count&offset=${(state.page - 1) * 10}`;
    if (state?.sortBy)
      url += `&meta=filter_count&sort=${
        state.isAsc ? state.sortBy : "-" + state.sortBy
      }`;
    get(url);
  }, [get, state]);

  const profileAssign = async (id) => {
    await patch(`/${id}`, {
      status: "requested",
    });
    if (response.ok) {
      await post({ status: "pending", profile: id });
      window.location.href = PATHS.profile;
    } else {
      alert("Something Went Wrong");
    }
  };
  console.log("data?.data", data?.data);

  return (
    <>
      {!data ? (
        <LoadingScreen />
      ) : (
        <React.Fragment>
          <div className="main-header d-flex justify-content-between">
            <div>
              <h1>All Profiles</h1>
            </div>
            <div className="">
              <div className="float-right">
                <a className="btn btn-secondary" href={ROUTES.addProfile}>
                  <Plus /> Add Profile
                </a>
              </div>
            </div>
          </div>

          <div className="main-body">
            <div className="card h-auto">
              <div className="card-body">
                <ETable<any>
                  columns={[
                    { key: "id", label: "Id" },
                    { key: "name", label: "Name" },
                    { key: "category.name", label: "Category" },
                    { key: "city", label: "City" },
                  ]}
                  totalPages={Math.ceil((data?.meta?.filter_count || 10) / 10)}
                  customColumns={[
                    {
                      heading: "Status",
                      renderer: (item) => (
                        <span
                          className={
                            "badge text-uppercase badge-pill " +
                            (item.status === "pending"
                              ? "badge-danger"
                              : item.status === "requested"
                              ? "badge-warning"
                              : item.status === "assigned"
                              ? "badge-success"
                              : item.status === "deployed"
                              ? "badge-success"
                              : "badge-danger")
                          }
                        >
                          {item.status === "requested"
                            ? "added to assignment"
                            : item.status === "deployed"
                            ? "deployed"
                            : "pending"}
                        </span>
                      ),
                    },
                    // {
                    //   heading: "Inspection Report",
                    //   renderer: (item) =>
                    //     <a data-toggle="tooltip" data-placement="top" title="View Inspection Report"
                    //       style={{ cursor: "pointer" }}
                    //       className="text-secondary"
                    //       onClick={async () => {
                    //         // setViewInspectionModel(true)
                    //         window.location.href = "/inspection/" + item.id;
                    //       }}>
                    //       View
                    //     </a>
                    // },
                    {
                      heading: "",
                      renderer: (item) =>
                        item.status === "pending" ? (
                          <div className="d-flex justify-content-space-between">
                            <button
                              className="btn btn-link"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Add to assignment"
                              style={{ cursor: "pointer" }}
                              onClick={() => setAssignId(item?.id)}
                            >
                              <FileEarmarkPlusFill className="h2 text-secondary" />
                            </button>

                            <a
                              className="ml-2"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit profile"
                              style={{ cursor: "pointer" }}
                              onClick={async () => {
                                window.location.href =
                                  "/editProfile/" + item.id;
                              }}
                            >
                              <PencilSquare className="h2 text-secondary" />
                            </a>
                          </div>
                        ) : (
                          <>
                            <a
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit profile"
                              style={{ cursor: "pointer" }}
                              onClick={async () => {
                                window.location.href =
                                  "/editProfile/" + item.id;
                              }}
                            >
                              <PencilSquare className="h2 text-secondary" />
                            </a>
                          </>
                        ),
                    },
                  ]}
                  onChange={(query, sortKey, isSortAsc, page) => {
                    setState({
                      query,
                      sortBy: sortKey,
                      isAsc: isSortAsc,
                      page,
                    });
                  }}
                  items={data?.data || []}
                  actionButtons={[]}
                />
              </div>
            </div>
          </div>

          {/* View profile modal */}
          {viewInspectionModel && (
            <>
              <div className="modal-backdrop"></div>
              <div
                className="modal"
                id="modelId"
                tabIndex={-1}
                role="dialog"
                style={{ display: "block" }}
                aria-labelledby="modelTitleId"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-scrollable  modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Inspection Report</h5>
                      <button
                        type="button"
                        className="close text-danger"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setViewInspectionModel(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body bg-light">{/* constent */}</div>
                  </div>
                </div>
              </div>
            </>
          )}
          {assignId !== -1 && (
            <>
              <div className="modal-backdrop"></div>
              <div
                className="modal"
                id="modelId"
                tabIndex={-1}
                role="dialog"
                style={{ display: "block" }}
                aria-labelledby="modelTitleId"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-scrollable modal-sm"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-body">
                      Are you sure you want to Assign?
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() => setAssignId(-1)}
                        data-dismiss="modal"
                      >
                        No
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={async () => {
                          await profileAssign(assignId);
                          alert("Profile added to assignment");
                        }}
                        data-dismiss="modal"
                      >
                        yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* View profile modal end*/}
        </React.Fragment>
      )}
    </>
  );
};
