import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "use-http";
import EFileUpload from "../../common/components/EFileUpload/EFileUpload";
import { API_URL } from "../../index";
import { ROUTES, PATHS } from "../../reducers/routes";
import { Trash, Archive, PersonFill, PlusSquare } from "react-bootstrap-icons";
import { MapComponent } from "../../common/components/MapComponent";
import { LoadingScreen } from "../../common/components/LoadingScreen";
import moment from "moment";
import { FILE_URL } from "../../constant";

function EditProfile() {
  const { id } = useParams<{ id: string }>();
  const documentUploadRef = React.useRef<HTMLInputElement>(null);
  const sopUploadRef = React.useRef<HTMLInputElement>(null);

  const [profileData, setProfileData] = React.useState<any>({
    // status: "pending",
    name: "",
    category: null,
    // type: null,
    vp_category: null,
    bank_category: null,
    address: "",
    city: "",
    state: "",
    pincode: null,
    district: "",
    latitude: "",
    longitude: "",
    document: "",
    paid: false,
    profile_image: "",
    satellite_image: "",
    profile_documents: [],
    sops: [],
    range: null,
    jurisdiction: null,
  });

  console.log("profileData", profileData);
  const [editModel, setEditModel] = React.useState(false);
  const [documentArray, setDocumentArray] = React.useState<any>([]);
  const [document, setDocument] = React.useState("");
  const [documentId, setDocumentId] = React.useState<number>();
  const [docName, setDocName] = React.useState("");

  const [fileToUpload, setFileToUpload] = useState<any>();
  const formData = new FormData();

  const { post } = useFetch(
    `${FILE_URL}fileupload/${new Date().valueOf()}`,
    // "${FILE_URL}fileupload/" + new Date().valueOf(),
    { cache: "no-cache" }
  );

  // const { post } = useFetch("/files?fields=private_hash&t=" + new Date().valueOf(), { cache: "no-cache", });
  const { data: category } = useFetch(`/items/profile_category`, {}, []);
  // const { data: type } = useFetch(`/items/profile_type`, {}, []);
  const { data: ranges, loading: loadRanges } = useFetch(
    `/items/ranges`,
    {},
    []
  ); // for ranges
  const { data: vpcategory } = useFetch(`/items/vp_category`, {}, []);
  const { data: bank_category } = useFetch(`/items/bank_category`, {}, []);
  const { get, data, loading: loadingProfile } = useFetch(
    `/items/profile/${id}?fields=*.*.*&single=1`,
    {},
    []
  );
  const { patch: insertProfile, response, error } = useFetch(
    `/items/profile`,
    {}
  );
  // const { delete: removedoc, response: deleteres } = useFetch(`/files`, {});
  const { post: removedoc, response: deleteres } = useFetch(
    `${FILE_URL}filedelete`,
    {}
  );

  const { data: jurisdiction, loading: loadjurisdiction } = useFetch(
    `items/ranges?fields=jurisdiction.jurisdiction_id.*,name,id`,
    {},
    []
  ); // for

  const [refresh, setRefresh] = React.useState(false);

  const [sopDoc, setSopDoc] = React.useState("");
  const [sopID, setSopID] = React.useState<number>();
  const [sopName, setSopName] = React.useState("");
  const [sopFileToUpload, setSopFileToUpload] = useState<any>();
  const formDataSop = new FormData();
  const [sopArray, setSopArray] = React.useState<any>([]);

  const [JSRDoc, setJSRDoc] = React.useState("");
  const [JSRID, setJSRID] = React.useState<number>();
  const [JSRName, setJSRName] = React.useState("");
  const [JSRDate, setJSRDate] = React.useState("");
  const [JSRArray, setJSRArray] = React.useState<any>([]);

  const [deltefileModal, setDeltefileModal] = React.useState(false);
  const [deleteFileID, setDeleteFileID] = React.useState("");
  const [deleteFileType, setDeleteFileType] = React.useState("");

  const [showImage, setShowImage] = useState<string>("");
  const [showSatelliteImage, setShowSatelliteImage] = useState<string>("");

  const deletefile = async (id, feild) => {
    const token = localStorage.getItem("token");
    /*  do not change **tokenInput** variable */
    const tokenInput = "qwertyytrewq";
    await removedoc(`${id}?${tokenInput}=${token}`);
    if (deleteres.ok) {
      let newarr = [];
      if (feild == "profile_documents") {
        newarr = profileData?.profile_documents.filter((e) => e.id !== id);
        setProfileData((prevState) => {
          return Object.assign({}, prevState, {
            profile_documents: newarr,
          });
        });
        setDocumentArray(newarr);
      }
      if (feild == "sops") {
        newarr = profileData?.sops.filter((e) => e.id !== id);
        setProfileData((prevState) => {
          return Object.assign({}, prevState, {
            sops: newarr,
          });
        });
        setSopArray(newarr);
      }
      if (feild == "JSR") {
        newarr = profileData?.JSR.filter((e) => e.id !== id);
        setProfileData((prevState) => {
          return Object.assign({}, prevState, {
            JSR: newarr,
          });
        });
        setJSRArray(newarr);
        setJSRDate("");
      }
      alert(`${feild} File deleted successfully`);
    } else {
      alert("Something Went Wrong");
    }
    setDeleteFileID("");
    setDeleteFileType("");
  };

  const _fillByPincode = async (val) => {
    setProfileData((prevState) => {
      return Object.assign({}, prevState, {
        pincode: val,
      });
    });
    await fetch(`https://api.postalpincode.in/pincode/${val}`)
      .then((response) => response.json())
      .then((data) => {
        if (val.length > 6) {
          setProfileData((prevState) => {
            return Object.assign({}, prevState, {
              city: "",
              state: "",
              district: "",
            });
          });
        }
        if (val.length === 6 && data[0].PostOffice.length !== 0) {
          setProfileData((prevState) => {
            return Object.assign({}, prevState, {
              city: data[0].PostOffice[0].Block,
              state: data[0].PostOffice[0].State,
              district: data[0].PostOffice[0].District,
            });
          });
        }
      });
  };

  // fetching file to view
  const fetchFile = (id, type) => {
    const token = localStorage.getItem("token");
    /*  do not change **tokenInput** variable */
    const tokenInput = "qwertyytrewq";
    fetch(`${FILE_URL}getfile/${id}?${tokenInput}=${token}`)
      .then((data) => {
        if (type == "imgProfile") {
          setShowImage(data?.url);
        } else if (type == "imgSatellite") {
          setShowSatelliteImage(data?.url);
        } else {
          data?.status == 200 && window.open(data?.url, "_blank");
        }
      })
      .catch((error) => alert("error in displaying file"));

    // fetch(`http://live.espranza.in:6063/files/${id}?${tokenInput}=${token}`)
    //   .then((data) => {
    //     data?.status == 200 && window.open(data?.url, '_blank')
    //   }).catch(() => alert("error in displaying file"))
  };

  React.useEffect(() => {
    setProfileData((prev) => ({
      ...prev,
      name: data?.data?.name,
      category: data?.data?.category.id,
      // type: data?.data?.type.id,
      vp_category: data?.data?.vp_category?.id,
      bank_category: data?.data?.bank_category?.id,
      address: data?.data?.address,
      city: data?.data?.city,
      state: data?.data?.state,
      pincode: data?.data?.pincode,
      district: data?.data?.district,
      latitude: data?.data?.latitude,
      longitude: data?.data?.longitude,
      document: data?.data?.document,
      profile_image: data?.data?.profile_image,
      satellite_image: data?.data?.satellite_image,
      profile_documents: data?.data?.profile_documents || [],
      sops: data?.data?.sops || [],
      paid: data?.data?.paid,
      range: data?.data?.range?.id,
      jurisdiction: data?.data?.jurisdiction,
    }));

    if (data?.data?.profile_image) {
      fetchFile(data?.data?.profile_image, "imgProfile");
    }
    if (data?.data?.satellite_image) {
      fetchFile(data?.data?.satellite_image, "imgSatellite");
    }
  }, [data]);
  const [jurisdictionData, setJurisdictionData] = useState<any>([]);

  React.useEffect(() => {
    // console.log("profileData.range", profileData.range)
    setJurisdictionData(
      jurisdiction?.data?.filter((each) => each.id == profileData.range)[0]
        ?.jurisdiction
    );
  }, [profileData.range]);

  return (
    <>
      {loadingProfile ? (
        <LoadingScreen />
      ) : (
        <React.Fragment>
          <div className="main-header ">
            <div className="row">
              <div className="col-lg-6">
                <h1>Edit Profile</h1>
              </div>
              <div className="col-lg-6 d-flex justify-content-end">
                <a
                  className="btn btn-outline-dark btn-sm mr-2"
                  href={PATHS.profile}
                >
                  Cancel
                </a>
                <button
                  onClick={async () => {
                    setEditModel(true);
                  }}
                  className="btn btn-primary btn-sm"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="main-body text-uppercase">
            <div className="card">
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <h2>Name and Location</h2>
                  </div>
                  <div className="col-lg-4">
                    <h2>Profile Image</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">Name</label>
                          <input
                            type="text"
                            required
                            className="form-control form-control-sm"
                            value={profileData.name || undefined}
                            onChange={(e) => {
                              const val = e.target.value;
                              setProfileData((prevState) => {
                                return Object.assign({}, prevState, {
                                  name: val,
                                });
                              });
                            }}
                            placeholder="Full Name"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="">Category</label>
                          <select
                            className="form-control form-control-sm"
                            id="exampleFormControlSelect1"
                            onChange={(item) => {
                              if (item.target.value) {
                                let val = item.target.value;
                                let selected = category?.data.find(
                                  (value) => value.name == val
                                );
                                let selectedId = selected.id;
                                setProfileData((prevState) => {
                                  return Object.assign({}, prevState, {
                                    category: selectedId,
                                  });
                                });
                              }
                            }}
                          >
                            <option selected disabled>
                              Select
                            </option>
                            {category?.data.map((each, index) => (
                              <option
                                key={index}
                                selected={each.id === profileData?.category}
                              >
                                {each.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="">Ranges</label>

                          <select
                            className="form-control form-control-sm"
                            id="exampleFormControlSelect1"
                            onChange={(items) => {
                              if (items.target.value) {
                                let val = items.target.value;
                                let selected = ranges?.data.find(
                                  (value) => value.name == val
                                );
                                let selectedId = selected.id;
                                setProfileData((prevState) => {
                                  return Object.assign({}, prevState, {
                                    range: selectedId,
                                  });
                                });
                              }
                            }}
                          >
                            <option selected disabled>
                              Select
                            </option>
                            {ranges?.data.map((each, index) => (
                              <option
                                key={index}
                                selected={each.id === profileData?.range}
                              >
                                {each.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        {profileData.category == 1 ||
                        profileData.category == 2 ||
                        profileData.category == 3 ? (
                          <div className="form-group">
                            <label htmlFor="">VP Category</label>

                            <select
                              className="form-control form-control-sm"
                              id="exampleFormControlSelect1"
                              onChange={(items) => {
                                if (items.target.value) {
                                  let val = items.target.value;
                                  let selected = vpcategory?.data.find(
                                    (value) => value.name == val
                                  );
                                  let selectedId = selected.id;
                                  setProfileData((prevState) => {
                                    return Object.assign({}, prevState, {
                                      vp_category: selectedId,
                                    });
                                  });
                                }
                              }}
                            >
                              <option selected disabled>
                                select
                              </option>
                              {vpcategory?.data.map((each, index) => (
                                <option
                                  key={index}
                                  selected={
                                    each.id === profileData?.vp_category
                                  }
                                >
                                  {each.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : null}
                        {profileData.category == 5 ? (
                          <div className="form-group">
                            <label htmlFor="">Bank Category</label>
                            <select
                              className="form-control form-control-sm"
                              id="exampleFormControlSelect1"
                              onChange={(items) => {
                                if (items.target.value) {
                                  let val = items.target.value;
                                  let selected = bank_category?.data.find(
                                    (value) => value.name == val
                                  );
                                  let selectedId = selected.id;
                                  setProfileData((prevState) => {
                                    return Object.assign({}, prevState, {
                                      bank_category: selectedId,
                                    });
                                  });
                                }
                              }}
                            >
                              <option selected disabled>
                                select
                              </option>
                              {bank_category?.data.map((each, index) => (
                                <option
                                  selected={
                                    each.id === profileData?.bank_category
                                  }
                                  key={index}
                                >
                                  {each.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="">Jurisdiction</label>
                          <select
                            className="form-control form-control-sm"
                            id="exampleFormControlSelect1"
                            onChange={(items) => {
                              if (items.target.value) {
                                let val = Number(items.target.value);
                                console.log("val", val);

                                setProfileData((prevState) => {
                                  return Object.assign({}, prevState, {
                                    jurisdiction: val,
                                  });
                                });
                              }
                            }}
                          >
                            <option selected disabled>
                              Select
                            </option>
                            {jurisdictionData &&
                              jurisdictionData?.map((each, index) => (
                                <option
                                  selected={
                                    each?.jurisdiction_id?.id ===
                                    profileData?.jurisdiction
                                  }
                                  key={index}
                                  value={each?.jurisdiction_id?.id}
                                >
                                  {each?.jurisdiction_id?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Address
                          </label>
                          <input
                            type="text"
                            required
                            className="form-control form-control-sm"
                            value={profileData.address || undefined}
                            onChange={(e) => {
                              const val = e.target.value;
                              setProfileData((prevState) => {
                                return Object.assign({}, prevState, {
                                  address: val,
                                });
                              });
                            }}
                            placeholder="Enter Address"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Pincode
                          </label>
                          <input
                            required
                            type="number"
                            min={0}
                            className="form-control form-control-sm"
                            value={profileData.pincode || undefined}
                            onChange={(e) => {
                              let val = e.target.value;
                              _fillByPincode(val);
                            }}
                            placeholder="Enter Pincode"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            State
                          </label>
                          <input
                            type="text"
                            required
                            className="form-control form-control-sm"
                            value={profileData.state || undefined}
                            disabled={true}
                            placeholder="Select State"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">City</label>
                          <input
                            type="text"
                            required
                            className="form-control form-control-sm"
                            value={profileData.city || undefined}
                            disabled={true}
                            placeholder="Enter City"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            District
                          </label>
                          <input
                            type="text"
                            required
                            className="form-control form-control-sm"
                            value={profileData.district || undefined}
                            disabled={true}
                            placeholder="Enter District"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Latitude
                          </label>
                          <input
                            type="number"
                            min={0}
                            required
                            className="form-control form-control-sm"
                            value={profileData.latitude || undefined}
                            onChange={(e) => {
                              const val = e.target.value;
                              setProfileData((prevState) => {
                                return Object.assign({}, prevState, {
                                  latitude: val,
                                });
                              });
                            }}
                            placeholder="Enter Latitude"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Longitude
                          </label>
                          <input
                            type="number"
                            min={0}
                            required
                            className="form-control form-control-sm"
                            value={profileData.longitude || undefined}
                            onChange={(e) => {
                              const val = e.target.value;
                              setProfileData((prevState) => {
                                return Object.assign({}, prevState, {
                                  longitude: val,
                                });
                              });
                            }}
                            placeholder="Enter Longitude"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <p className="font-weight-bold"> Select Image </p>
                    {/* <EFileUpload
                                                onUpload={(url, id) => {
                                                    console.log("id", id)
                                                    setProfileData((prevState) => {
                                                        return Object.assign({}, prevState, {
                                                            profile_image: url,
                                                        });
                                                    })
                                                }
                                                }
                                            /> */}

                    <input
                      accept="image/*"
                      type="file"
                      onChange={async (e: any) => {
                        const file = new FormData();
                        let fileObject = e?.currentTarget?.files?.item(0);
                        if (fileObject && fileObject.size < 2000000) {
                          file.append(
                            "myFile",
                            e?.currentTarget?.files?.item(0)
                          );
                          const token = JSON.parse(
                            localStorage.getItem("persistantState") || ""
                          )?.user?.token;
                          /*  do not change **tokenInput** variable if then change node backend is also required*/
                          const tokenInput = "qwertyytrewq";
                          const res = await post(
                            `?${tokenInput}=${token}`,
                            file
                          );
                          if (res?.error) {
                            alert(
                              "Unauthorized user request login again to submit file"
                            );
                          } else {
                            setProfileData((prevState) => {
                              return Object.assign({}, prevState, {
                                profile_image: res?.data?._id,
                              });
                            });

                            fetchFile(res?.data?._id, "imgProfile");
                          }
                        } else {
                          alert("Please select file less than 2MB");
                        }
                      }}
                    />

                    <div
                      style={{
                        border: "1px lightgrey dashed",
                        height: "350px",
                        width: "100%",
                      }}
                      className="rounded bg-light"
                    >
                      {profileData?.profile_image !== "" ? (
                        //  src={API_URL + profileData?.profile_image}
                        <img
                          src={showImage}
                          style={{
                            maxWidth: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            margin: "0 auto",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      ) : (
                        <div className="d-flex h-100 justify-content-center align-items-center">
                          <PersonFill className="display-2 text-muted" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="spacer spacer--sm"></div>

                <div className="">
                  <div className="row">
                    <div className="col-lg-6">
                      <h2 className="pl-1">Upload Documents</h2>
                    </div>
                    <div className="col-lg-6">
                      <h2 className="pl-1">Satellite Image</h2>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      {profileData.profile_documents.length < 5 ? (
                        <div className="">
                          <div className="pb-1">
                            <input
                              accept="application/pdf"
                              type="file"
                              ref={documentUploadRef}
                              onChange={async (e: any) => {
                                const file = new FormData();
                                let fileObject = e?.currentTarget?.files?.item(
                                  0
                                );
                                if (fileObject && fileObject.size < 2000000) {
                                  setFileToUpload(
                                    e?.currentTarget?.files?.item(0)
                                  );
                                  // file.append(
                                  //   "myFile",
                                  //   e?.currentTarget?.files?.item(0)
                                  // );
                                  // const token = JSON.parse(
                                  //   localStorage.getItem("persistantState") ||
                                  //     ""
                                  // )?.user?.token;
                                  /*  do not change **tokenInput** variable if then change node backend is also required*/
                                  // const tokenInput = "qwertyytrewq";
                                  // const res = await post(
                                  //   `?${tokenInput}=${token}`,
                                  //   file
                                  // );
                                  // if (res.error) {
                                  //   alert(
                                  //     "Unauthorized user request login again to submit file"
                                  //   );
                                  // } else {
                                  //   setDocumentId(res?.data?._id);
                                  // }
                                } else {
                                  alert("Please select file less than 2MB");
                                }
                              }}
                            />
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div></div>
                              <input
                                required
                                type="text"
                                value={docName}
                                placeholder="Enter Document Name "
                                className="form-control form-control-sm"
                                onChange={(e) => {
                                  let val = e.target.value;
                                  formData.set("name", val);
                                  setDocName(val);
                                }}
                              />
                              <small className="form-text text-muted">
                                You can only upload 5 documents
                              </small>
                            </div>
                            <div className="col-lg-6">
                              <button
                                disabled={docName == "" ? true : false}
                                style={{ fontSize: "1.1rem" }}
                                onClick={async () => {
                                  formData.append("myFile", fileToUpload);
                                  formData.append("docname", docName);
                                  formData.append("vpname", profileData?.name);
                                  const token = JSON.parse(
                                    localStorage.getItem("persistantState") ||
                                      ""
                                  )?.user?.token;
                                  const tokenInput = "qwertyytrewq";
                                  const res = await post(
                                    `?${tokenInput}=${token}`,
                                    formData
                                  );
                                  if (res?.error) {
                                    alert(
                                      "Unauthorized user request login again to submit file"
                                    );
                                  } else {
                                    setDocumentId(res?.data?._id);
                                    let temp = [
                                      ...profileData.profile_documents,
                                    ];
                                    temp.push({
                                      url: document,
                                      name: `${docName}`,
                                      id: res?.data?._id,
                                    });
                                    setProfileData((prevState) => {
                                      return Object.assign({}, prevState, {
                                        profile_documents: temp,
                                      });
                                    });
                                    setDocName("");
                                    setDocument("");
                                    setDocumentId(-1);
                                    documentUploadRef.current!.value = "";
                                  }
                                }}
                                className="btn btn-primary btn-sm"
                              >
                                Add +
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <h4>You can only select 5 documents</h4>
                        </div>
                      )}
                      <div className="pl-0 mt-3">
                        {/* <p className="small mt-0 text-underline">{profileData.document && (<u><a href={API_URL + profileData.document} target="_blank">View selected document</a></u>)}</p> */}
                        <h3 className="mt-2 ml-0 pl-0 ">Documents</h3>
                        <ul className="bg-light p-2 rounded">
                          {profileData?.profile_documents?.map((e) => (
                            <li className="nav bg-white d-flex justify-content-between align-items-center  col-lg-12 rounded px-2 my-2">
                              <div>
                                {/* <a href={API_URL + e?.url} className="text-secondary" target="_blank">{e?.name}</a> */}
                                <div
                                  className="text-secondary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    fetchFile(e?.id, "docs");
                                  }}
                                >
                                  {e?.name}
                                </div>
                              </div>
                              <div>
                                <button
                                  className="btn-sm btn pt-2"
                                  onClick={async () => {
                                    // deletefile(e?.id)
                                    setDeltefileModal(true);
                                    setDeleteFileID(e?.id);
                                    setDeleteFileType("profile_documents");
                                  }}
                                >
                                  <Trash className="h4 text-danger" />
                                </button>
                              </div>
                            </li>
                          ))}
                          {profileData?.profile_documents.length <= 0 && (
                            <small className="text-muted mr-1">
                              No documents selected
                            </small>
                          )}
                        </ul>
                      </div>
                    </div>

                    {/* satellite Image */}
                    <div className="col-md-6">
                      {/* <EFileUpload
                                                    onUpload={(url, id) => {

                                                        setProfileData((prevState) => {
                                                            return Object.assign({}, prevState, {
                                                                satellite_image: url,
                                                            });
                                                        })
                                                    }
                                                    }
                                                /> */}

                      <input
                        accept="image/*"
                        type="file"
                        onChange={async (e: any) => {
                          const file = new FormData();
                          let fileObject = e?.currentTarget?.files?.item(0);
                          if (fileObject && fileObject.size < 2000000) {
                            file.append(
                              "myFile",
                              e?.currentTarget?.files?.item(0)
                            );
                            const token = JSON.parse(
                              localStorage.getItem("persistantState") || ""
                            )?.user?.token;
                            /*  do not change **tokenInput** variable if then change node backend is also required*/
                            const tokenInput = "qwertyytrewq";
                            const res = await post(
                              `?${tokenInput}=${token}`,
                              file
                            );
                            if (res?.error) {
                              alert(
                                "Unauthorized user request login again to submit file"
                              );
                            } else {
                              setProfileData((prevState) => {
                                return Object.assign({}, prevState, {
                                  satellite_image: res?.data?._id,
                                });
                              });

                              fetchFile(res?.data?._id, "imgSatellite");
                            }
                          } else {
                            alert("Please select file less than 2MB");
                          }
                        }}
                      />
                      <div
                        style={{
                          border: "1px lightgrey dashed",
                          height: "250px",
                          width: "100%",
                        }}
                        className="rounded bg-light"
                      >
                        {profileData?.satellite_image !== "" ? (
                          //  src={API_URL + profileData?.satellite_image}
                          <img
                            src={showSatelliteImage}
                            style={{
                              // objectFit: "contain",
                              maxWidth: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              margin: "0 auto",
                            }}
                            alt=""
                          />
                        ) : (
                          <div className="d-flex h-100 justify-content-center align-items-center">
                            <PersonFill className="display-2 text-muted" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row  pt-3">
                  <div className="col-lg-6">
                    <h2 className="">UPLOAD SOP'S</h2>
                    {profileData.sops.length < 5 ? (
                      <div className="">
                        <div className="pb-1">
                          {/* <EFileUpload type="application/pdf" refresh={refresh} onUpload={(url, id) => { setSopDoc(url); setSopID(id) }} /> */}
                          {/* <input accept="application/pdf" type="file" ref={sopUploadRef} onChange={async (e: any) => {
                                                                const data = new FormData();
                                                                let file = e?.currentTarget?.files?.item(0);
                                                                if (file && file.size < 2000000) {
                                                                    data.append("file", e?.currentTarget?.files?.item(0))
                                                                    const res = await post(data);
                                                                    setSopDoc(`_/assets/${res?.data?.private_hash}`);
                                                                    setSopID(res?.data?.id)
                                                                } else {
                                                                    alert("Please select file less than 2MB")
                                                                }

                                                            }} /> */}

                          <input
                            accept="application/pdf"
                            type="file"
                            ref={sopUploadRef}
                            onChange={async (e: any) => {
                              const file = new FormData();
                              let fileObject = e?.currentTarget?.files?.item(0);
                              if (fileObject && fileObject.size < 2000000) {
                                setSopFileToUpload(
                                  e?.currentTarget?.files?.item(0)
                                );
                                // file.append(
                                //   "myFile",
                                //   e?.currentTarget?.files?.item(0)
                                // );
                                // const token = JSON.parse(
                                //   localStorage.getItem("persistantState") || ""
                                // )?.user?.token;
                                // /*  do not change **tokenInput** variable */
                                // const tokenInput = "qwertyytrewq";
                                // const res = await post(
                                //   `?${tokenInput}=${token}`,
                                //   file
                                // );
                                // if (res.error) {
                                //   alert(
                                //     "Unauthorized user request login again to submit file"
                                //   );
                                // }
                                // setSopID(res?.data?._id);
                              } else {
                                alert("Please select file less than 2MB");
                              }
                            }}
                          />
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div></div>
                            <input
                              required
                              type="text"
                              value={sopName}
                              placeholder="Enter Document Name "
                              className="form-control form-control-sm"
                              onChange={(e) => {
                                let val = e.target.value;
                                setSopName(val);
                              }}
                            />
                            <small className="form-text text-muted">
                              You can only upload 5 SOP'S
                            </small>
                          </div>
                          <div className="col-lg-6">
                            <button
                              disabled={sopName == "" ? true : false}
                              style={{
                                fontSize: "1.1rem",
                              }}
                              onClick={async () => {
                                formDataSop.append("myFile", sopFileToUpload);
                                formDataSop.append("docname", sopName);
                                formDataSop.append("vpname", profileData?.name);
                                const token = JSON.parse(
                                  localStorage.getItem("persistantState") || ""
                                )?.user?.token;
                                const tokenInput = "qwertyytrewq";
                                const res = await post(
                                  `?${tokenInput}=${token}`,
                                  formDataSop
                                );
                                if (res?.error) {
                                  alert(
                                    "Unauthorized user request login again to submit file"
                                  );
                                } else {
                                  setSopID(res?.data?._id);
                                  let temp = [...profileData.sops];
                                  temp.push({
                                    url: sopDoc,
                                    name: sopName,
                                    id: res?.data?._id,
                                  });
                                  setProfileData((prevState) => {
                                    return Object.assign({}, prevState, {
                                      sops: temp,
                                    });
                                  });
                                  setSopName("");
                                  setSopDoc("");
                                  setSopID(-1);
                                  // setRefresh(true)
                                  sopUploadRef.current!.value = "";
                                }
                              }}
                              className="btn btn-primary btn-sm"
                            >
                              Add +
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <h4>
                          You can only select 5 SOP'S. please delete one to
                          select new
                        </h4>
                      </div>
                    )}
                  </div>
                  <div className="pl-0 mt-3 col-lg-6">
                    <h2 className="mt-2 ml-0 pl-0 ">SOPs</h2>
                    <ul className="bg-light p-2 rounded">
                      {profileData?.sops?.map((e) => (
                        <li className="nav bg-white d-flex justify-content-between align-items-center  col-lg-12 rounded px-2 my-2">
                          <div>
                            {/* <a href={API_URL + e?.url}
                                                                    className="text-secondary" target="_blank">{e?.name}
                                                                </a> */}
                            <div
                              className="text-secondary"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                fetchFile(e?.id, "docs");
                              }}
                            >
                              {e?.name}
                            </div>
                          </div>
                          <div>
                            <button
                              className="btn-sm btn pt-2"
                              onClick={async () => {
                                setDeltefileModal(true);
                                setDeleteFileID(e?.id);
                                setDeleteFileType("sops");
                              }}
                            >
                              <Trash className="h4 text-danger" />
                            </button>
                          </div>
                        </li>
                      ))}
                      {profileData?.sops.length <= 0 && (
                        <small className="text-muted mr-1">
                          No SOP'S selected
                        </small>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-12 pl-0">
                  <div className="d-flex pl-0 align-items-center">
                    <h2 className="">Paid Security</h2>
                    <p className="pl-2 " style={{ paddingTop: "12px" }}>
                      <input
                        type="checkbox"
                        checked={profileData?.paid}
                        onClick={() => {
                          setProfileData((prevState) => {
                            return Object.assign({}, prevState, {
                              paid: !profileData.paid,
                            });
                          });
                        }}
                      />
                    </p>
                  </div>
                  <div>
                    <a
                      className="btn btn-outline-dark mr-2"
                      href={PATHS.profile}
                    >
                      Cancel
                    </a>
                    <button
                      disabled={
                        profileData.name == "" ||
                        profileData.address == "" ||
                        profileData.category == null ||
                        profileData.pincode == null ||
                        profileData.state == "" ||
                        profileData.city == "" ||
                        profileData.district == "" ||
                        profileData.latitude == "" ||
                        profileData.longitude == ""
                          ? true
                          : false
                      }
                      onClick={async () => {
                        setEditModel(true);
                      }}
                      className="btn btn-primary mr-4"
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {editModel && (
            <>
              <div className="modal-backdrop"></div>
              <div
                className="modal"
                id="modelId"
                tabIndex={-1}
                role="dialog"
                style={{ display: "block" }}
                aria-labelledby="modelTitleId"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-scrollable modal-sm"
                  role="document"
                >
                  <div className="modal-content">
                    {/* <div className="modal-header">
        <h5 className="modal-title">Confirm Decline Request !!</h5>
        <button
            type="button"
            className="close"
            onClick={() => setEditModel(false)}
            data-dismiss="modal"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div> */}
                    <div className="modal-body">
                      Are you sure you want to update profile?
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() => setEditModel(false)}
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={async () => {
                          await insertProfile(
                            `/${data?.data?.id}`,
                            profileData
                          );
                          if (response.ok) {
                            window.location.href = PATHS.profile;
                          } else {
                            alert("Something Went Wrong");
                          }
                        }}
                        data-dismiss="modal"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {deltefileModal && (
            <>
              <div className="modal-backdrop"></div>
              <div
                className="modal"
                id="modelId"
                tabIndex={-1}
                role="dialog"
                style={{ display: "block" }}
                aria-labelledby="modelTitleId"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-scrollable modal-sm"
                  role="document"
                >
                  <div className="modal-content">
                    {/* <div className="modal-header">
<h5 className="modal-title">Create profile request</h5>
<button
type="button"
className="close"
onClick={() => setDeclineModel(false)}
data-dismiss="modal"
aria-label="Close"
>
<span aria-hidden="true">&times;</span>
</button>
</div> */}
                    <div className="modal-body">
                      Are you sure you want to delete file ?
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() => setDeltefileModal(false)}
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={async () => {
                          deletefile(deleteFileID, deleteFileType);
                          setDeleteFileID("");
                          setDeltefileModal(false);
                        }}
                        data-dismiss="modal"
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </React.Fragment>
      )}
    </>
  );
}

export default EditProfile;
