import { spawn } from "child_process";
import { createSecretKey } from "crypto";
import React, { useEffect, useState, useRef } from "react";
import {
  FileArrowDown,
  FileEarmarkTextFill,
  Pencil,
  Printer,
} from "react-bootstrap-icons";
import { useFetch } from "use-http";
import DistrictVp from "./districtVp";
import { CUSTOM_URL } from "../..";
import Paid from "./paid";
import TotalForces from "./totalForces";
import TotalVp from "./totalVp";
import VpCategories from "./vpCategories";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { LoadingScreen } from "../../common/components/LoadingScreen";
import Goback from "../../common/components/Goback";
function ForcesDeployment() {
  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);

  /* state for forces */
  const [forceState, setForceState] = useState<any>([]);
  const [rankState, setRankState] = useState<any>([]);
  const [otherforceState, setOtherforceState] = useState<any>([]);
  const [battalionState, setbattalionState] = useState<any>([]);
  /* state for forces */

  const { data: forceDeployment } = useFetch(
    `${CUSTOM_URL}/directus/custom/forceDeployment`,
    {},
    []
  );

  const [forceDeploymentState, setForceDeploymentState] = useState<any>([]);

  useEffect(() => {
    setRankState(rank?.data);
  }, [rank]);

  useEffect(() => {
    setbattalionState(battalion?.data);
  }, [battalion]);

  useEffect(() => {
    setOtherforceState(otherforce?.data);
  }, [otherforce]);

  useEffect(() => {
    setForceState(force?.data);
  }, [force]);

  useEffect(() => {
    const arr: any = [];

    // removing slashes from string
    forceDeployment?.data?.map((force) => {
      let str = force?.force_deployemnt.replace(/\\|\//g, "");
      arr.push(JSON.parse(str));
    });
    // console.log(arr)

    // a have change name of force from number to string
    let a = arr?.map((e: any) =>
      e?.map((ee: any) => {
        ee.force = forceState && forceState[Number(ee?.force - 1)]?.name;
        ee.battalion =
          battalionState && battalionState[Number(ee?.battalion - 1)]?.name;
        ee.rank = rankState && rankState[Number(ee?.rank - 1)]?.name;
        return ee;
      })
    );
    // console.log("a", a)
    setForceDeploymentState(a);
  }, [forceDeployment, forceState, battalionState]);

  useEffect(() => {
    let arr: any = [];
    forceDeploymentState.map((each) => {
      each.map((e) => {
        forceState?.map((f) => {
          let count = 0;
          if (e.force == f.name) {
            count += Number(e.count);
            arr.push({
              count: count,
              force: f?.name,
              rank: e.rank,
            });
          }
        });
      });
    });
    setDataToshow(arr);
  }, [forceDeploymentState]);

  const [datatoShow, setDataToshow] = useState<any>([]);

  //  group by functions
  const groupByKey = (list, key) =>
    list?.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  const [data2shaow, setData2shaow] = useState<any>([]);
  const [key, setKey] = useState<any>([]);
  const [byRank, setbyRank] = useState<any>([]);

  //
  useEffect(() => {
    let arr: any = [];
    forceState?.map((e) => {
      let count = 0;
      let rank = "";
      datatoShow?.map((f) => {
        if (f.force == e.name) {
          // count += Number(f.count);
          rank = f.rank;
          arr.push({
            count: f.count,
            force: e?.name,
            rank: rank,
          });
        }
      });
    });

    let a = groupByKey(arr, "force");
    let key = Object.keys(groupByKey(arr, "force"));
    setKey(key);

    // let arrr for rank wise sort

    let newarr: any = [];
    key?.map((e) => {
      newarr.push(groupByKey(a[e], "rank"));
    });
    setbyRank(newarr);
    setData2shaow(groupByKey(arr, "force"));
  }, [datatoShow]);

  console.log("datatoShow", byRank);

  const [lastDataFininsh, setlastDataFininsh] = useState<any>([]);
  // sum by keys

  // final arrr for mapping
  useEffect(() => {
    let finalArry: any = [];
    byRank?.map((r) => {
      let byRankNew: any = [];
      let key = Object.keys(r);
      let fRank = "";
      key?.map((k) => {
        let count = 0;
        let force = "";
        let rank = "";
        r[k].map((each) => {
          count += each.count;
          force = each.force;
          rank = k;
        });
        fRank = force;
        byRankNew.push({ count: count, force: force, rank: rank });
      });
      finalArry.push({ force: fRank, data: byRankNew });
      // console.log("byRankNew", byRankNew)
    });
    // console.log("finalarry", finalArry);

    setlastDataFininsh(finalArry);
  }, [byRank]);

  console.log("finalarry lastDataFininsh", lastDataFininsh); //count

  const [selectedReport, setSelectedReport] = useState(1);

  const [totalCountRank, settotalCountRank] = useState<any>([]);
  const [totalsum, setTotalSum] = useState<number>(0);

  // data sorted rank wise
  useEffect(() => {
    const forces = groupByKey(
      lastDataFininsh.map((data) => data.data).flat(),
      "rank"
    );
    // console.log("forces", forces);

    const totalCountByRank = Object.keys(forces).map((k) => {
      return {
        rank: k,
        count: forces[k].reduce((acc, { count }) => acc + Number(count), 0),
      };
    });

    let totalsum = totalCountByRank.reduce(
      (acc, { count }) => acc + Number(count),
      0
    );
    setTotalSum(totalsum);
    // totalCountByRank.push({ rank: "Total count", count: totalsum });
    settotalCountRank(totalCountByRank);
    console.log(totalCountRank[totalCountRank.length - 1]);
    // console.log("totalCountByRank", totalCountByRank)
  }, [lastDataFininsh]);

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  if (forceDeployment == undefined) {
    return <LoadingScreen />;
  } else {
    return (
      <div>
        {/* <Goback /> */}
        <div className="mt-5 ml-4 mr-4" ref={componentRef}>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mb-5 mt-5 text-uppercase">ALL FORCE BREAKUP</h3>
            {/* <Printer className="mb-5 mt-5" onClick={handlePrint} /> */}
            <ReactToPrint
              bodyClass={`print-styles`}
              removeAfterPrint={true}
              pageStyle="@page { size: landscape; } "
              trigger={() => (
                <button
                  className="btn float-right my-3 btn-sm btn-primary"
                  // onClick={handlePrint}
                >
                  <Printer className="mr-2" />
                  PRINT REPORT
                </button>
              )}
              content={() => componentRef.current!}
            />
          </div>
          <table className="table bg-white table-bordered table-sm text-left text-uppercase">
            <thead>
              <th style={{ width: "10%" }}>Sr.No</th>
              <th style={{ width: "20%" }}>FORCE</th>
              <table className="w-100">
                <th style={{ width: "50%" }}>RANK</th>
                <th style={{ width: "50%" }}>Count</th>
              </table>
              <th style={{ width: "10%" }}>TOTAL</th>
            </thead>
            {
              <>
                {lastDataFininsh.map((key, i) => (
                  <tr className="" key={i}>
                    <th>{i + 1}</th>
                    <td>{key.force}</td>
                    <table className="w-100">
                      {key.data.map((d, i) => (
                        <tr className="w-100" key={i}>
                          <td style={{ width: "50%" }}>{d.rank}</td>
                          <td style={{ width: "50%" }}>{d.count}</td>
                        </tr>
                      ))}
                    </table>
                    <td>
                      {key.data.reduce(
                        (acc, { count }) => (acc += Number(count)),
                        0
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th>Total</th>
                  <td></td>
                  <td></td>
                  <th>{totalsum}</th>
                </tr>
              </>
            }
          </table>
          <h3 className="mb-5 mt-5 text-uppercase">Rank Breakup</h3>
          <table className="table bg-white table-bordered table-sm text-left text-uppercase">
            <thead>
              <th style={{ width: "10%" }}>Sr.No</th>
              <th style={{ width: "10%" }}>Rank</th>
              <th style={{ width: "10%" }}>Count</th>
            </thead>
            {totalCountRank.map((data, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{data.rank}</td>
                <td>{data.count}</td>
              </tr>
            ))}
            <tr style={{ fontWeight: "bold" }}>
              <td>Total</td>
              <td></td>
              <td>{totalsum}</td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default ForcesDeployment;
