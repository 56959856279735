import React, { useState, useEffect } from "react";
import { FileEarmarkArrowDown } from "react-bootstrap-icons";
import Empty from "./../../common/components/Empty";
import { FILE_URL } from "../../constant";
interface Props {}

const Files = (props: Props) => {
  const [data, setData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const token = localStorage.getItem("token");
  const tokenInput = "qwertyytrewq";

  const getFile = (id) => {
    fetch(`${FILE_URL}getFile/${id}?${tokenInput}=${token}`).then((res) => {
      window.open(res?.url, "_blank");
    });
  };

  useEffect(() => {
    fetch(`${FILE_URL}getAllFiles?${tokenInput}=${token}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setData(data);
        setFilteredData(data);
      })
      .catch((error) => console.log("error", error));
  }, [token]);
  useEffect(() => {
    if (search.length > 0) {
      let filtered = data?.filter((e) => {
        if (
          e?.vp_name.toLowerCase().includes(search.toLowerCase()) ||
          e?.doc_name.toLowerCase().includes(search.toLowerCase())
        ) {
          return e;
        }
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [search, data]);
  console.log(data);
  return (
    <div className="container-fluid mt-4">
      <div className="card">
        <div className="card-header">
          <div className="card-title d-flex justify-content-between align-items-center w-100">
            <h1>All Files</h1>
            <div className="form-group mr-4 my-auto">
              <input
                type="search"
                value={search}
                onChange={(e) => {
                  const { value } = e.target;
                  setSearch(value);
                }}
                className="form-control form-control-sm mr-4 py-0 px-4 rounded-pill "
                placeholder="Search Files..."
              />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex flex-wrap justify-content-center">
            {filteredData?.length > 0 ? (
              filteredData?.map((e, index) => (
                <div
                  className="card m-3 p-0 border border-primary relative"
                  style={{
                    width: "22%",
                    height: "150px",
                  }}
                >
                  <span
                    className=" px-2  text-white"
                    style={{
                      width: "fit-content",
                      marginTop: "-1.1px",
                      borderTopLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                      background:
                        "linear-gradient(125deg, rgba(26,26,100,1) 25%, rgba(207,35,46,1) 70%)",
                    }}
                  >
                    {index + 1}
                  </span>
                  <div className="card-body text-center py-2">
                    <h3>{e?.vp_name}</h3>
                    <FileEarmarkArrowDown />
                    <span className="ml-2">{e?.doc_name}</span>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => getFile(e?._id)}
                  >
                    view
                  </button>
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center  w-100">
                <Empty title="No Files found" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Files;
