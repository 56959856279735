import React from "react";
import { useParams } from "react-router-dom";
import EFileUpload from "../../../common/components/EFileUpload/EFileUpload";
import { useFetch } from "use-http";
import { NON_ADMIN } from "../../../reducers/routes";
import { API_URL } from "../../../index";
import {
  FileEarmarkArrowDown,
  PersonFill,
  ArrowRight,
  Trash,
  PencilSquare,
  X,
} from "react-bootstrap-icons";
import moment from "moment";
import { LoadingScreen } from "../../../common/components/LoadingScreen";
import Select from "react-select";
import { useState, useRef } from "react";
import { FILE_URL } from "../../../constant";

export default function Deploy() {
  const resetPer = useRef<any>();
  const resetrank = useRef<any>();
  const resetbat = useRef<any>();
  const resetOtherforce = useRef<any>();
  const ResetOtherforceCount = useRef<any>();
  const resetOtherRank = useRef<any>();
  const { id } = useParams<{ id: string }>();

  const { get, data, loading: loadingProfile } = useFetch(
    `/items/assignments/${id}?fields=*.*.*&single=1`,
    {},
    []
  );
  // const { post } = useFetch("/files?fields=private_hash&t=" + new Date().valueOf(), { cache: "no-cache", });
  const { post } = useFetch(`${FILE_URL}fileupload/${new Date().valueOf()}`, {
    cache: "no-cache",
  });

  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);

  const { patch } = useFetch(`/items/assignments`);
  const [declineModel, setDeclineModel] = React.useState(false);
  const [docName, setDocName] = React.useState("");
  const [docFileToUpload, setDocFileToUpload] = useState<any>();
  const formDataDoc = new FormData();
  const [documentId, setDocumentId] = React.useState<number>();
  const [unit_cctv_document, setUnit_cctv_document] = React.useState<any>([]);

  // const { delete: removedoc, response: deleteres } = useFetch(`/files`, {});
  const { post: removedoc, response: deleteres } = useFetch(
    `${FILE_URL}filedelete`,
    {}
  );

  const deletefile = async (id) => {
    const token = localStorage.getItem("token");
    /*  do not change **tokenInput** variable */
    const tokenInput = "qwertyytrewq";
    await removedoc(`${id}?${tokenInput}=${token}`);
    if (deleteres.ok) {
      let newarr = unit_cctv_document?.filter((e) => e.id !== id);
      setUnit_cctv_document(newarr);
      alert("File deleted successfully");
      setDeleteFileID("");
      setDeleteFileType("");
      setDeltefileModal(false);
    } else {
      alert("Something Went Wrong");
      setDeltefileModal(false);
    }
  };
  const deletefilesop = async (id) => {
    const token = localStorage.getItem("token");
    /*  do not change **tokenInput** variable */
    const tokenInput = "qwertyytrewq";
    await removedoc(`${id}?${tokenInput}=${token}`);
    if (deleteres.ok) {
      let newarr = profileData?.sops?.filter((e) => e?.id !== id);
      setProfileData((prev) => ({ ...prev, sops: newarr }));
      alert("File deleted successfully");
      setDeleteFileID("");
      setDeleteFileType("");
    } else {
      alert("Something Went Wrong");
    }
  };

  const [otherDeployed, setOtherDeployed] = React.useState({
    force: "",
    rank: "",
    count: 0,
    private_security_name: "",
    private_security_address: "",
    private_security_contact: "",
  });
  const [unitDeployed, setUnitDeployed] = React.useState({
    force: "",
    battalion: "",
    rank: "",
    count: 0,
  });
  const [unitDeployedArray, setUnitDeployedArray] = React.useState<any>([]);

  const [dogsquadArray, setDogsquadArray] = React.useState<any>([]);

  const [otherDeployedArray, setOtherDeployedArray] = React.useState<any>([]);

  const [dummyAssignment, setDummyAssignment] = React.useState([
    {
      rank: "",
      count: "",
      force: "",
      desployed_by_district: 0,
      desployed_by_armed: 0,
      allocated: 0,
      private_security: false,
      name_private_security: "",
      address_private_security: "",
      assigned_private_security: 0,
      contact_private_security: "",
      comment_for_allocate_more_than_sanctioned: "",
    },
  ]);

  const [securityAssignment, setSecurityAssignment] = React.useState([
    {
      equipment_name: "",
      name: "",
      eqipment_count: 0,
      equipment_deployed: 0,
      deployed_more_comment: "",
    },
  ]);
  const [teams, setTeams] = React.useState([
    {
      team_name: "",
      name: "",
      team_count: 0,
      team_deployed: 0,
      team_deployed_more_comment: "",
    },
  ]);

  const [supervisorOfficer, setSupervisorOfficer] = React.useState<any>({
    name: "",
    phone: "",
    rank: "",
    order_no: "",
  });
  const [guardIncharge, setGuardIncharge] = React.useState<any>({
    name: "",
    phone: "",
    rank: "",
    order_no: "",
  });

  const [assigned_date, setAssigned_date] = React.useState("");
  const [review_date, setReview_date] = React.useState("");

  const [cctv, setCctv] = React.useState(0);
  const [document, setDocument] = React.useState("");
  const [hasPrivateSecurity, setPrivateSecurity] = React.useState<boolean[]>(
    []
  );
  // comment when cctv deployement count exceed
  const [
    deployedExceedComment,
    setDeployedExceedComment,
  ] = React.useState<string>("");
  const [
    force_deployment_comment,
    setForce_deployment_comment,
  ] = React.useState<string>("");

  let totalSenctioned = 0;
  const [totalDeplyed, setTotalDeplyed] = React.useState<number>(0);
  const [editIndex, setEditIndex] = React.useState(-1);

  const [deleteForceModel, setDeleteForceModel] = React.useState(false);
  const [deleteForceIndex, setDeleteForceIndex] = React.useState(-1);
  const [deleteOtherForceModel, setDeleteOtherForceModel] = React.useState(
    false
  );

  const [editProfileModel, setEditProfileModel] = React.useState(false);
  const [deleteOtherForceIndex, setDeleteOtherForceIndex] = React.useState(-1);

  const [sopDoc, setSopDoc] = React.useState("");
  const [sopID, setSopID] = React.useState<number>();
  const [sopName, setSopName] = React.useState("");
  const [sopFileToUpload, setSopFileToUpload] = useState<any>();
  const formDataSop = new FormData();
  const [sopArray, setSopArray] = React.useState<any>([]);

  const [deltefileModal, setDeltefileModal] = React.useState(false);
  const [deleteFileID, setDeleteFileID] = React.useState("");
  const [deleteFileType, setDeleteFileType] = React.useState("");

  console.log("data?.data?", data?.data);

  React.useEffect(() => {
    let tempArr = hasPrivateSecurity;
    data?.data?.assignment_by_rank?.map((i: any) => {
      tempArr.push(i?.private_security);
      setPrivateSecurity(tempArr);
    });
    setDummyAssignment(data?.data?.assignment_by_rank);
    setSecurityAssignment(
      data?.data?.security_equipment == null
        ? []
        : data?.data?.security_equipment
    );
    setTeams(data?.data?.team_s == null ? [] : data?.data?.team_s);
  }, [data]);

  // changing property of states
  let changeObject = (property, value, index) => {
    let tempObjArr = [...dummyAssignment];
    tempObjArr[index][property] = value;
    // setDummyAssignment(tempObjArr);
    if (
      property == "desployed_by_district" ||
      property == "desployed_by_armed"
    ) {
      tempObjArr[index]["allocated"] =
        Number(tempObjArr[index].desployed_by_district) +
        Number(tempObjArr[index].desployed_by_armed);
      setDummyAssignment(tempObjArr);
    } else {
      setDummyAssignment(tempObjArr);
    }
  };

  // for changing of security Assignment equipment
  let changeSecurityAssignment = (property, value, index) => {
    let tempdata = [...securityAssignment];
    tempdata[index][property] = value;
    setSecurityAssignment(tempdata);
  };
  // for changing of teams Assignment equipment
  let changeTeamsAssignment = (property, value, index) => {
    let tempdata = [...teams];
    tempdata[index][property] = value;
    setTeams(tempdata);
  };

  // patch query for important information  profile
  const { patch: insertProfile, response, error } = useFetch(
    `/items/profile`,
    {}
  );
  // object for important information
  const [viewProfile, setViewProfile] = React.useState(false);

  const [profileData, setProfileData] = React.useState<any>({
    police_station: { name: "", address: "", phone: "", distance: "" },
    ssp: { name: "", address: "", phone: "", distance: "" },
    ndrf: { name: "", address: "", phone: "", distance: "" },
    airport: { name: "", address: "", phone: "", distance: "" },
    helipad: { name: "", address: "", phone: "", distance: "" },
    railway_station: { name: "", address: "", phone: "", distance: "" },
    airbase: { name: "", address: "", phone: "", distance: "" },
    District_Police_Control_Room: {
      name: "",
      address: "",
      phone: "",
      distance: "",
    },
    Fire_Alram: { name: "", address: "", phone: "", distance: "" },
    Magistrate_Office: { name: "", address: "", phone: "", distance: "" },
    Hospital: { name: "", address: "", phone: "", distance: "" },
    latitude: "",
    longitude: "",
    profile_image: "",
    satellite_image: "",
    sops: [],
  });

  // setting  data to local sates for pre filed when clicked on edit
  React.useEffect(() => {
    setUnitDeployedArray(data?.data?.force_deployemnt || []);
    setOtherDeployedArray(data?.data?.other_deployment || []);
    setUnit_cctv_document(data?.data?.unit_cctv_document || []);
    setForce_deployment_comment(data?.data?.force_deployment_comment || "");
    setTotalDeplyed(
      data?.data?.force_deployemnt?.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0) || 0
    );
    setDogsquadArray(data?.data?.dog_squad);
    setProfileData((prev) => ({
      ...prev,
      police_station: data?.data?.profile?.police_station,
      ssp: data?.data?.profile?.ssp,
      ndrf: data?.data?.profile?.ndrf,
      airport: data?.data?.profile?.airport,
      helipad: data?.data?.profile?.helipad,
      railway_station: data?.data?.profile?.railway_station,
      airbase: data?.data?.profile?.airbase,
      latitude: data?.data?.profile?.latitude,
      longitude: data?.data?.profile?.longitude,
      profile_image: data?.data?.profile?.profile_image,
      satellite_image: data?.data?.profile?.satellite_image,
      sops: data?.data?.profile?.sops || [],
    }));
    setSupervisorOfficer((prev) => ({
      ...prev,
      name: data?.data?.supervisor_officer?.name || "",
      phone: data?.data?.supervisor_officer?.phone || "",
      rank: data?.data?.supervisor_officer?.rank || "",
      order_no: data?.data?.supervisor_officer?.order_no || "",
    }));
    setGuardIncharge((prev) => ({
      ...prev,
      name: data?.data?.on_guard_incharge?.name || "",
      phone: data?.data?.on_guard_incharge?.phone || "",
      rank: data?.data?.on_guard_incharge?.rank || "",
      order_no: data?.data?.on_guard_incharge?.order_no || "",
    }));
    setCctv(data?.data?.cctv_deployed);
    // setDocument(data?.data?.document_on_deployment);
    setDummyAssignment(data?.data?.assignment_by_rank);
    setDeployedExceedComment(data?.data?.deployed_exceed_comment);
  }, [data]);

  React.useEffect(() => {
    let date = moment();
    let reviewDtae = moment();
    let sixm = reviewDtae.add(6, "M").format("YYYY-MM-DD");
    setAssigned_date(moment(date).format("YYYY-MM-DD"));
    setReview_date(sixm);
  }, []);

  // React.useEffect(() => {

  // }, [cctv])

  const [forceState, setForceState] = useState<any>();
  const [rankState, setRankState] = useState<any>();
  const [battalionState, setBattalionState] = useState<any>();

  React.useEffect(() => {
    setForceState(force?.data);
    setRankState(rank?.data);
  }, [force, rank]);

  React.useEffect(() => {
    let batArr = battalion?.data?.filter((e) => e.force == unitDeployed?.force);
    setBattalionState(batArr);
  }, [unitDeployed.force]);

  let reviewDtae = moment(); //today date

  const editUnitDeployed = (indexAt, value) => {
    let tempArr = unitDeployedArray;
    tempArr[indexAt] = value;
    setUnitDeployedArray(tempArr);
  };
  const unitdocumentUploadRef = React.useRef<HTMLInputElement>(null);
  const sopUploadRef = React.useRef<HTMLInputElement>(null);

  const fetchFile = (id) => {
    const token = JSON.parse(localStorage.getItem("persistantState") || "")
      ?.user?.token;
    /*  do not change **tokenInput** variable */
    const tokenInput = "qwertyytrewq";
    fetch(`${FILE_URL}getfile/${id}?${tokenInput}=${token}`)
      .then((data) => {
        data?.status == 200 && window.open(data?.url, "_blank");
      })
      .catch((error) => alert("error in displaying file"));
  };

  const CCTVJSX = () => {
    return (
      <>
        <div className="row pb-3">
          <div className="col-lg-6">
            <h2 className="py-2">DOCUMENTS</h2>
          </div>
        </div>
        <h4 className="text-uppercase">Uploads Documents</h4>
        <div className="row m-1 bg-light rounded p-3 pl-0">
          {/* <div className="col-lg-6 pl-0">
            <h4>Sanctioned:
              <strong> {data?.data.cctv ? data?.data.cctv : <span className="text-muted">Not Available</span>}</strong>
            </h4>
            <div className="">
              <div className="form-group">
                <label htmlFor="cctvnumber">Total CCTV Deployed</label>
                <input
                  type="number"
                  min={0}
                  value={cctv}
                  className="form-control form-control-sm "
                  onChange={(e: any) => {
                    setCctv(e.target.value)
                  }}
                  id="cctvnumber"
                />
              </div>
            </div>
            {
              cctv > data?.data.cctv && (
                <div>
                  <div className="form-group">
                    <label htmlFor="cctvnumber">Comment ( Required ) </label>
                    <textarea
                      value={deployedExceedComment}
                      placeholder="Enter reason"
                      className="form-control form-control-sm"
                      onChange={(e: any) => {
                        setDeployedExceedComment(e.target.value);
                      }}
                      id="cctvnumber"
                    />
                  </div>
                </div>
              )
            }

          </div>
           */}

          <div className="col-lg-12 mt-2 text-uppercase">
            <div className="row">
              {/* <div className="col-lg-12 pl-0">
                <h4><>Upload Documents</></h4>
              </div> */}
              {unit_cctv_document?.length < 5 ? (
                <div className="row">
                  <div className="col-lg-4">
                    {/* <EFileUpload type="application/pdf" onUpload={(url, id) => { setDocument(url); setDocumentId(id); }} /> */}

                    {/* <input placeholder="selectfile" accept="application/pdf" type="file" ref={unitdocumentUploadRef} onChange={async (e: any) => {
                      const data = new FormData();
                      let file = e?.currentTarget?.files?.item(0);
                      if (file && file.size < 2000000) {
                        data.append("file", e?.currentTarget?.files?.item(0))
                        const res = await post(data);
                        setDocument(`_/assets/${res?.data?.private_hash}`);
                        setDocumentId(res?.data?.id)
                      } else {
                        alert("Please select file less than 2MB")
                      }
                    }} /> */}

                    <input
                      accept="application/pdf"
                      type="file"
                      ref={unitdocumentUploadRef}
                      onChange={async (e: any) => {
                        const file = new FormData();

                        let fileObject = e?.currentTarget?.files?.item(0);
                        if (fileObject && fileObject.size < 2000000) {
                          setDocFileToUpload(e?.currentTarget?.files?.item(0));
                          // file.append(
                          //   "myFile",
                          //   e?.currentTarget?.files?.item(0)
                          // );
                          // const token = JSON.parse(
                          //   localStorage.getItem("persistantState") || ""
                          // )?.user?.token;
                          // /*  do not change **tokenInput** variable if then change node backend is also required*/
                          // const tokenInput = "qwertyytrewq";
                          // const res = await post(
                          //   `?${tokenInput}=${token}`,
                          //   file
                          // );
                          // if (res.error) {
                          //   alert(
                          //     "Unauthorized user request login again to submit file"
                          //   );
                          // } else {
                          //   setDocumentId(res?.data?._id);
                          // }
                        } else {
                          alert("Please upload file which is less than 2MB");
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      value={docName}
                      onChange={(e) => {
                        let val = e.target.value;
                        setDocName(val);
                      }}
                      className="form-control form-control-sm"
                      placeholder="Name of ducument"
                    />
                    <small className="form-text text-muted text-uppercase">
                      You can only upload 5 documents
                    </small>
                  </div>
                  <div className="col-lg-2">
                    <button
                      disabled={docName == "" ? true : false}
                      onClick={async () => {
                        formDataDoc.append("myFile", docFileToUpload);
                        formDataDoc.append("docname", docName);
                        formDataDoc.append("vpname", data?.data?.profile?.name);
                        const token = JSON.parse(
                          localStorage.getItem("persistantState") || ""
                        )?.user?.token;
                        const tokenInput = "qwertyytrewq";
                        const res = await post(
                          `?${tokenInput}=${token}`,
                          formDataDoc
                        );
                        if (res?.error) {
                          alert(
                            "Unauthorized user request login again to submit file"
                          );
                        } else {
                          setDocumentId(res?.data?._id);
                          setUnit_cctv_document((prev) => [
                            ...prev,
                            {
                              name: docName,
                              url: document,
                              id: res?.data?._id,
                            },
                          ]);
                          setDocName("");
                          unitdocumentUploadRef.current!.value = "";
                        }
                      }}
                      className="btn btn-sm  btn-primary"
                      style={{ fontSize: "1.1rem" }}
                    >
                      Add +
                    </button>
                  </div>
                  <div className="col-lg-12  m-0">
                    <small className="form-text text-muted text-uppercase">
                      You can only upload 5 documents
                    </small>
                  </div>
                </div>
              ) : (
                <div className="pl-0">
                  <h4>You can only select 5 documents </h4>
                </div>
              )}
              {/* {document && (
              <p className="small">Document selected:
                <a href={API_URL + document}
                  className="small text-secondary ml-1"
                  target="_blank" rel="noopener noreferrer">
                  View <ArrowRight className="mb-1" />
                </a>
              </p>
            )} */}
              {
                <div className="col-lg-6 pl-0 text-uppercase">
                  <div className="pl-0">Document selected:</div>
                  {unit_cctv_document?.map((e) => (
                    <div className="bg-white row my-1 mt-2 pr-4 ml-2 py-1 rounded d-flex align-items-center">
                      <div className="col-lg-11">
                        {/* <a href={API_URL + e.url} className="text-secondary mt-2 mb-2 text-capitalize" target="_blank" rel="noopener noreferrer">
                            <FileEarmarkArrowDown className="" fontSize="17px" /> {e.name}
                          </a> */}
                        <div
                          className="text-secondary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            fetchFile(e?.id);
                          }}
                        >
                          {e?.name}
                        </div>
                      </div>
                      <div className="col-lg-1">
                        <button
                          className="btn-sm btn"
                          onClick={async () => {
                            setDeltefileModal(true);
                            setDeleteFileID(e?.id);
                            setDeleteFileType("docs");

                            // deletefile(e?.id)
                          }}
                        >
                          <Trash className="h3 mt-2 text-danger" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>

          {/* sops */}
        </div>

        {/* <h4 className="mt-2 ">UPLOAD SOP'S</h4>
        <div className="row m-1 bg-light rounded p-3 pl-0">
          <div className="row w-100">
            <div className="col-lg-12 m-0">
              {profileData.sops.length < 5 ? (
                <div className="row">
                  <div className="col-lg-4">
                    <input
                      accept="application/pdf"
                      type="file"
                      ref={sopUploadRef}
                      onChange={async (e: any) => {
                        const data = new FormData();
                        let file = e?.currentTarget?.files?.item(0);
                        if (file && file.size < 2000000) {
                          setSopFileToUpload(e?.currentTarget?.files?.item(0));
                        } else {
                          alert("Please select file less than 2MB");
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      required
                      type="text"
                      value={sopName}
                      placeholder="Enter Document Name "
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        let val = e.target.value;
                        setSopName(val);
                      }}
                    />
                    <small className="form-text text-muted text-uppercase">
                      You can only upload 5 sops
                    </small>
                  </div>
                  <div className="col-lg-2">
                    <button
                      disabled={
                        sopName == "" || sopUploadRef.current!.value == ""
                          ? true
                          : false
                      }
                      style={{
                        fontSize: "1.1rem",
                      }}
                      onClick={async () => {
                        formDataSop.append("myFile", sopFileToUpload);
                        formDataSop.append("docname", sopName);
                        formDataSop.append("vpname", data?.data?.profile?.name);
                        const token = JSON.parse(
                          localStorage.getItem("persistantState") || ""
                        )?.user?.token;
                        const tokenInput = "qwertyytrewq";
                        const res = await post(
                          `?${tokenInput}=${token}`,
                          formDataSop
                        );
                        if (res?.error) {
                          alert(
                            "Unauthorized user request login again to submit file"
                          );
                        } else {
                          setSopDoc(`_/assets/${res?.data?.private_hash}`);
                          setSopID(res?.data?._id);
                          let temp: any = [...profileData.sops];
                          temp.push({
                            url: sopDoc,
                            name: sopName,
                            id: res?.data?._id,
                          });
                          setProfileData((prevState) => {
                            return Object.assign({}, prevState, {
                              sops: temp,
                            });
                          });
                          setSopName("");
                          setSopDoc("");
                          setSopID(-1);
                          // setRefresh(true)
                          sopUploadRef.current!.value = "";
                        }
                      }}
                      className="btn btn-primary btn-sm "
                    >
                      Add +
                    </button>
                  </div>
                </div>
              ) : (
                <div className="">
                  <h4>
                    You can only select 5 SOP'S. please delete one to select new
                  </h4>
                </div>
              )}
            </div>
            <div className="col-lg-12 text-uppercase">
              <div className="pl-0">
                <div className="ml-0 pl-0">SOP'S selected:</div>
                <ul className="bg-light p-2 rounded text-uppercase">
                  {profileData?.sops?.map((e: any) => (
                    <li className="nav bg-white d-flex justify-content-between align-items-center  col-lg-12 rounded px-2 my-2">
                      <div>
                        <div
                          className="text-secondary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            fetchFile(e?.id);
                          }}
                        >
                          {e.name}
                        </div>
                      </div>
                      <div>
                        <button
                          className="btn-sm btn pt-2"
                          onClick={async () => {
                            setDeltefileModal(true);
                            setDeleteFileID(e?.id);
                            setDeleteFileType("sops");

                            // deletefilesop(e?.id)
                          }}
                        >
                          <Trash className="h4 text-danger" />
                        </button>
                      </div>
                    </li>
                  ))}
                  {profileData?.sops.length <= 0 && (
                    <small className="text-muted mr-1 text-uppercase">
                      No SOP'S selected
                    </small>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  };
  const FORCEJSX = () => {
    return (
      <>
        <div className="text-uppercase">
          <div className="spacer spacer--sm"></div>
          <hr />
          <h2 className="">SANCTIONED DEPLOYMENT</h2>
          <div className="spacer spacer--sm"></div>
          <div className="row">
            {data?.data?.assignment_by_rank?.map((item: any, index: any) => {
              totalSenctioned += Number(item.count);
              return (
                <React.Fragment>
                  <div className="col-md-2" key={index}>
                    <div className="form-group">
                      <label htmlFor="exampleInput1">{item.rank}</label>
                      <input
                        type="number"
                        min={0}
                        className="form-control form-control-sm"
                        value={item.count}
                        id="exampleInput1"
                        disabled={true}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            <div className="col-md-2">
              <label htmlFor="exampleInput1">TOTAL</label>
              <div className="form-group">
                <input
                  type="number"
                  min={0}
                  className="form-control form-control-sm"
                  value={totalSenctioned}
                  id="exampleInput1"
                  disabled={true}
                />
              </div>
            </div>
            {/* <hr className="border-width-lg" /> */}
          </div>
          <div className="spacer spacer--sm"></div>
          <hr />
          <h2 className="">FORCE DEPLOYMENT (Total:{totalDeplyed})</h2>
          {/* <div className="spacer spacer--sm"></div> */}
          {totalDeplyed !== totalSenctioned && (
            <div className="row py-2 pl-3">
              <div className="col-md-12  pl-0 text-danger small">
                Reason for allocating{" "}
                {totalDeplyed > totalSenctioned ? "more" : "less"} than
                sanctioned
              </div>
              <div className="col-md-12 pl-0">
                <textarea
                  className="form-control"
                  value={force_deployment_comment}
                  onChange={(e) => {
                    const val = e.target.value;
                    setForce_deployment_comment(val);
                  }}
                />
              </div>
            </div>
          )}

          <table className="table has-action-buttons text-uppercase">
            <thead>
              <tr>
                <th style={{ width: "150px" }}>FORCE</th>
                <th style={{ width: "200px" }}>BATTALION</th>
                <th style={{ width: "100px" }}>RANK</th>
                <th style={{ width: "100px" }}>COUNT</th>
                <th style={{ width: "100px" }}></th>
              </tr>
            </thead>
            <tbody>
              {unitDeployedArray?.map((e, index) => {
                return (
                  <tr>
                    <th>
                      <select
                        style={{ appearance: "none" }}
                        className="form-control form-control-sm"
                        disabled
                        // onChange={
                        //   (e) => {
                        //     let val = e.target.value;
                        //     let selected = force?.data.find(value => value.name == val)
                        //     let selectedId = selected.id;
                        //     setUnitDeployed((prevState) => {
                        //       return ({
                        //         ...prevState, force: selectedId
                        //       })
                        //     });
                        //   }}
                      >
                        <option disabled>Select</option>
                        {force?.data.map((each, index) => (
                          <option selected={each.id === e?.force} key={index}>
                            {each.name}
                          </option>
                        ))}
                      </select>

                      {/* <Select ref={resetPer}
                        onChange={(items) => {
                          if (items) {
                            setUnitDeployed((prevState) => {
                              return ({
                                ...prevState, force: items.id
                              })
                            });
                          }
                        }}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => `${option.id}`}
                        options={forceState} /> */}
                    </th>
                    <th>
                      <select
                        style={{ appearance: "none" }}
                        disabled
                        className="form-control form-control-sm"
                        id="exampleFormControlSelect1"
                        // onChange={
                        //   (e) => {
                        //     let val = e.target.value;
                        //     let selected = battalion?.data.find(value => value.name == val)
                        //     let selectedId = selected.id;
                        //     setUnitDeployed((prevState) => {
                        //       return ({ ...prevState, battalion: selectedId })
                        //     });
                        //   }}
                      >
                        <option>Select</option>
                        {battalion?.data
                          ?.filter((ele) => ele.force === e?.force)
                          .map((each, index) => (
                            <option
                              selected={each.id === e?.battalion}
                              key={index}
                            >
                              {each.name}
                            </option>
                          ))}
                      </select>
                    </th>
                    <th>
                      <select
                        style={{ appearance: "none" }}
                        disabled
                        className="form-control form-control-sm"
                        id="exampleFormControlSelect1"
                        // onChange={
                        //   (e) => {
                        //     let val = e.target.value;
                        //     let selected = rank?.data.find(value => value.name == val)
                        //     let selectedId = selected.id;
                        //     setUnitDeployed((prevState) => {
                        //       return Object.assign({}, prevState, {
                        //         rank: selectedId,
                        //       });
                        //     });
                        //   }}
                      >
                        <option selected disabled>
                          Select
                        </option>
                        {rank?.data.map((each, index) => (
                          <option selected={each.id == e.rank} key={index}>
                            {each.name}
                          </option>
                        ))}
                      </select>
                    </th>
                    <th>{e.count}</th>
                    <th>
                      {
                        <>
                          <a
                            onClick={() => {
                              setDeleteForceModel(true);
                              setDeleteForceIndex(index);
                              // setUnitDeployedArray(unitDeployedArray?.filter((e, i) => i !== index))
                              // setTotalDeplyed((prev) => prev -= unitDeployedArray?.[index].count)
                            }}
                            className="btn btn-sm btn-danger"
                          >
                            Delete
                          </a>
                        </>
                      }
                    </th>
                  </tr>
                );
              })}
            </tbody>
            {/* form for rank */}
            {
              <tfoot>
                <tr>
                  <td>
                    {/* <select className="form-control form-control-sm" id="exampleFormControlSelect1"
                      onChange={
                        (e) => {
                          let val = e.target.value;
                          let selected = force?.data.find(value => value.name == val)
                          let selectedId = selected.id;
                          setUnitDeployed((prevState) => {
                            return ({
                              ...prevState, force: selectedId
                            })
                          });
                        }}
                    >
                      <option selected disabled>Select</option>
                      {force?.data.map((each, index) => (
                        <option
                          // selected={each.id === force?.rank}
                          key={index}>{each.name}
                        </option>
                      ))}
                    </select> */}
                    <Select
                      ref={resetPer}
                      onChange={(items) => {
                        if (items) {
                          setUnitDeployed((prevState) => {
                            return {
                              ...prevState,
                              force: items.id,
                            };
                          });
                        }
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option.id}`}
                      options={forceState}
                    />
                  </td>

                  <td>
                    {/* <select className="form-control form-control-sm" id="exampleFormControlSelect1"
                      onChange={
                        (e) => {
                          let val = e.target.value;
                          let selected = battalion?.data.find(value => value.name == val)
                          let selectedId = selected.id;
                          setUnitDeployed((prevState) => {
                            return ({ ...prevState, battalion: selectedId })
                          });
                        }}
                    >
                      <option>Select</option>
                      {battalion?.data?.filter((e) => e.force == unitDeployed?.force).map((each, index) => (
                        <option
                          key={index}>{each.name}
                        </option>
                      ))}
                    </select> */}
                    <Select
                      ref={resetbat}
                      onChange={(items) => {
                        if (items) {
                          setUnitDeployed((prevState) => {
                            return { ...prevState, battalion: items?.id };
                          });
                        }
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option.id}`}
                      options={battalionState}
                    />
                  </td>
                  <td>
                    {/* <select className="form-control form-control-sm" id="exampleFormControlSelect1"
                      onChange={
                        (e) => {
                          let val = e.target.value;
                          let selected = rank?.data.find(value => value.name == val)
                          let selectedId = selected.id;
                          setUnitDeployed((prevState) => {
                            return Object.assign({}, prevState, {
                              rank: selectedId,
                            });
                          });
                        }}
                    >
                      <option selected disabled>Select</option>
                      {rank?.data.map((each, index) => (
                        <option
                          key={index}>{each.name}
                        </option>
                      ))}
                    </select> */}
                    <Select
                      ref={resetrank}
                      onChange={(items) => {
                        if (items) {
                          setUnitDeployed((prevState) => {
                            return {
                              ...prevState,
                              rank: items.id,
                            };
                          });
                        }
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option.id}`}
                      options={rankState}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      min={0}
                      placeholder="Enter count"
                      className="form-control form-control-sm"
                      value={unitDeployed.count}
                      onChange={(e) => {
                        const { value } = e.target;
                        setUnitDeployed((prevState) => {
                          return Object.assign({}, prevState, {
                            count: Number(value),
                          });
                        });
                      }}
                    />
                  </td>
                  <td>
                    <div className="action-buttons">
                      <button
                        disabled={
                          !unitDeployed.force ||
                          !unitDeployed.battalion ||
                          !unitDeployed.rank ||
                          !unitDeployed.count
                        }
                        onClick={() => {
                          const tempArr = unitDeployedArray;
                          tempArr.push(unitDeployed);
                          setUnitDeployedArray(tempArr);
                          setTotalDeplyed(
                            totalDeplyed + Number(unitDeployed?.count)
                          );
                          setUnitDeployed({
                            force: "",
                            battalion: "",
                            rank: "",
                            count: 0,
                          });

                          resetPer?.current.select.clearValue();
                          resetrank?.current.select.clearValue();
                          resetbat?.current.select.clearValue();
                        }}
                        className="btn btn-sm btn-primary"
                      >
                        + Add
                      </button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            }
          </table>

          {/* <div className="row pt-2 pb-2 border-width-lg">
                                <div className="col-md-2 font-weight-bold"> Rank </div>
                                <div className="col-md-2 font-weight-bold"> Sanctioned </div>
                                <div className="col-md-2 font-weight-bold"> Deployed By Police District </div>
                                <div className="col-md-2 font-weight-bold"> Deployed By Armed </div>
                                <div className="col-md-2 font-weight-bold"> Allocated </div>
                                <div className="col-md-2 font-weight-bold"> Private Security </div>
                              </div> */}
          <>
            {/* {data?.data?.assignment_by_rank?.map((item: any, index: any) => (
                                  <div className="bg-light rounded" key={index}>
                                    <div className="row  py-2 bg-light mt-2 rounded-top" >
                                      <div className="col-md-2 d-flex align-items-center">{item.rank}</div>
                                      <div className="col-md-2 d-flex align-items-center">
                                        <div className="form-group mb-0">
                                          <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            id="exampletext"
                                            value={item.count}
                                            disabled={true}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group mb-0">
                                          <input
                                            type="number"
                                            min={0}
                                            className="form-control form-control-sm"
                                            id="exampletext"
                                            placeholder="0"
                                            value={item.desployed_by_district}
                                            onChange={(e) => {
                                              const val = e.target.value;
                                              // setDummyAssignment((prevState) => {
                                              //   return Object.assign({}, prevState, {
                                              //     desployed_by_district: val,
                                              //   });
                                              // });
                                              changeObject('desployed_by_district', val, index);
                                              // totalAllocation(index);
                                            }}

                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2 ">
                                        <input
                                          type="number"
                                          min={0}
                                          className="form-control form-control-sm"
                                          id="exampletext"
                                          value={item.desployed_by_armed}
                                          onChange={(e) => {
                                            const val = e.target.value;
                                            changeObject('desployed_by_armed', val, index)
                                            // totalAllocation(index);
                                          }}
                                          placeholder="0"
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        {dummyAssignment?.length > 0 &&
                                          <input
                                            type="number"
                                            className="form-control form-control-sm"
                                            // onChange={(e) => {
                                            //   const val = e.target.value;
                                            //   // setDummyAssignment((prevState) => {
                                            //   //   return Object.assign({}, prevState, {
                                            //   //     desployed_by_district: val,
                                            //   //   });
                                            //   // });
                                            //   changeObject('allocated', val, index)
                                            // }}
                                            id="exampletext"
                                            readOnly
                                            value={dummyAssignment[index]?.allocated}
                                          />
                                        }

                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-check mb-0">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                            onChange={() => {
                                              let tempArr = [...hasPrivateSecurity];

                                              tempArr[index] = !tempArr[index];
                                              setPrivateSecurity(tempArr)
                                            }}
                                            checked={hasPrivateSecurity[index]
                                            }
                                          // onChange={(e) => {
                                          //   const val = e.target.checked;
                                          //   // setDummyAssignment((prevState) => {
                                          //   //   return Object.assign({}, prevState, {
                                          //   //     desployed_by_district: val,
                                          //   //   });
                                          //   // });
                                          //   changeObject('private_security',val,index)
                                          // }}

                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row px-2 bg-light">


                                      {
                                        dummyAssignment?.length > 0 && (
                                          dummyAssignment[index]?.allocated > item.count && (
                                            <div className="row py-2 pl-4">
                                              <div className="col-md-12  pl-0 text-danger">
                                                Reason for allocating more than sanctioned
                                              </div>
                                              <div className="col-md-12">
                                                <textarea className="form-control"
                                                  value={dummyAssignment[index]?.comment_for_allocate_more_than_sanctioned}
                                                  onChange={(e) => {
                                                    const val = e.target.value;
                                                    changeObject('comment_for_allocate_more_than_sanctioned', val, index)
                                                    // totalAllocation(index);
                                                  }}
                                                />
                                              </div>


                                            </div>
                                          )
                                        )
                                      }
                                    </div>


                                    {hasPrivateSecurity[index] === true && <div style={{ marginLeft: "0%" }}>
                                      <div className="row bg-light rounded-bottom">
                                        <div className="col-lg-12 pt-3">
                                          <h3>Private Security Details</h3>
                                        </div>
                                        <div className="col-md-3 font-weight-bold">Name</div>
                                        <div className="col-md-3 font-weight-bold">
                                          Address
                                        </div>
                                        <div className="col-md-3 font-weight-bold">
                                          Number Assigned
                                        </div>
                                        <div className="col-md-3 font-weight-bold">
                                          Contact
                                        </div>
                                      </div>
                                      <div className="row pt-2 mb-3 bg-light rounded-bottom pb-4">
                                        <div className="col-md-3">
                                          <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={dummyAssignment[index]?.name_private_security || item?.name_private_security}
                                            onChange={(e) => {
                                              const val = e.target.value;

                                              changeObject('name_private_security', val, index)
                                              if (val.length !== 0) {
                                                changeObject('private_security', true, index)
                                              } else {
                                                changeObject('private_security', false, index)

                                              }
                                            }}
                                            id="exampletext"
                                          />
                                        </div>
                                        <div className="col-md-3">
                                          <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={item.address_private_security}
                                            onChange={(e) => {
                                              const val = e.target.value;

                                              changeObject('address_private_security', val, index)
                                            }}
                                            id="exampletext"
                                          />
                                        </div>
                                        <div className="col-md-3">
                                          <input
                                            type="number"
                                            className="form-control form-control-sm"
                                            value={item.assigned_private_security}
                                            onChange={(e) => {
                                              const val = e.target.value;

                                              changeObject('assigned_private_security', val, index)
                                            }}
                                            id="exampletext"
                                          />
                                        </div>
                                        <div className="col-md-3">
                                          <input
                                            type="number"
                                            className="form-control form-control-sm"
                                            value={item.contact_private_security}
                                            onChange={(e) => {
                                              const val = e.target.value;
                                              changeObject('contact_private_security', val, index)
                                            }}
                                            id="exampletext"
                                          />
                                        </div>
                                      </div>
                                    </div>}

                                  </div>
                                ))} */}
          </>
        </div>
        <div className="spacer spacer--sm"></div>
        <h2>OTHER DEPLOYMENT</h2>
        <div className="spacer spacer--sm"></div>
        <table className="table has-action-buttons">
          <thead>
            <tr>
              <th style={{ width: "150px" }}>FORCE</th>
              <th style={{ width: "150px" }}>Rank</th>
              <th style={{ width: "80px" }}>COUNT</th>
              <th>
                <div className="row">
                  <div className="col-lg-4">Name</div>
                  <div className="col-lg-4">Address</div>
                  <div className="col-lg-4">Contact</div>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {otherDeployedArray?.map((e, i) => (
              <tr key={i}>
                <td>
                  <select
                    style={{ appearance: "none" }}
                    disabled
                    className="form-control form-control-sm"
                    id="exampleFormControlSelect1"
                    // onChange={
                    //   (e) => {
                    //     let val = e.target.value;
                    //     let selected = otherforce?.data.find(value => value.name == val)
                    //     let selectedId = selected.id;
                    //     setOtherDeployed((prevState) => {
                    //       return ({
                    //         ...prevState, force: selectedId
                    //       })
                    //     });
                    //   }}
                  >
                    <option selected disabled>
                      Select
                    </option>
                    {otherforce?.data.map((each, index) => (
                      <option selected={each.id == e.force} key={index}>
                        {each.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  {" "}
                  {e?.force != "3" ? (
                    <select
                      style={{ appearance: "none" }}
                      disabled
                      className="form-control form-control-sm"
                      id="exampleFormControlSelect1"
                    >
                      {rank?.data.map((each, index) => (
                        <option selected={each.id == e.rank} key={index}>
                          {each.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <span className="small">NA</span>
                  )}
                </td>
                <th>{e.count}</th>
                <th>
                  {e?.force == "3" || e?.force == "8" ? (
                    <div className="row">
                      <div className="col-lg-4">{e.private_security_name}</div>
                      <div className="col-lg-4">
                        {e.private_security_address}{" "}
                      </div>
                      <div className="col-lg-4">
                        {e.private_security_contact}
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-lg-4">
                        {" "}
                        <span className="small">NA</span>
                      </div>
                      <div className="col-lg-4">
                        {" "}
                        <span className="small">NA</span>
                      </div>
                      <div className="col-lg-4">
                        {" "}
                        <span className="small">NA</span>
                      </div>
                    </div>
                  )}
                </th>
                <th>
                  <a
                    onClick={() => {
                      setDeleteOtherForceModel(true);
                      setDeleteOtherForceIndex(i);
                    }}
                    className="btn btn-sm btn-danger"
                  >
                    Delete
                  </a>
                </th>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td>
                <Select
                  ref={resetOtherforce}
                  onChange={(items) => {
                    if (items) {
                      setOtherDeployed((prevState) => {
                        return {
                          ...prevState,
                          force: items.id,
                        };
                      });
                    }
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option.id}`}
                  options={otherforce?.data}
                />
              </td>
              {otherDeployed?.force != "3" ? (
                <td>
                  <Select
                    ref={resetOtherRank}
                    onChange={(items) => {
                      if (items) {
                        setOtherDeployed((prevState) => {
                          return {
                            ...prevState,
                            rank: items.id,
                          };
                        });
                      }
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    options={rank?.data}
                  />
                </td>
              ) : (
                <td></td>
              )}

              <td>
                <input
                  ref={ResetOtherforceCount}
                  type="number"
                  min={0}
                  className="form-control form-control-sm"
                  placeholder="0"
                  onChange={(e) => {
                    let val = e.target.value;
                    setOtherDeployed((prevState) => ({
                      ...prevState,
                      count: Number(val),
                    }));
                  }}
                />
              </td>

              <td>
                {(otherDeployed?.force == "3" ||
                  otherDeployed?.force == "8") && (
                  <div className="d-flex w-100">
                    <input
                      type="text"
                      className="form-control form-control-sm  mr-3"
                      onChange={(e) => {
                        let val = e.target.value;
                        setOtherDeployed((prevState) => ({
                          ...prevState,
                          private_security_name: val,
                        }));
                      }}
                      placeholder="Name"
                    ></input>
                    <input
                      type="text"
                      className="form-control form-control-sm mr-3"
                      onChange={(e) => {
                        let val = e.target.value;
                        setOtherDeployed((prevState) => ({
                          ...prevState,
                          private_security_address: val,
                        }));
                      }}
                      placeholder="Address"
                    ></input>
                    <input
                      type="number"
                      min={0}
                      className="form-control form-control-sm mr-3"
                      onChange={(e) => {
                        let val = e.target.value;
                        setOtherDeployed((prevState) => ({
                          ...prevState,
                          private_security_contact: val,
                        }));
                      }}
                      placeholder="Contact"
                    ></input>
                  </div>
                )}
              </td>

              <td>
                <div className="action-buttons">
                  <button
                    disabled={!otherDeployed.force || !otherDeployed.count}
                    onClick={() => {
                      setOtherDeployedArray((prev) => [...prev, otherDeployed]);
                      setOtherDeployed({
                        force: "",
                        count: 0,
                        rank: "",
                        private_security_name: "",
                        private_security_address: "",
                        private_security_contact: "",
                      });
                      resetOtherforce?.current.select.clearValue();
                      ResetOtherforceCount.current.value = 0;
                      if (otherDeployed?.force != "3") {
                        resetOtherRank?.current.select.clearValue();
                      }
                      // ResetOtherRankCount.current.value = 0;
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    + Add
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>

        <div className="spacer spacer--sm"></div>
        <h2>SUPERVISIONING OFFICER</h2>

        <div className="row text-uppercase">
          <div className="col-lg-3">
            <b>Name</b>
            <input
              type="text"
              className="form-control form-control-sm"
              value={supervisorOfficer.name}
              onChange={(e) => {
                const val = e.target.value;
                setSupervisorOfficer((prevState) => {
                  return Object.assign({}, prevState, {
                    name: val,
                  });
                });
              }}
              id="exampletext"
            />
          </div>
          <div className="col-lg-3">
            <b>Phone</b>
            <input
              type="number"
              min={0}
              maxLength={10}
              className="form-control form-control-sm"
              value={supervisorOfficer.phone}
              onChange={(e) => {
                const val = e.target.value;
                setSupervisorOfficer((prevState) => {
                  return Object.assign({}, prevState, {
                    phone: val,
                  });
                });
              }}
              id="exampletext"
            />
          </div>
          <div className="col-lg-3">
            <b>Rank</b>
            <select
              className="form-control form-control-sm"
              id="exampleFormControlSelect1"
              onChange={(e) => {
                let val = e.target.value;
                let selected = rank?.data.find((value) => value.name == val);
                let selectedId = selected.name;
                setSupervisorOfficer((prevState) => {
                  return Object.assign({}, prevState, {
                    rank: selectedId,
                  });
                });
              }}
            >
              <option selected disabled>
                Select
              </option>
              {rank?.data.map((each, index) => (
                <option
                  selected={each.name === supervisorOfficer?.rank}
                  key={index}
                >
                  {each.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3">
            <b>Order No.</b>
            <input
              type="text"
              className="form-control form-control-sm"
              value={supervisorOfficer.order_no}
              onChange={(e) => {
                const val = e.target.value;
                setSupervisorOfficer((prevState) => {
                  return Object.assign({}, prevState, {
                    order_no: val,
                  });
                });
              }}
              id="exampletext"
            />
          </div>
        </div>
        <div className="spacer spacer--sm"></div>
        <h2>ESTABLISHMENT OFFICER</h2>
        <div className="spacer spacer--sm"></div>
        <div className="row text-uppercase">
          <div className="col-lg-3">
            <b>Name</b>
            <input
              type="text"
              className="form-control form-control-sm"
              value={guardIncharge.name}
              onChange={(e) => {
                const val = e.target.value;
                setGuardIncharge((prevState) => {
                  return Object.assign({}, prevState, {
                    name: val,
                  });
                });
              }}
              id="exampletext"
            />
          </div>
          <div className="col-lg-3">
            <b>Phone</b>
            <input
              type="number"
              min={0}
              maxLength={10}
              className="form-control form-control-sm"
              value={guardIncharge.phone}
              onChange={(e) => {
                const val = e.target.value;
                setGuardIncharge((prevState) => {
                  return Object.assign({}, prevState, {
                    phone: val,
                  });
                });
              }}
              id="exampletext"
            />
          </div>
          <div className="col-lg-3">
            <b>Rank</b>
            <select
              className="form-control form-control-sm"
              id="exampleFormControlSelect1"
              onChange={(items) => {
                if (items.target.value) {
                  let val = items.target.value;
                  let selected = rank?.data.find((value) => value.name == val);
                  let selectedId = selected.name;
                  setGuardIncharge((prevState) => {
                    return Object.assign({}, prevState, {
                      rank: selectedId,
                    });
                  });
                }
              }}
            >
              <option selected disabled>
                Select
              </option>
              {rank?.data.map((each, index) => (
                <option
                  selected={
                    each.name === data?.data?.on_guard_incharge?.rank && true
                  }
                  key={index}
                >
                  {each.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3">
            <b>Order No.</b>
            <input
              type="text"
              className="form-control form-control-sm "
              value={
                guardIncharge.order_no ||
                data?.data?.on_guard_incharge?.order_no
              }
              onChange={(e) => {
                const val = e.target.value;
                setGuardIncharge((prevState) => {
                  return Object.assign({}, prevState, {
                    order_no: val,
                  });
                });
              }}
              id="exampletext"
            />
          </div>
        </div>
        <div className="spacer spacer--sm"></div>
        <h2 className="text-uppercase">SECURITY infrastructure</h2>
        <div className="spacer spacer--sm"></div>
        {data?.data?.security_equipment?.length > 0 ? (
          <div className="row text-uppercase">
            <div className="col-md-3 font-weight-bold"> Infrastructure </div>
            <div className="col-md-3 font-weight-bold"> Name </div>
            <div className="col-md-3 font-weight-bold"> Sanctioned </div>
            <div className="col-md-3 font-weight-bold"> Deployed </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <span className="text-muted">Not Available</span>
            </div>
          </div>
        )}
        {data?.data?.security_equipment?.map((each, index) => (
          <div
            className="row pt-2 text-uppercase mb-2 pb-2 border-bottom"
            key={index}
          >
            <div className="col-md-3 font-weight-bold">
              <input
                type="text"
                className="form-control form-control-sm"
                value={each.equipment_name.toUpperCase()}
                disabled={true}
                id="exampletext"
              />
            </div>
            <div className="col-md-3 font-weight-bold">
              <input
                type="text"
                className="form-control form-control-sm"
                value={each.name}
                disabled={true}
                id="exampletext"
              />
            </div>
            <div className="col-md-3 font-weight-bold">
              <input
                type="text"
                className="form-control form-control-sm"
                value={each.eqipment_count}
                disabled={true}
                id="exampletext"
              />
            </div>
            <div className="col-md-3 font-weight-bold">
              <input
                type="number"
                min={0}
                className="form-control form-control-sm"
                value={each.equipment_deployed}
                onChange={(e) => {
                  let val = Number(e.target.value);
                  changeSecurityAssignment("equipment_deployed", val, index);
                }}
                id="exampletext"
              />
            </div>
            {each.equipment_deployed > each.eqipment_count && (
              <>
                <label className="font-weight-bold pl-3 mt-2">Comment</label>
                <div className="col-md-12 mt-2">
                  {/* <div className="col-md-12 font-weight-bold text-danger ml-0 pl-0">Reason (for deploying more than sanctioned) </div> */}
                  <textarea
                    // type="text"
                    className="form-control form-control-sm "
                    placeholder="Reasons for deploying more than sanctioned"
                    value={each.deployed_more_comment}
                    onChange={(e) => {
                      let val = e.target.value;
                      changeSecurityAssignment(
                        "deployed_more_comment",
                        val,
                        index
                      );
                    }}
                    id="exampletext"
                  />
                  {/* <span className="small text-danger">Reasons for deploying more than sanctioned **</span> */}
                </div>
              </>
            )}
          </div>
        ))}

        <div className="spacer spacer--sm"></div>
        <h2 className="text-uppercase">TEAMS</h2>
        <div className="spacer spacer--sm"></div>
        {data?.data?.team_s?.length > 0 ? (
          <div className="row text-uppercase">
            <div className="col-md-3 font-weight-bold"> Teams </div>
            <div className="col-md-3 font-weight-bold"> Name </div>
            <div className="col-md-3 font-weight-bold"> SANCTIONED</div>
            <div className="col-md-3 font-weight-bold"> Deployed </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <span className="text-muted">Not Available</span>
            </div>
          </div>
        )}
        {data?.data?.team_s?.map((each, index) => (
          <div className="row pt-2 text-uppercase" key={index}>
            <div className="col-md-3 font-weight-bold">
              <input
                type="text"
                className="form-control form-control-sm"
                value={each.team_name.toUpperCase()}
                disabled={true}
                id="exampletext"
              />
            </div>
            <div className="col-md-3 font-weight-bold">
              <input
                type="text"
                className="form-control form-control-sm"
                value={each.name}
                disabled={true}
                id="exampletext"
              />
            </div>
            <div className="col-md-3 font-weight-bold">
              <input
                type="text"
                className="form-control form-control-sm"
                value={each.team_count}
                disabled={true}
                id="exampletext"
              />
            </div>
            <div className="col-md-3 font-weight-bold">
              <input
                type="number"
                min={0}
                className="form-control form-control-sm"
                value={each.team_deployed}
                onChange={(e) => {
                  let val = Number(e.target.value);
                  changeTeamsAssignment("team_deployed", val, index);
                }}
                id="exampletext"
              />
            </div>
            {console.log("teams", teams)}
            {each.team_deployed > each.team_count && (
              <>
                <label className="font-weight-bold pl-3 mt-2">Comment</label>
                <div className="col-md-12 mt-2">
                  {/* <div className="col-md-12 font-weight-bold text-danger ml-0 pl-0">Reason (for deploying more than sanctioned) </div> */}
                  <textarea
                    // type="text"
                    className="form-control form-control-sm "
                    placeholder="Reasons for deploying more than sanctioned"
                    value={each.team_deployed_more_comment}
                    onChange={(e) => {
                      let val = e.target.value;
                      changeTeamsAssignment(
                        "team_deployed_more_comment",
                        val,
                        index
                      );
                    }}
                    id="exampletext"
                  />
                  {/* <span className="small text-danger">Reasons for deploying more than sanctioned **</span> */}
                </div>
              </>
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {loadingProfile ? (
        <LoadingScreen />
      ) : (
        <>
          <React.Fragment>
            <div className="container-fluid ">
              <div className="row px-5 py-5 ">
                <div className="col-lg-6 pr-0">
                  {data?.data?.status &&
                    (moment(data?.data?.review_date).format("YYYY-MM-DD") <=
                    moment(reviewDtae).format("YYYY-MM-DD") ? (
                      <h1 className="">REVIEW DEPLOYMENT</h1>
                    ) : (
                      <h1 className="">
                        {data?.data?.profile.status === "deployed"
                          ? "EDIT DEPLOYMENT"
                          : "DEPLOYMENT"}{" "}
                      </h1>
                    ))}
                </div>
                <div className="col-lg-6 d-flex justify-content-end pl-0">
                  {data?.data?.status && (
                    <div className="">
                      <button
                        className="btn btn-outline-dark mr-2"
                        onClick={() => {
                          window.location.href = NON_ADMIN.deploy;
                        }}
                      >
                        cancel{" "}
                      </button>
                      <button
                        className={`${
                          data?.data?.profile.status === "deployed"
                            ? "btn btn-primary"
                            : "btn btn-success"
                        }`}
                        onClick={async () => {
                          setDeclineModel(true);
                        }}
                      >
                        {data?.data?.profile.status === "deployed"
                          ? "update"
                          : "Save"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="main-body w-100">
              <div className="card h-auto">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-lg-12 px-5 pt-2">
                      {/* 
                        
                          there are 3 type of profile for each VP .
                           1:CCTV
                           2:CCTV AND DEPLOYED FORCE
                           3:DEPLOYED FORCE
                          for now the requiremnt has changed so we commenting the code 
                          but we dont know in future that client can say being back old changes 
                          beacuse customer is king <(*_*)> 
                        */}

                      {/* {data?.data?.profile?.type?.name === "CCTV" ? (CCTVJSX()) : null}
                        {data?.data?.profile?.type?.name === "CCTV AND DEPLOYED FORCE" ? <> {CCTVJSX()}{FORCEJSX()}</> : null}
                        {data?.data?.profile?.type?.name === "DEPLOYED FORCE" ? (FORCEJSX()) : null} */}

                      {CCTVJSX()}
                      {FORCEJSX()}
                      <hr />
                      <div className="row mb-2 mt-3">
                        <h2 className="pl-3 text-uppercase">
                          Nearby LandMarks
                        </h2>
                      </div>
                      <div className="spacer spacer--sm"></div>
                      <div className="row pl-3">
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Name</th>
                              <th>Address</th>
                              <th>Phone</th>
                              <th>Distance ( in KM )</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Police Sation</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    // value={profileData?.police_station?.name || data?.data?.profile?.police_station?.name}
                                    value={profileData?.police_station?.name}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        police_station: {
                                          ...profileData.police_station,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.police_station?.address}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        police_station: {
                                          ...profileData.police_station,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    min={0}
                                    maxLength={10}
                                    className="form-control form-control-sm"
                                    value={profileData?.police_station?.phone}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        police_station: {
                                          ...profileData.police_station,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={
                                      profileData?.police_station?.distance
                                    }
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        police_station: {
                                          ...profileData.police_station,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>SSP</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.ssp?.name}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        ssp: {
                                          ...profileData.ssp,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.ssp?.address}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        ssp: {
                                          ...profileData.ssp,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.ssp?.phone}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        ssp: {
                                          ...profileData.ssp,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.ssp?.distance}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        ssp: {
                                          ...profileData.ssp,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>NDRF</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.ndrf?.name}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        ndrf: {
                                          ...profileData.ndrf,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.ndrf?.address}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        ndrf: {
                                          ...profileData.ndrf,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.ndrf?.phone}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        ndrf: {
                                          ...profileData.ndrf,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.ndrf?.distance}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        ndrf: {
                                          ...profileData.ndrf,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Airport</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.airport?.name}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        airport: {
                                          ...profileData.airport,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.airport?.address}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        airport: {
                                          ...profileData.airport,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.airport?.phone}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        airport: {
                                          ...profileData.airport,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.airport?.distance}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        airport: {
                                          ...profileData.airport,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Helipad</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.helipad?.name}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        helipad: {
                                          ...profileData.helipad,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.helipad?.address}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        helipad: {
                                          ...profileData.helipad,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.helipad?.phone}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        helipad: {
                                          ...profileData.helipad,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.helipad?.distance}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        helipad: {
                                          ...profileData.helipad,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>RAILWAY STATION</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.railway_station?.name}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        railway_station: {
                                          ...profileData.railway_station,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={
                                      profileData?.railway_station?.address
                                    }
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        railway_station: {
                                          ...profileData.railway_station,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    min={0}
                                    maxLength={10}
                                    className="form-control form-control-sm"
                                    value={profileData?.railway_station?.phone}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        railway_station: {
                                          ...profileData.railway_station,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={
                                      profileData?.railway_station?.distance
                                    }
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        railway_station: {
                                          ...profileData.railway_station,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Air Base</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.airbase?.name}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        airbase: {
                                          ...profileData.airbase,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.airbase?.address}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        airbase: {
                                          ...profileData.airbase,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.airbase?.phone}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        airbase: {
                                          ...profileData.airbase,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.airbase?.distance}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        airbase: {
                                          ...profileData.airbase,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>District Police Control Room</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={
                                      profileData?.District_Police_Control_Room
                                        ?.name
                                    }
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        District_Police_Control_Room: {
                                          ...profileData.District_Police_Control_Room,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={
                                      profileData?.District_Police_Control_Room
                                        ?.address
                                    }
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        District_Police_Control_Room: {
                                          ...profileData.District_Police_Control_Room,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={
                                      profileData?.District_Police_Control_Room
                                        ?.phone
                                    }
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        District_Police_Control_Room: {
                                          ...profileData.District_Police_Control_Room,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={
                                      profileData?.District_Police_Control_Room
                                        ?.distance
                                    }
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        District_Police_Control_Room: {
                                          ...profileData.District_Police_Control_Room,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Fire Alram</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.Fire_Alram?.name}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Fire_Alram: {
                                          ...profileData.Fire_Alram,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.Fire_Alram?.address}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Fire_Alram: {
                                          ...profileData.Fire_Alram,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.Fire_Alram?.phone}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Fire_Alram: {
                                          ...profileData.Fire_Alram,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.Fire_Alram?.distance}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Fire_Alram: {
                                          ...profileData.Fire_Alram,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Magistrate Office</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.Magistrate_Office?.name}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Magistrate_Office: {
                                          ...profileData.Magistrate_Office,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={
                                      profileData?.Magistrate_Office?.address
                                    }
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Magistrate_Office: {
                                          ...profileData.Magistrate_Office,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={
                                      profileData?.Magistrate_Office?.phone
                                    }
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Magistrate_Office: {
                                          ...profileData.Magistrate_Office,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={
                                      profileData?.Magistrate_Office?.distance
                                    }
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Magistrate_Office: {
                                          ...profileData.Magistrate_Office,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Hospital</th>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.Hospital?.name}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Hospital: {
                                          ...profileData.Hospital,
                                          name: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={profileData?.Hospital?.address}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Hospital: {
                                          ...profileData.Hospital,
                                          address: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Address"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.Hospital?.phone}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Hospital: {
                                          ...profileData.Hospital,
                                          phone: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Phone"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group d-flex align-items-center m-1">
                                  <input
                                    type="number"
                                    maxLength={10}
                                    min={0}
                                    className="form-control form-control-sm"
                                    value={profileData?.Hospital?.distance}
                                    onChange={(e) => {
                                      const val = e.target.value;
                                      setProfileData((profileData) => ({
                                        ...profileData,
                                        Hospital: {
                                          ...profileData.Hospital,
                                          distance: val,
                                        },
                                      }));
                                    }}
                                    placeholder="Distance"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <hr />
                      <a
                        className="text-secondary"
                        onClick={() => {
                          setEditProfileModel(true);
                        }}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        Edit Profile
                        <PencilSquare className="ml-2 mb-1" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="container d-flex justify-content-end p-5">
                  {data?.data?.status && (
                    <>
                      <button
                        className="btn btn-outline-dark mr-2"
                        onClick={() => {
                          window.location.href = NON_ADMIN.deploy;
                        }}
                      >
                        cancel{" "}
                      </button>
                      <button
                        className={`${
                          data?.data?.profile.status === "deployed"
                            ? "btn btn-primary"
                            : "btn btn-success"
                        }`}
                        // disabled={cctv > data?.data?.cctv && deployedExceedComment?.length === 0}
                        onClick={async () => {
                          setDeclineModel(true);
                          // await patch(`/${id}`, { status: "deployed", deployed_exceed_comment: deployedExceedComment, cctv_deployed: cctv, document_on_deployment: document, assignment_by_rank: dummyAssignment, security_equipment: securityAssignment, supervisor_officer: supervisorOfficer, on_guard_incharge: guardIncharge });
                          // await insertProfile(`/${data?.data?.profile?.id}`, profileData);
                          // await insertProfile(`/${data?.data?.profile?.id}`, { status: 'deployed' });
                          // window.location.href = NON_ADMIN.deploy
                        }}
                      >
                        {data?.data?.profile.status === "deployed"
                          ? "update"
                          : "Save"}{" "}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            {declineModel && (
              <>
                <div className="modal-backdrop"></div>
                <div
                  className="modal"
                  id="modelId"
                  tabIndex={-1}
                  role="dialog"
                  style={{ display: "block" }}
                  aria-labelledby="modelTitleId"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable modal-sm"
                    role="document"
                  >
                    <div className="modal-content">
                      {/* <div className="modal-header">
                    {
                      (moment(data?.data?.review_date).format("YYYY-MM-DD") <= moment(reviewDtae).format("YYYY-MM-DD"))
                        ? (<h5 className="">Review Deployment</h5>) :
                        (<h5 className="modal-title">Profile {data?.data?.profile.status === "deployed" ? "update" : "deploy"} request</h5>)
                    }

                    <button
                      type="button"
                      className="close text-danger"
                      onClick={() => setDeclineModel(false)}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div> */}
                      <div className="modal-body">
                        {moment(data?.data?.review_date).format("YYYY-MM-DD") <=
                        moment(reviewDtae).format("YYYY-MM-DD") ? (
                          <span>
                            {" "}
                            Are you sure you want to update this review{" "}
                          </span>
                        ) : (
                          <span>
                            {" "}
                            Are you sure you want to{" "}
                            {data?.data?.profile.status === "deployed"
                              ? "update"
                              : "deploy"}{" "}
                            this profile?
                          </span>
                        )}
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light btn-sm"
                          onClick={() => setDeclineModel(false)}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className={`${
                            data?.data?.profile.status === "deployed"
                              ? "btn btn-primary btn-sm"
                              : "btn btn-success btn-sm"
                          }`}
                          onClick={async () => {
                            await patch(`/${id}`, {
                              status: "deployed",
                              team_s: teams,
                              unit_cctv_document: unit_cctv_document,
                              other_deployment: otherDeployedArray,
                              force_deployemnt: unitDeployedArray,
                              force_deployment_comment: force_deployment_comment,
                              assigned_date: assigned_date,
                              review_date: review_date,
                              deployed_exceed_comment: deployedExceedComment,
                              cctv_deployed: cctv,
                              assignment_by_rank: dummyAssignment,
                              security_equipment: securityAssignment,
                              supervisor_officer: supervisorOfficer,
                              on_guard_incharge: guardIncharge,
                            });
                            await insertProfile(
                              `/${data?.data?.profile?.id}`,
                              profileData
                            );
                            await insertProfile(`/${data?.data?.profile?.id}`, {
                              status: "deployed",
                            });
                            window.location.href = NON_ADMIN.deploy;
                          }}
                          data-dismiss="modal"
                        >
                          {data?.data?.profile.status === "deployed"
                            ? "update"
                            : "deploy"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {deleteForceModel && (
              <>
                <div className="modal-backdrop"></div>
                <div
                  className="modal"
                  id="modelId"
                  tabIndex={-1}
                  role="dialog"
                  style={{ display: "block" }}
                  aria-labelledby="modelTitleId"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable modal-sm"
                    role="document"
                  >
                    <div className="modal-content">
                      {/* <div className="modal-header">
                    {
                      (moment(data?.data?.review_date).format("YYYY-MM-DD") <= moment(reviewDtae).format("YYYY-MM-DD"))
                        ? (<h5 className="">Review Deployment</h5>) :
                        (<h5 className="modal-title">Profile {data?.data?.profile.status === "deployed" ? "update" : "deploy"} request</h5>)
                    }

                    <button
                      type="button"
                      className="close text-danger"
                      onClick={() => setDeclineModel(false)}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div> */}
                      <div className="modal-body">
                        Are you sure you want to delete this force
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light btn-sm"
                          onClick={() => setDeleteForceModel(false)}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          // className="btn btn-primary"
                          className="btn btn-danger btn-sm"
                          onClick={async () => {
                            setDeleteForceModel(false);
                            setUnitDeployedArray(
                              unitDeployedArray?.filter(
                                (e, i) => i !== deleteForceIndex
                              )
                            );
                            setTotalDeplyed(
                              (prev) =>
                                (prev -=
                                  unitDeployedArray?.[deleteForceIndex].count)
                            );
                            setDeleteForceIndex(-1);
                          }}
                          data-dismiss="modal"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {deleteOtherForceModel && (
              <>
                <div className="modal-backdrop"></div>
                <div
                  className="modal"
                  id="modelId"
                  tabIndex={-1}
                  role="dialog"
                  style={{ display: "block" }}
                  aria-labelledby="modelTitleId"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable modal-sm"
                    role="document"
                  >
                    <div className="modal-content">
                      {/* <div className="modal-header">
                    {
                      (moment(data?.data?.review_date).format("YYYY-MM-DD") <= moment(reviewDtae).format("YYYY-MM-DD"))
                        ? (<h5 className="">Review Deployment</h5>) :
                        (<h5 className="modal-title">Profile {data?.data?.profile.status === "deployed" ? "update" : "deploy"} request</h5>)
                    }

                    <button
                      type="button"
                      className="close text-danger"
                      onClick={() => setDeclineModel(false)}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div> */}
                      <div className="modal-body">
                        Are you sure you want to delete this other force
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light btn-sm"
                          onClick={() => setDeleteOtherForceModel(false)}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          // className="btn btn-primary"
                          className="btn btn-danger btn-sm"
                          onClick={async () => {
                            setDeleteOtherForceModel(false);
                            setOtherDeployedArray(
                              otherDeployedArray?.filter(
                                (e, ii) => ii !== deleteOtherForceIndex
                              )
                            );
                            setDeleteOtherForceIndex(-1);
                          }}
                          data-dismiss="modal"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {editProfileModel && (
              <>
                <div className="modal-backdrop"></div>
                <div
                  className="modal"
                  id="modelId"
                  tabIndex={-1}
                  role="dialog"
                  style={{ display: "block" }}
                  aria-labelledby="modelTitleId"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable modal-lg"
                    role="document"
                  >
                    <div className="modal-content">
                      {/* <button
                          type="button"
                          className="close text-danger"
                          onClick={() => setEditProfileModel(false)}
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button> */}

                      <div className="modal-body">
                        <div className="row mb-2 mt-3">
                          <h2 className="pl-3 text-uppercase">
                            Edit Profile Details
                          </h2>
                        </div>

                        {
                          <>
                            <div className="spacer spacer--sm"></div>
                            <div className="row mb-2">
                              <div className="col-lg-6">
                                <h4>Latitude</h4>
                                <input
                                  type="number"
                                  min={0}
                                  required
                                  className="form-control form-control-sm"
                                  value={profileData.latitude || undefined}
                                  onChange={(e) => {
                                    const val = e.target.value;
                                    setProfileData((prevState) => {
                                      return Object.assign({}, prevState, {
                                        latitude: val,
                                      });
                                    });
                                  }}
                                  placeholder="Enter Latitude"
                                />
                              </div>
                              <div className="col-lg-6">
                                <h4>Longitude</h4>
                                <input
                                  type="number"
                                  min={0}
                                  required
                                  className="form-control form-control-sm"
                                  value={profileData.longitude || undefined}
                                  onChange={(e) => {
                                    const val = e.target.value;
                                    setProfileData((prevState) => {
                                      return Object.assign({}, prevState, {
                                        longitude: val,
                                      });
                                    });
                                  }}
                                  placeholder="Enter Longitude"
                                />
                              </div>

                              <div className="col-lg-6">
                                <h4 className="mt-3">Profile Image</h4>
                                <div className="">
                                  {/* <p className="small mt-0 text-underline">{profileData.document && (<u><a href={API_URL + profileData.document} target="_blank">View selected document</a></u>)}</p> */}

                                  <EFileUpload
                                    onUpload={(url, id) => {
                                      console.log("id", id);
                                      setProfileData((prevState) => {
                                        return Object.assign({}, prevState, {
                                          profile_image: url,
                                        });
                                      });
                                    }}
                                  />
                                  <div
                                    style={{
                                      border: "1px lightgrey dashed",
                                      height: "350px",
                                      width: "100%",
                                    }}
                                    className="rounded bg-light"
                                  >
                                    {profileData?.profile_image !== "" ? (
                                      <img
                                        src={
                                          API_URL + profileData?.profile_image
                                        }
                                        style={{
                                          maxWidth: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "100%",
                                          margin: "0 auto",
                                          objectFit: "contain",
                                        }}
                                        alt=""
                                      />
                                    ) : (
                                      <div className="d-flex h-100 justify-content-center align-items-center">
                                        <PersonFill className="display-2 text-muted" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <h4 className="mt-3">Satellite Image</h4>
                                <div>
                                  <EFileUpload
                                    onUpload={(url, id) => {
                                      setProfileData((prevState) => {
                                        return Object.assign({}, prevState, {
                                          satellite_image: url,
                                        });
                                      });
                                    }}
                                  />
                                  <div
                                    style={{
                                      border: "1px lightgrey dashed",
                                      height: "350px",
                                      width: "100%",
                                    }}
                                    className="rounded bg-light"
                                  >
                                    {profileData?.satellite_image !== "" ? (
                                      <img
                                        src={
                                          API_URL + profileData?.satellite_image
                                        }
                                        style={{
                                          // objectFit: "contain",
                                          maxWidth: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "100%",
                                          margin: "0 auto",
                                        }}
                                        alt=""
                                      />
                                    ) : (
                                      <div className="d-flex h-100 justify-content-center align-items-center">
                                        <PersonFill className="display-2 text-muted" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="spacer spacer--sm"></div>
                          </>
                        }
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light btn-sm"
                          onClick={() => setEditProfileModel(false)}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          // className="btn btn-primary"
                          className="btn btn-primary btn-sm"
                          onClick={async () => {
                            await insertProfile(
                              `/${data?.data?.profile?.id}`,
                              profileData
                            );
                            setEditProfileModel(false);
                          }}
                          data-dismiss="modal"
                        >
                          update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {deltefileModal && (
              <>
                <div className="modal-backdrop"></div>
                <div
                  className="modal"
                  id="modelId"
                  tabIndex={-1}
                  role="dialog"
                  style={{ display: "block" }}
                  aria-labelledby="modelTitleId"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable modal-sm"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-body">
                        Are you sure you want to delete this file
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light btn-sm"
                          onClick={() => setDeltefileModal(false)}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          // className="btn btn-primary"
                          className="btn btn-danger btn-sm"
                          onClick={async () => {
                            if (deleteFileType === "sops") {
                              deletefilesop(deleteFileID);
                            } else {
                              deletefile(deleteFileID);
                            }
                          }}
                          data-dismiss="modal"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </React.Fragment>
        </>
      )}
    </>
  );
}
