import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "use-http";
import {
  Trash,
  DashCircleFill,
  PencilSquare,
  Plus,
  PlusSquare,
} from "react-bootstrap-icons";
import EFileUpload from "../../common/components/EFileUpload/EFileUpload";
import { PATHS } from "../../reducers/routes";
import { API_URL } from "../../index";
import { LoadingScreen } from "../../common/components/LoadingScreen";
import Select from "react-select";
import { FILE_URL } from "../../constant";
function EditAssignment() {
  const UploadRef = React.useRef<HTMLInputElement>(null);

  const resetPer = useRef<any>();
  const securityref = useRef<any>();
  const resetTeam = useRef<any>();

  const { id } = useParams<{ id: string }>();
  const [assignData, setAssignData] = React.useState<any>({
    iscctvchecked: false,
    cctv: 0,
    assignment_by_rank: [],
    security_equipment: [],
    assign_to: null,
    cctv_document: "",
    assignment_documents: [],
    dog_squad: [],
  });

  // const { delete: removedoc, response: deleteres } = useFetch(`/files`, {});
  const { post: removedoc, response: deleteres } = useFetch(
    `${FILE_URL}filedelete`,
    {}
  );

  const { get, data, loading: loadingProfile } = useFetch(
    `/items/assignments/${id}?fields=*.*.*&single=1`,
    {},
    []
  );
  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: equipmentlist } = useFetch(
    `/items/security_equipments_list`,
    {},
    []
  );
  const { data: teams } = useFetch(`/items/teams`, {}, []);
  const { data: ssp } = useFetch(`/items/ssp_profile?fields`, {}, []);
  const { patch: assignrequest, response: assignresponce } = useFetch(
    `/items/assignments`
  );

  const [rankFilterState, setRankFilterState] = useState();
  const [sppState, setSppState] = useState<any>();
  const [equipmentlistState, setEquipmentlistState] = useState<any>();

  const [document, setDocument] = React.useState("");
  const [docName, setDocName] = React.useState("");
  const [fileToUpload, setFileToUpload] = useState<any>();
  const formData = new FormData();
  const [documentId, setDocumentId] = React.useState<number>();

  const [updateModel, setupdateModel] = React.useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = React.useState(-1);

  const [rankdeleteModal, setRankdeleteModal] = React.useState(false);
  const [securityDeleteModal, setSecurityDeleteModal] = React.useState(false);

  const [assignToUser, setAssignToUser] = React.useState<any>();
  const [rankcurr, setRankCurr] = React.useState<number>(-1);
  const [securityCurr, setSecurityCurr] = React.useState<number>(-1);
  const [assignmentByRank, setAssignmentByRank] = React.useState({
    rank: "",
    count: "",
    desployed_by_district: "",
    desployed_by_armed: "",
    allocated: "",
    private_security: false,
    name_private_security: "",
    address_private_security: "",
    assigned_private_security: "",
    contact_private_security: "",
  });

  const [securityEquipment, setSecurityEquipment] = React.useState({
    equipment_name: "",
    name: "",
    eqipment_count: "",
    equipment_deployed: "",
  });

  const [rankAssignment, setRankAssignment] = React.useState<any>([]);
  const [securityEquipmentData, setSecurityEquipmentData] = React.useState<any>(
    []
  );

  const [editRank, setEditRank] = React.useState<any>({
    rank: null,
    count: null,
  });
  const [editSecurity, setEditSecurity] = React.useState<any>({
    equipment_name: null,
    name: null,
    eqipment_count: null,
  });
  const [checkbox, setCheckBox] = React.useState(false);

  const deletefile = async (id) => {
    console.log("id", id);
    const token = localStorage.getItem("token");
    /*  do not change **tokenInput** variable */
    const tokenInput = "qwertyytrewq";
    await removedoc(`${id}?${tokenInput}=${token}`);
    if (deleteres.ok) {
      let newarr = assignData?.assignment_documents?.filter((e) => e.id !== id);
      setAssignData((prevState) => {
        return Object.assign({}, prevState, {
          assignment_documents: newarr,
        });
      });
      alert("File deleted successfully");
    } else {
      alert("Something Went Wrong");
    }
  };

  React.useEffect(() => {
    setAssignData((prevState) => {
      return Object.assign({}, prevState, {
        security_equipment: securityEquipmentData,
      });
    });
  }, [securityEquipmentData]);

  React.useEffect(() => {
    setAssignData((prevState) => {
      return Object.assign({}, prevState, {
        assignment_by_rank: rankAssignment,
      });
    });
  }, [rankAssignment]);

  // const { post } = useFetch("/files?fields=private_hash&t=" + new Date().valueOf(), { cache: "no-cache", });

  const { post } = useFetch(`${FILE_URL}fileupload/` + new Date().valueOf(), {
    cache: "no-cache",
  });

  useEffect(() => {
    let rankOption = rank?.data
      ?.filter(
        (e) => rankAssignment?.findIndex((ele) => ele.rank === e.name) === -1
      )
      ?.map((each, index: any) => each);
    setRankFilterState(rankOption);
  }, [rankAssignment, data]);
  useEffect(() => {
    setRankFilterState(rank?.data);
  }, [rank]);
  useEffect(() => {
    setSppState(ssp?.data);
  }, [ssp]);

  useEffect(() => {
    setEquipmentlistState(equipmentlist?.data);
  }, [equipmentlist]);

  const [dogSquad, setDogSquad] = React.useState({
    name: "",
    count: 0,
    deployee: 0,
  });

  // teams

  // securityEquipmentData
  const [teamData, setTeamData] = React.useState<any>([]);
  //  securityCurr
  const [teamcurr, setTeamCurr] = React.useState<number>(-1);
  // editSecurity
  const [editTeam, setEditTeam] = React.useState<any>({
    team_name: null,
    name: null,
    team_count: null,
  });

  // securityEquipment
  const [team, setTeam] = React.useState({
    team_name: "",
    name: "",
    team_count: "",
    team_deployed: "",
  });
  const [teamList, setTeamList] = useState<any>();

  // teams delete modal

  // securityDeleteModal
  const [teamDeleteModal, setTeamDeleteModal] = React.useState(false);
  // p deleteItemIndex
  const [teamdeleteItemIndex, setTeamDeleteItemIndex] = React.useState(-1);

  React.useEffect(() => {
    setAssignData((prevState) => {
      return Object.assign({}, prevState, {
        team_s: teamData,
      });
    });
  }, [teamData]);
  useEffect(() => {
    setTeamList(teams?.data);
  }, [teams]);

  React.useEffect(() => {
    setAssignData((prev) => ({
      ...prev,
      iscctvchecked: data?.data?.iscctvchecked,
      cctv: data?.data?.cctv,
      assignment_by_rank: data?.data?.assignment_by_rank,
      security_equipment: data?.data?.security_equipment,
      assign_to: data?.data?.assign_to,
      cctv_document: data?.data?.cctv_document,
      assignment_documents: data?.data?.assignment_documents || [],
      dog_squad: data?.data?.dog_squad || [],
      team_s: data?.data?.team_s || [],
    }));
    setRankAssignment(data?.data?.assignment_by_rank);
    setTeamData(data?.data?.team_s);
    setSecurityEquipmentData(data?.data?.security_equipment);
    setRankFilterState(
      rank?.data
        ?.filter(
          (e) =>
            data?.data?.assignment_by_rank?.findIndex(
              (ele) => ele.rank === e.name
            ) === -1
        )
        ?.map((each, index: any) => each)
    );
  }, [data]);
  console.log("data", data);

  const saveData = async () => {
    await setAssignData((prevState) => {
      return Object.assign({}, prevState, {
        dog_squad: [dogSquad],
      });
    });
    await assignrequest(`/${id}`, assignData);
    if (assignresponce.ok) {
      window.location.href = PATHS.assignment;
    } else {
      alert("Something Went Wrong");
    }
  };

  const fetchFile = (id) => {
    const token = JSON.parse(localStorage.getItem("persistantState") || "")
      ?.user?.token;
    /*  do not change **tokenInput** variable */
    const tokenInput = "qwertyytrewq";
    fetch(`${FILE_URL}getfile/${id}?${tokenInput}=${token}`)
      .then((data) => {
        data?.status == 200 && window.open(data?.url, "_blank");
      })
      .catch((error) => alert("error in displaying file"));
  };

  const CCTVJSX = () => {
    return (
      <div className="row">
        <div className="col-lg-3">
          <div className="form-group">
            <h2> Assign CCTV</h2>
            <input
              type="checkbox"
              checked={assignData.iscctvchecked}
              onChange={() => {
                setAssignData((prevState) => {
                  return Object.assign({}, prevState, {
                    iscctvchecked: !assignData.iscctvchecked,
                  });
                });
              }}
            />
            {assignData?.iscctvchecked && (
              <input
                type="number"
                min={0}
                className="form-control form-control-sm"
                value={assignData.cctv}
                onChange={(e) => {
                  const val = e.target.value;
                  setAssignData((prevState) => {
                    return Object.assign({}, prevState, {
                      cctv: val,
                    });
                  });
                }}
                placeholder="0"
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const FORCEJSX = () => {
    return (
      <>
        <div className="row mt-3">
          <h2 className="pl-3">Ranks</h2>
        </div>
        <table className="table has-action-buttons">
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Rank</th>
              <th>Count</th>
              <th></th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* showing data */}
            {console.log("rankAssignment", rankAssignment)}
            {rankAssignment?.map((e, index) => (
              <tr key={index}>
                <td>
                  {/* <select
                    className="form-control form-control-sm"
                    id="exampleFormControlSelect1"
                    disabled={index != rankcurr}
                    onChange={(e) => {
                      let rank = e.target.value;
                      setEditRank({ ...editRank, rank });
                    }}
                  >
                    <option selected disabled>
                      Select
                    </option>
                    {rank?.data?.map((each, index) => (
                      <option
                        key={index}
                        selected={e.rank === each.name}
                      >
                        {each.name}
                      </option>
                    ))}
                  </select> */}

                  <Select
                    defaultValue={rank?.data?.find((r) => r.name === e.rank)}
                    isDisabled={index != rankcurr}
                    onChange={(items) => {
                      if (items) {
                        setEditRank((prevState) => {
                          return Object.assign({}, prevState, {
                            rank: items.name,
                          });
                        });
                      }
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.name}`}
                    options={rankFilterState}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    min={0}
                    className="form-control form-control-sm"
                    value={index != rankcurr ? e.count : editRank.count}
                    disabled={index != rankcurr}
                    placeholder="Enter count"
                    onChange={(e) => {
                      let count = e.target.value;
                      setEditRank({ ...editRank, count });
                    }}
                  />
                </td>
                <td></td>
                <td>
                  <div className="action-buttons">
                    {rankcurr != index ? (
                      <>
                        {/* edit option */}
                        <a
                          onClick={() => {
                            setRankCurr(index);
                            setEditRank(rankAssignment[index]);
                          }}
                          className="btn btn-link"
                        >
                          <PencilSquare />
                        </a>
                        {/* delete option */}
                        <a
                          onClick={() => {
                            setDeleteItemIndex(index);
                            setRankdeleteModal(true);
                            // setRankAssignment(rankAssignment.filter((item, rankIndex) => rankIndex != index))
                          }}
                          className="btn btn-link text-danger"
                        >
                          <DashCircleFill />
                        </a>
                      </>
                    ) : (
                      <>
                        {/* cancel option */}
                        <a
                          onClick={() => {
                            setRankCurr(-1);
                          }}
                          className="btn btn-sm btn-light"
                        >
                          Cancel
                        </a>
                        {/* save option */}
                        <a
                          onClick={() => {
                            // setRankAssignment(rankAssignment.filter((item, rankIndex) => rankIndex != index))
                            let temarr = [...rankAssignment];
                            temarr[index].rank = editRank.rank;
                            temarr[index].count = editRank.count;
                            setRankAssignment(temarr);
                            setEditRank({
                              rank: null,
                              count: null,
                            });
                            setRankCurr(-1);
                          }}
                          className="btn btn-sm btn-success"
                        >
                          Save
                        </a>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          {/* form for rank */}
          <tfoot>
            <tr>
              <td>
                {/* <select
                  className="form-control form-control-sm"
                  id="exampleFormControlSelect1"
                  onChange={(items) => {
                    if (items.target.value) {
                      let val = items.target.value;
                      let selected = rank?.data.find(
                        (value) => value.name == val
                      );
                      let selectedId = selected.name;
                      setAssignmentByRank((prevState) => {
                        return Object.assign({}, prevState, {
                          rank: selectedId,
                        });
                      });
                    }
                  }}
                >
                  <option selected disabled>
                    Select
                  </option>
                  {rank?.data.map((each, index) => (
                    <option key={index}>{each.name}</option>
                  ))}
                </select>
              */}
                {console.log("assignmentByRank", assignmentByRank)}
                <Select
                  ref={resetPer}
                  onChange={(items) => {
                    if (items) {
                      setAssignmentByRank((prevState) => {
                        return Object.assign({}, prevState, {
                          rank: items.name,
                        });
                      });
                    }
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option.name}`}
                  options={rankFilterState}
                />
              </td>
              <td>
                <input
                  type="number"
                  min={0}
                  className="form-control form-control-sm"
                  value={assignmentByRank.count}
                  onChange={(e) => {
                    const val = e.target.value;
                    setAssignmentByRank((prevState) => {
                      return Object.assign({}, prevState, {
                        count: val,
                      });
                    });
                  }}
                  placeholder="Enter count"
                />
              </td>
              <td></td>
              <td>
                <div className="action-buttons">
                  <button
                    disabled={
                      assignmentByRank.rank && assignmentByRank.count
                        ? false
                        : true
                    }
                    onClick={() => {
                      let val = assignmentByRank;
                      setRankAssignment([...rankAssignment, val]);
                      setAssignmentByRank({
                        ...assignmentByRank,
                        rank: "",
                        count: "",
                      });
                      resetPer?.current.select.clearValue();
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    + Add
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>

        {/* Security INfrastructure */}
        <div className="row mt-3">
          <h2 className="pl-3">Security INfrastructure</h2>
        </div>
        <table className="table has-action-buttons">
          <thead>
            <tr>
              <th>INfrastructure</th>
              <th>Name</th>
              <th>Count</th>
              <th></th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* showing data */}
            {console.log("securityEquipmentData", securityEquipmentData)}
            {securityEquipmentData?.map((e, index) => (
              <tr key={index}>
                <td style={{ width: "40%" }}>
                  {/* <input
                    type="text"
                    className="form-control form-control-sm"
                    // value={e.equipment_name}
                    value={
                      index != securityCurr
                        ? e.equipment_name
                        : editSecurity.equipment_name
                    }
                    // disabled={true}
                    disabled={index != securityCurr}
                    placeholder="Enter Name"
                    onChange={(e) => {
                      let equipment_name = e.target.value;
                      setEditSecurity({
                        ...editSecurity,
                        equipment_name,
                      });
                    }}
                  /> */}
                  {console.log("e", e, equipmentlistState)}
                  <Select
                    isDisabled={index != securityCurr}
                    defaultValue={equipmentlistState?.find(
                      (r) =>
                        r?.equipment_name.toLowerCase() ==
                        e?.equipment_name.toLowerCase()
                    )}
                    onChange={(items) => {
                      if (items) {
                        setEditSecurity((prevState) => {
                          return Object.assign({}, prevState, {
                            equipment_name: items.equipment_name,
                          });
                        });
                      }
                    }}
                    getOptionLabel={(option) => `${option.equipment_name}`}
                    getOptionValue={(option) => `${option.equipment_name}`}
                    options={equipmentlist?.data}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    // value={e.eqipment_count}
                    value={index != securityCurr ? e.name : editSecurity.name}
                    // disabled={true}
                    disabled={index != securityCurr}
                    placeholder="Enter Name"
                    onChange={(e) => {
                      let name = e.target.value;
                      setEditSecurity({ ...editSecurity, name });
                    }}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    min={0}
                    className="form-control form-control-sm"
                    // value={e.eqipment_count}
                    value={
                      index != securityCurr
                        ? e.eqipment_count
                        : editSecurity.eqipment_count
                    }
                    // disabled={true}
                    disabled={index != securityCurr}
                    placeholder="0"
                    onChange={(e) => {
                      let eqipment_count = e.target.value;
                      setEditSecurity({
                        ...editSecurity,
                        eqipment_count,
                      });
                    }}
                  />
                </td>
                <td></td>
                <td>
                  <div className="action-buttons">
                    {securityCurr != index ? (
                      <>
                        {/* edit option */}
                        <a
                          onClick={() => {
                            setSecurityCurr(index);
                            setEditSecurity(securityEquipmentData[index]);
                          }}
                          className="btn btn-link"
                        >
                          <PencilSquare />
                        </a>
                        {/* delete option */}
                        <a
                          onClick={() => {
                            setSecurityDeleteModal(true);
                            setDeleteItemIndex(index);
                            // setSecurityEquipmentData(securityEquipmentData.filter((item, rankIndex) => rankIndex != index))
                          }}
                          className="btn btn-link text-danger"
                        >
                          <DashCircleFill />
                        </a>
                      </>
                    ) : (
                      <>
                        {/* cancel option */}
                        <a
                          onClick={() => {
                            setSecurityCurr(-1);
                          }}
                          className="btn btn-sm btn-light"
                        >
                          Cancel
                        </a>
                        {/* save option */}
                        <a
                          onClick={() => {
                            let temarr = [...securityEquipmentData];
                            temarr[index].equipment_name =
                              editSecurity.equipment_name;
                            temarr[index].name = editSecurity.name;
                            temarr[index].eqipment_count =
                              editSecurity.eqipment_count;
                            setSecurityEquipmentData(temarr);
                            setEditSecurity({
                              equipment_name: null,
                              eqipment_count: null,
                            });
                            setSecurityCurr(-1);
                          }}
                          className="btn btn-sm btn-success"
                        >
                          Save
                        </a>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          {/* form for security */}
          <tfoot>
            <tr>
              <td>
                {/* <input
                  type="text"
                  className="form-control form-control-sm"
                  value={securityEquipment.equipment_name}
                  onChange={(e) => {
                    const val = e.target.value;
                    setSecurityEquipment((prevState) => {
                      return Object.assign({}, prevState, {
                        equipment_name: val,
                      });
                    });
                  }}
                  placeholder="Enter Equipment Name"
                /> */}
                <Select
                  ref={securityref}
                  onChange={(items) => {
                    if (items) {
                      setSecurityEquipment((prevState) => {
                        return Object.assign({}, prevState, {
                          equipment_name: items.equipment_name,
                        });
                      });
                    }
                  }}
                  getOptionLabel={(option) => `${option.equipment_name}`}
                  getOptionValue={(option) => `${option.equipment_name}`}
                  options={equipmentlist?.data}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  value={securityEquipment.name}
                  onChange={(e) => {
                    const val = e.target.value;
                    setSecurityEquipment((prevState) => {
                      return Object.assign({}, prevState, {
                        name: val,
                      });
                    });
                  }}
                  placeholder="Enter Name"
                />
              </td>
              <td>
                <input
                  type="number"
                  min={0}
                  className="form-control form-control-sm"
                  value={securityEquipment.eqipment_count}
                  onChange={(e) => {
                    const val = e.target.value;
                    setSecurityEquipment((prevState) => {
                      return Object.assign({}, prevState, {
                        eqipment_count: val,
                      });
                    });
                  }}
                  placeholder="Enter count"
                />
              </td>
              <td></td>
              <td>
                <div className="action-buttons">
                  <button
                    onClick={() => {
                      let val = securityEquipment;
                      setSecurityEquipmentData([...securityEquipmentData, val]);
                      setSecurityEquipment({
                        ...securityEquipment,
                        equipment_name: "",
                        name: "",
                        eqipment_count: "",
                        equipment_deployed: "",
                      });
                      securityref?.current.select.clearValue();
                    }}
                    disabled={
                      securityEquipment.eqipment_count &&
                      securityEquipment.equipment_name
                        ? false
                        : true
                    }
                    className="btn btn-sm btn-primary"
                  >
                    + Add
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>

        {/* Teams */}
        <div className="row mt-3">
          <h3 className="pl-3 font-weight-bold">Teams</h3>
        </div>
        <table className="table has-action-buttons">
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Teams </th>
              <th>Name</th>
              <th>Count</th>
              <th></th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* showing data */}
            {teamData?.map((e, index) => (
              <tr key={index}>
                <td>
                  <Select
                    isDisabled={index != teamcurr}
                    defaultValue={teamList?.find((r) => r.name == e.team_name)}
                    onChange={(items) => {
                      if (items) {
                        setEditTeam((prevState) => {
                          return Object.assign({}, prevState, {
                            team_name: items.name,
                          });
                        });
                      }
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.name}`}
                    options={teams?.data}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    disabled={index != teamcurr}
                    className="form-control form-control-sm"
                    value={index != teamcurr ? e?.name : editTeam?.name}
                    onChange={(e) => {
                      let name = e.target.value;
                      console.log(name);
                      setEditTeam({ ...editTeam, name });
                    }}
                    placeholder="Enter Name"
                  />
                </td>
                {console.log("editTeam", editTeam)}
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    // value={e.eqipment_count}
                    value={
                      index != teamcurr ? e?.team_count : editTeam?.team_count
                    }
                    disabled={index != teamcurr}
                    placeholder="0"
                    onChange={(e) => {
                      let team_count = e.target.value;
                      setEditTeam({ ...editTeam, team_count });
                    }}
                  />
                </td>
                <td></td>
                <td>
                  <div className="action-buttons">
                    {teamcurr != index ? (
                      <>
                        {/* edit option */}
                        <a
                          onClick={() => {
                            setTeamCurr(index);
                            setEditTeam(teamData[index]);
                          }}
                          className="btn btn-link"
                        >
                          <PencilSquare />
                        </a>
                        {/* delete option */}
                        <a
                          onClick={() => {
                            setTeamDeleteModal(true);
                            setTeamDeleteItemIndex(index);
                            // setSecurityEquipmentData(securityEquipmentData.filter((item, rankIndex) => rankIndex != index))
                          }}
                          className="btn btn-link text-danger"
                        >
                          <DashCircleFill />
                        </a>
                      </>
                    ) : (
                      <>
                        {/* cancel option */}
                        <a
                          onClick={() => {
                            setTeamCurr(-1);
                          }}
                          className="btn btn-sm btn-light"
                        >
                          Cancel
                        </a>
                        {/* save option */}
                        <a
                          onClick={() => {
                            let temarr = [...teamData];
                            temarr[index].team_name = editTeam.team_name;
                            temarr[index].name = editTeam.name;
                            temarr[index].team_count = editTeam.team_count;
                            setTeamData(temarr);
                            setEditTeam({ team_name: null, team_count: null });
                            setTeamCurr(-1);
                          }}
                          className="btn btn-sm btn-success"
                        >
                          Save
                        </a>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          {/* form for security */}
          <tfoot>
            <tr>
              <td>
                <Select
                  ref={resetTeam}
                  onChange={(items) => {
                    if (items) {
                      console.log("items", items);
                      setTeam((prevState) => {
                        return Object.assign({}, prevState, {
                          team_name: items.name,
                        });
                      });
                    }
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option.name}`}
                  options={teams?.data}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  value={team.name}
                  onChange={(e) => {
                    const val = e.target.value;
                    setTeam((prevState) => {
                      return Object.assign({}, prevState, {
                        name: val,
                      });
                    });
                  }}
                  placeholder="Enter Name"
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  value={team.team_count}
                  onChange={(e) => {
                    const val = e.target.value;
                    setTeam((prevState) => {
                      return Object.assign({}, prevState, {
                        team_count: val,
                      });
                    });
                  }}
                  placeholder="Enter count"
                />
              </td>
              <td></td>
              <td>
                <div className="action-buttons">
                  <button
                    onClick={() => {
                      let val = team;
                      setTeamData([...teamData, val]);
                      setTeam({
                        ...team,
                        team_name: "",
                        team_count: "",
                        team_deployed: "",
                      });
                      resetTeam?.current.select.clearValue();
                    }}
                    disabled={team.team_count && team.team_name ? false : true}
                    className="btn btn-sm btn-primary"
                  >
                    + Add
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>

        {/* <div className="row mt-3">
          <h2 className="pl-3">Dog Squad</h2>  {/* <div className="row">
          <div className="col-lg-4">
            <label className="bold h4">Name</label>
            <input
              value={assignData?.dog_squad[0]?.name}
              onChange={(e) => {
                let val = e.target.value;
                // setDogSquad((prev) => ({ ...prev, name: val }))
                setAssignData((prevState) => {
                  return Object.assign({}, prevState, {
                    dog_squad: [{
                      name: val,
                      count: assignData?.dog_squad[0]?.count,
                      deployee: assignData?.dog_squad[0]?.deployee || 0
                    }],
                  });
                })
              }}
              type="text" className="form-control form-control-sm" />
          </div>
          <div className="col-lg-4">
            <label className="bold h4">Count</label>
            <input value={assignData?.dog_squad[0]?.count} onChange={(e) => {
              let val = Number(e.target.value);
              // setDogSquad((prev) => ({ ...prev, count: val }))

              setAssignData((prevState) => {
                return Object.assign({}, prevState, {
                  dog_squad: [{
                    count: val,
                    name: assignData?.dog_squad[0]?.name,
                    deployee: assignData?.dog_squad[0]?.deployee || 0
                  }],
                });
              })
            }}
              type="Number" className="form-control form-control-sm" />
          </div>

        </div>
        </div> */}
        {/* <div className="row d-flex justify-content-center align-items-center"> */}
        {/* */}
      </>
    );
  };

  const { data: userData } = useFetch(`/users?filter[role]=3`, {}, []);

  console.log("userData", userData);
  return (
    <>
      {loadingProfile ? (
        <LoadingScreen />
      ) : (
        <div>
          <React.Fragment>
            <div className="container-fluid">
              <div className="row pt-5">
                <div className="col-lg-6">
                  <h1 className="pl-3">Edit Assignment</h1>
                </div>

                <div className="col-lg-6 d-flex justify-content-end">
                  <a className="btn btn-light mr-2" href={PATHS.assignment}>
                    Cancel
                  </a>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      // saveData();
                      setupdateModel(true);
                    }}
                  >
                    Update
                  </button>
                </div>
                <div className="col-lg-12 mt-4 text-uppercase">
                  <div className="card p-4 mb-5">
                    {/* 
                            
                      there are 3 type of profile for each VP .
                      1:CCTV
                      2:CCTV AND DEPLOYED FORCE
                      3:DEPLOYED FORCE
                    
                    */}

                    {/* {data?.data?.profile?.type?.name === "CCTV" ? (CCTVJSX()) : null}
                    {data?.data.profile.type.name === "CCTV AND DEPLOYED FORCE" ? (<>{CCTVJSX()}{FORCEJSX()} </>) : null}
                    {data?.data.profile.type.name === "DEPLOYED FORCE" ? (FORCEJSX()) : null} */}

                    {/* {CCTVJSX()} */}

                    {FORCEJSX()}
                    <div className="row mt-3">
                      {/* <h1 className="pl-3">Assign To SSP</h1> */}
                      <h2 className="pl-3">Assign To Unit</h2>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-4">
                        {/* <label>
                          <b>SSP BY DISTRICT</b>{" "}
                        </label> */}
                        <select
                          className="form-control form-control-sm"
                          id="exampleFormControlSelect1"
                          onChange={(items) => {
                            if (items.target.value) {
                              let val = items.target.value;
                              let selected = userData?.data?.find(
                                (value) => value.first_name == val
                              );
                              console.log("selected", selected);
                              let selectedId = selected?.id;
                              setAssignData((prevState) => {
                                return Object.assign({}, prevState, {
                                  assign_to: selectedId,
                                });
                              });
                            }
                          }}
                        >
                          <option disabled selected>
                            Select
                          </option>
                          {userData?.data?.map((each, index) => (
                            <option
                              selected={each?.id === assignData.assign_to}
                              key={index}
                            >
                              {each.first_name}
                            </option>
                          ))}
                        </select>
                        {console.log("assignData", assignData)}
                      </div>
                    </div>
                    <div className=" mt-4">
                      <h2 className="">Upload Documents</h2>
                    </div>
                    {assignData.assignment_documents.length < 5 ? (
                      <div className="row p-4 bg-light m-1">
                        <div className="col-lg-3 pl-0 pr-0">
                          {/* <input accept="application/pdf" type="file" ref={UploadRef} onChange={async (e: any) => {
                            const data = new FormData();
                            let file = e?.currentTarget?.files?.item(0);
                            if (file && file.size < 2000000) {
                              data.append("file", e?.currentTarget?.files?.item(0))
                              const res = await post(data);
                              setDocument(`_/assets/${res?.data?.private_hash}`);
                              setDocumentId(res?.data?.id);
                            } else {
                              alert("Please select file less than 2MB")
                            }
                          }} /> */}

                          <input
                            accept="application/pdf"
                            type="file"
                            ref={UploadRef}
                            onChange={async (e: any) => {
                              const file = new FormData();

                              let fileObject = e?.currentTarget?.files?.item(0);
                              if (fileObject && fileObject.size < 2000000) {
                                setFileToUpload(
                                  e?.currentTarget?.files?.item(0)
                                );
                                // file.append(
                                //   "myFile",
                                //   e?.currentTarget?.files?.item(0)
                                // );
                                // const token = JSON.parse(
                                //   localStorage.getItem("persistantState") || ""
                                // )?.user?.token;
                                // /*  do not change **tokenInput** variable if then change node backend is also required*/
                                // const tokenInput = "qwertyytrewq";
                                // const res = await post(
                                //   `?${tokenInput}=${token}`,
                                //   file
                                // );
                                // if (res.error) {
                                //   alert(
                                //     "Unauthorized user request login again to submit file"
                                //   );
                                // } else {
                                //   setDocumentId(res?.data?._id);
                                // }
                              } else {
                                alert("Please select file less than 2MB");
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-5">
                          <input
                            type="text"
                            value={docName}
                            placeholder="Enter Document Name "
                            className="form-control form-control-sm"
                            onChange={(e) => {
                              let val = e.target.value;
                              setDocName(val);
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <button
                            disabled={
                              docName == "" || UploadRef.current!.value == ""
                                ? true
                                : false
                            }
                            style={{ fontSize: "1.1rem" }}
                            onClick={async () => {
                              formData.append("myFile", fileToUpload);
                              formData.append("docname", docName);
                              formData.append(
                                "vpname",
                                data?.data?.profile?.name
                              );
                              const token = JSON.parse(
                                localStorage.getItem("persistantState") || ""
                              )?.user?.token;
                              const tokenInput = "qwertyytrewq";
                              const res = await post(
                                `?${tokenInput}=${token}`,
                                formData
                              );
                              if (res?.error) {
                                alert(
                                  "Unauthorized user request login again to submit file"
                                );
                              } else {
                                setDocumentId(res?.data?._id);
                                let temp = [...assignData.assignment_documents];
                                temp.push({
                                  url: document,
                                  name: docName,
                                  id: res?.data?._id,
                                });
                                setAssignData((prevState) => {
                                  return Object.assign({}, prevState, {
                                    assignment_documents: temp,
                                  });
                                });
                                setDocName("");
                                UploadRef.current!.value = "";
                              }
                            }}
                            className="btn btn-primary btn-sm"
                          >
                            Add +
                          </button>
                        </div>
                        <div className="col-lg-12 p-0 m-0">
                          <small className="form-text text-muted">
                            You can only upload 5 documents
                          </small>
                        </div>
                      </div>
                    ) : (
                      <h4 className=" pl-3">You can only select 5 documents</h4>
                    )}
                    <div className="row">
                      <div className="col-lg-6 pl-0 mt-3 mb-4 ml-3">
                        {/* <p className="small mt-0 text-underline">{profileData.document && (<u><a href={API_URL + profileData.document} target="_blank">View selected document</a></u>)}</p> */}
                        <span className="mt-2 ml-0 pl-0 text-muted font-weight-bold">
                          Documents
                        </span>
                        <ul className=" pl-0  ">
                          {assignData?.assignment_documents?.map((e) => (
                            <li
                              className="nav bg-light my-1 col-lg-12 pr-2 d-flex align-items-center"
                              style={{ textDecoration: "underline" }}
                            >
                              <div className="col-lg-11 col-md-11">
                                {/* <a href={API_URL + e?.url} className="" target="_blank">{e?.name}</a> */}
                                <div
                                  className="text-secondary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    fetchFile(e?.id);
                                  }}
                                >
                                  {e?.name}
                                </div>
                              </div>
                              <div className="col-lg-1 col-md-1">
                                <button
                                  className="btn-sm btn"
                                  onClick={async () => {
                                    deletefile(e?.id);
                                  }}
                                >
                                  <Trash
                                    className="h3 text-danger"
                                    style={{ marginTop: "8px" }}
                                  />
                                </button>
                              </div>
                            </li>
                          ))}
                          {assignData.assignment_documents.length <= 0 && (
                            <p
                              className="text-muted small p-2 rounded"
                              style={{ border: "1px #999 dashed" }}
                            >
                              Upload documents to view
                            </p>
                          )}
                        </ul>
                      </div>
                      <div className="col-lg-6"></div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <a
                          className="btn btn-light mr-2"
                          href={PATHS.assignment}
                        >
                          Cancel
                        </a>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            // saveData();
                            setupdateModel(true);
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-12">
                      <a className="btn btn-light mr-2" href={PATHS.assignment}>
                        Cancel
                      </a>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          // saveData();
                          setupdateModel(true);
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            {updateModel && (
              <>
                <div className="modal-backdrop"></div>
                <div
                  className="modal"
                  id="modelId"
                  tabIndex={-1}
                  role="dialog"
                  style={{ display: "block" }}
                  aria-labelledby="modelTitleId"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable modal-sm"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-body">
                        Are you sure you want to update this assignment?
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light btn-sm"
                          onClick={() => setupdateModel(false)}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={async () => {
                            saveData();
                          }}
                          data-dismiss="modal"
                        >
                          update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {rankdeleteModal && (
              <>
                <div className="modal-backdrop"></div>
                <div
                  className="modal"
                  id="modelId"
                  tabIndex={-1}
                  role="dialog"
                  style={{ display: "block" }}
                  aria-labelledby="modelTitleId"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable modal-sm"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-body">
                        Are you sure you want to delete this rank?
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light btn-sm"
                          onClick={() => setRankdeleteModal(false)}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={async () => {
                            setRankAssignment(
                              rankAssignment.filter(
                                (item, rankIndex) =>
                                  rankIndex != deleteItemIndex
                              )
                            );
                            setDeleteItemIndex(-1);
                            setRankdeleteModal(false);
                          }}
                          data-dismiss="modal"
                        >
                          delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {securityDeleteModal && (
              <>
                <div className="modal-backdrop"></div>
                <div
                  className="modal"
                  id="modelId"
                  tabIndex={-1}
                  role="dialog"
                  style={{ display: "block" }}
                  aria-labelledby="modelTitleId"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-scrollable modal-sm"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-body">
                        Are you sure you want to delete this security equipment?
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light btn-sm"
                          onClick={() => setSecurityDeleteModal(false)}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={async () => {
                            // setRankAssignment(rankAssignment.filter((item, rankIndex) => rankIndex != deleteItemIndex));
                            setSecurityEquipmentData(
                              securityEquipmentData.filter(
                                (item, rankIndex) =>
                                  rankIndex != deleteItemIndex
                              )
                            );
                            setDeleteItemIndex(-1);
                            setSecurityDeleteModal(false);
                          }}
                          data-dismiss="modal"
                        >
                          delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </React.Fragment>
        </div>
      )}
    </>
  );
}

export default EditAssignment;
