import { spawn } from "child_process";
import { createSecretKey } from "crypto";
import React, { useEffect, useState, useRef } from "react";
import {
  FileArrowDown,
  FileEarmarkTextFill,
  Pencil,
} from "react-bootstrap-icons";
import { useFetch } from "use-http";
import DistrictVp from "./districtVp";
import { CUSTOM_URL } from "../..";
import Paid from "./paid";
import TotalForces from "./totalForces";
import TotalVp from "./totalVp";
import VpCategories from "./vpCategories";
import { useReactToPrint } from "react-to-print";
import ForcesDeployment from "./forcesDeployment";
import { LoadingScreen } from "../../common/components/LoadingScreen";
import { useHistory } from "react-router-dom";



function Reports() {
  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);


  const history = useHistory();

  /* state for forces */
  const [forceState, setForceState] = useState<any>([]);
  const [rankState, setRankState] = useState<any>([]);
  const [otherforceState, setOtherforceState] = useState<any>([]);
  const [battalionState, setbattalionState] = useState<any>([]);
  /* state for forces */

  const { data: forceDeployment } = useFetch(
    `${CUSTOM_URL}/directus/custom/forceDeployment`,
    {},
    []
  );
  // const [forceDeploymentState, setForceDeploymentState] = useState<any>([]);

  // useEffect(() => {
  //   setRankState(rank?.data);
  // }, [rank]);

  // useEffect(() => {
  //   setbattalionState(battalion?.data);
  // }, [battalion]);

  // useEffect(() => {
  //   setOtherforceState(otherforce?.data);
  // }, [otherforce]);

  // useEffect(() => {
  //   setForceState(force?.data);
  // }, [force]);

  // useEffect(() => {
  //   const arr: any = [];

  //   // removing slashes from string
  //   forceDeployment?.data?.map((force) => {
  //     let str = force?.force_deployemnt.replace(/\\|\//g, "");
  //     arr.push(JSON.parse(str));
  //   });
  //   // console.log(arr)

  //   // a have change name of force from number to string
  //   let a = arr?.map((e: any) =>
  //     e?.map((ee: any) => {
  //       ee.force = forceState && forceState[Number(ee?.force)]?.name;
  //       ee.battalion =
  //         battalionState && battalionState[Number(ee?.battalion)]?.name;
  //       ee.rank = rankState && rankState[Number(ee?.rank)]?.name;
  //       return ee;
  //     })
  //   );
  //   // console.log("a", a)
  //   setForceDeploymentState(a);
  // }, [forceDeployment, forceState, battalionState]);

  // useEffect(() => {
  //   let arr: any = [];
  //   forceDeploymentState.map((each) => {
  //     each.map((e) => {
  //       forceState?.map((f) => {
  //         let count = 0;
  //         if (e.force == f.name) {
  //           count += Number(e.count);
  //           arr.push({
  //             count: count,
  //             force: f?.name,
  //             rank: e.rank,
  //           });
  //         }
  //       });
  //     });
  //   });
  //   setDataToshow(arr);
  // }, [forceDeploymentState]);

  const [datatoShow, setDataToshow] = useState<any>([]);

  // //  group by functions
  // const groupByKey = (list, key) =>
  //   list?.reduce(
  //     (hash, obj) => ({
  //       ...hash,
  //       [obj[key]]: (hash[obj[key]] || []).concat(obj),
  //     }),
  //     {}
  //   );

  // const [data2shaow, setData2shaow] = useState<any>([]);
  // const [key, setKey] = useState<any>([]);
  // const [byRank, setbyRank] = useState<any>([]);

  //
  // useEffect(() => {
  //   let arr: any = [];
  //   forceState?.map((e) => {
  //     let count = 0;
  //     let rank = "";
  //     datatoShow?.map((f) => {
  //       if (f.force == e.name) {
  //         // count += Number(f.count);
  //         rank = f.rank;
  //         arr.push({
  //           count: f.count,
  //           force: e?.name,
  //           rank: rank,
  //         });
  //       }
  //     });
  //   });

  //   let a = groupByKey(arr, "force");
  //   let key = Object.keys(groupByKey(arr, "force"));
  //   setKey(key);

  //   // let arrr for rank wise sort

  //   let newarr: any = [];
  //   key?.map((e) => {
  //     newarr.push(groupByKey(a[e], "rank"));
  //   });
  //   setbyRank(newarr);
  //   setData2shaow(groupByKey(arr, "force"));
  // }, [datatoShow]);

  // // console.log("datatoShow", byRank)

  // const [lastDataFininsh, setlastDataFininsh] = useState<any>([]);
  // // sum by keys

  // // final arrr for mapping
  // useEffect(() => {
  //   let finalArry: any = [];
  //   byRank?.map((r) => {
  //     let byRankNew: any = [];
  //     let key = Object.keys(r);
  //     let fRank = "";
  //     key?.map((k) => {
  //       let count = 0;
  //       let force = "";
  //       let rank = "";
  //       r[k].map((each) => {
  //         count += each.count;
  //         force = each.force;
  //         rank = k;
  //       });
  //       fRank = force;
  //       byRankNew.push({ count: count, force: force, rank: rank });
  //     });
  //     finalArry.push({ force: fRank, data: byRankNew });
  //     // console.log("byRankNew", byRankNew)
  //   });
  //   // console.log("finalarry", finalArry);

  //   setlastDataFininsh(finalArry);
  // }, [byRank]);

  // console.log("finalarry lastDataFininsh", lastDataFininsh);

  const [selectedReport, setSelectedReport] = useState(1);

  // const componentRef = useRef<any>();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });


  if (forceDeployment == undefined) {
    return <LoadingScreen />;
  } else {
    return (
      <>
        <div className="container-fluid">
          <div className="row ml-2">
            <div className="col-lg-12 py-5" style={{ maxWidth: "95vw" }}>
              <h1 className="mb-4">VP REPORTS</h1>
              <div className="row text-uppercase ">
                <div className="col-lg-4 col-md-2 btn text-uppercase text-muted">
                  <div
                    onClick={() => history.push('/reports/districtvp')}
                    className={`card p-4 d-flex align-items-center justify-content-center flex-column`}
                    style={{ border: "2px solid transparent" }}
                  >
                    <FileEarmarkTextFill color="blue" fontSize="2em" className="mb-2" />
                    <h4>STATIC GUARD ON GOVT BUILDING IN PUNJAB</h4>
                  </div>
                </div>

                <div className="col-lg-4 col-md-2 btn text-muted">
                  <div
                    onClick={() => history.push('/reports/totalvp')}
                    className={`card p-4 d-flex align-items-center justify-content-center flex-column `}
                    style={{
                      border: "2px solid transparent",
                    }}
                  >
                    <FileEarmarkTextFill color="blue" fontSize="2em" className="mb-2" />
                    <h4>Summary of forces deployed on vp Categories in punjab ,chandigarh and delhi</h4>
                  </div>
                </div>

                <div className="col-lg-4 col-md-2  btn text-muted">
                  <div onClick={() => history.push('/reports/vpcategories')}
                    className={`card p-4 d-flex align-items-center justify-content-center flex-column }`}
                    style={{
                      border: "2px solid transparent",
                    }}
                  >
                    <FileEarmarkTextFill color="blue" fontSize="2em" className="mb-2" />
                    {/* vp */}
                    <h4 >TOTAL NUMBER OF FORCES DEPLOYEMENT ON VULNERABLE POINTS</h4>
                  </div>
                </div>




                {/* <div className="col-lg-2  btn">
                <div
                  onClick={() => setSelectedReport(4)}
                  className={`card p-4 d-flex align-items-center justify-content-center flex-column ${selectedReport == 4 && "border-primary"
                    }`}
                  style={{
                    border: "2px solid transparent",
                  }}
                >
                  <FileEarmarkTextFill color="blue" fontSize="2em" className="mb-2" />
                  <h4>Total Forces</h4>
                </div>
              </div> */}
                <div className="col-lg-4 col-md-2 btn text-muted">
                  <div
                    onClick={() => history.push('/reports/paid')}
                    className={`card p-4 d-flex align-items-center justify-content-center flex-column `}
                    style={{
                      border: "2px solid transparent",
                    }}
                  >
                    <FileEarmarkTextFill color="blue" fontSize="2em" className="mb-2" />
                    <h4>Static guard on Payment Basis in Punjab </h4>
                  </div>
                </div>

                <div className="col-lg-4 col-md-2 btn text-muted">
                  <div onClick={() => history.push('/reports/forcesdeployment')}
                    className={`card p-4 d-flex align-items-center justify-content-center flex-column `}
                    style={{
                      border: "2px solid transparent",
                    }}
                  >
                    <FileEarmarkTextFill color="blue" fontSize="2em" className="mb-2" />
                    <h4>All Forces Deployment </h4>
                  </div>
                </div>


                <div className="col-lg-4 col-md-2 btn text-muted">
                  <div onClick={() => history.push('/reports/rangewise')}
                    className={`card p-4 d-flex align-items-center justify-content-center flex-column `}
                    style={{ border: "2px solid transparent", }}>
                    <FileEarmarkTextFill color="blue" fontSize="2em" className="mb-2" />
                    <h4>Range wise </h4>
                  </div>
                </div>
              </div>
              {/* {selectedReport == 1 && <DistrictVp />}
              {selectedReport == 2 && <TotalVp />}
              {selectedReport == 3 && <VpCategories />} */}
              {/* {selectedReport == 4 && <TotalForces />} */}
              {/* {selectedReport == 5 && <Paid />} */}
              {/* {selectedReport == 6 && <ForcesDeployment />} */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Reports;
