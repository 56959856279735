import React, { useRef, useEffect, useState } from "react";
import { FileEarmarkArrowDown } from "react-bootstrap-icons";
import { API_URL } from "../..";
import { useFetch } from "use-http";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { IRoute, IUser } from "../../reducers/types";
import { FILE_URL } from "../../constant";
interface Props {
  profileData: any;
}

function ViewVpProfile({ profileData }: Props) {
  console.log("profileData", profileData);
  const [user]: [IUser] = useSelector((state: any) => [state.user.user]);
  const { data: rank } = useFetch(`/items/rank`, {}, []);
  const { data: force } = useFetch(`items/force`, {}, []);
  const { data: otherforce } = useFetch(`items/other_force`, {}, []);
  const { data: battalion } = useFetch(`items/battalion`, {}, []);
  const { data: ranges } = useFetch(`items/ranges`, {}, []);

  const [showImage, setShowImage] = useState<string>("");
  const [showSatelliteImage, setShowSatelliteImage] = useState<string>("");

  // console.log()

  const fetchFile = (id, type) => {
    const token = JSON.parse(localStorage.getItem("persistantState") || "")
      ?.user?.token;
    /*  do not change **tokenInput** variable */
    const tokenInput = "qwertyytrewq";
    fetch(`${FILE_URL}getfile/${id}?${tokenInput}=${token}`)
      .then((data) => {
        if (type == "imgProfile") {
          setShowImage(data?.url);
        } else if (type == "imgSatellite") {
          setShowSatelliteImage(data?.url);
        } else {
          data?.status == 200 && window.open(data?.url, "_blank");
        }
      })
      .catch((error) => alert("error in displaying file"));

    // fetch(`http://live.espranza.in:6063/files/${id}?${tokenInput}=${token}`)
    //   .then((data) => {
    //     data?.status == 200 && window.open(data?.url, '_blank')
    //   }).catch(() => alert("error in displaying file"))
  };

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (profileData?.data?.profile?.profile_image) {
      fetchFile(profileData?.data?.profile?.profile_image, "imgProfile");
    }
    if (profileData?.data?.profile?.satellite_image) {
      fetchFile(profileData?.data?.profile?.satellite_image, "imgSatellite");
    }
  }, [profileData]);

  useEffect(() => {}, [showImage, showSatelliteImage]);

  console.log("ranges", ranges);

  // console.log("profileData", profileData)

  return (
    <div ref={componentRef}>
      <div className="row px-2">
        <div className="col-lg-6  pt-3 pb-1">
          <h1>Profile Details</h1>
        </div>
        {user?.role === "1" && (
          <div className="d-print-none col-lg-6 pt-3 pb-1 ">
            <button
              className=" btn btn-sm btn-primary float-right"
              onClick={handlePrint}
            >
              PRINT PROFILE
            </button>
          </div>
        )}
      </div>
      <div className="row mx-1 text-uppercase">
        <div className="col-lg-12 card">
          <div className="row ">
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <div className="row px-4 pt-4 pb-2">
                    <div className="col-lg-6 pl-0">
                      <p>
                        {" "}
                        <b>Name</b> :{" "}
                        {profileData?.data?.profile?.name ? (
                          profileData?.data?.profile?.name
                        ) : (
                          <span className="text-muted">Not Available</span>
                        )}
                      </p>
                    </div>
                    <div className="col-lg-6 pl-0">
                      <p>
                        {" "}
                        <b>Address</b> :{" "}
                        {profileData?.data?.profile?.address ? (
                          profileData?.data?.profile?.address
                        ) : (
                          <span className="text-muted">Not Available</span>
                        )}
                      </p>
                    </div>
                    <div className="col-lg-6 pl-0">
                      <p>
                        {" "}
                        <b> City</b> :{" "}
                        {profileData?.data?.profile?.city ? (
                          profileData?.data?.profile?.city
                        ) : (
                          <span className="text-muted">Not Available</span>
                        )}{" "}
                      </p>
                    </div>
                    <div className="col-lg-6 pl-0">
                      <p>
                        {" "}
                        <b>District</b> :{" "}
                        {profileData?.data?.profile?.district ? (
                          profileData?.data?.profile?.district
                        ) : (
                          <span className="text-muted">Not Available</span>
                        )}
                      </p>
                    </div>
                    <div className="col-lg-6 pl-0">
                      <p>
                        {" "}
                        <b>State </b> :{" "}
                        {profileData?.data?.profile?.state ? (
                          profileData?.data?.profile?.state
                        ) : (
                          <span className="text-muted">Not Available</span>
                        )}{" "}
                      </p>
                    </div>
                    <div className="col-lg-6 pl-0">
                      <p>
                        {" "}
                        <b>Pincode</b> :{" "}
                        {profileData?.data?.profile?.pincode ? (
                          profileData?.data?.profile?.pincode
                        ) : (
                          <span className="text-muted">Not Available</span>
                        )}{" "}
                      </p>
                    </div>
                    <div className="col-lg-6 pl-0">
                      <p>
                        <b>Latitude</b> :{" "}
                        {profileData?.data?.profile?.latitude ? (
                          profileData?.data?.profile?.latitude
                        ) : (
                          <span className="text-muted">Not Available</span>
                        )}
                      </p>
                    </div>
                    <div className="col-lg-6 pl-0">
                      <p>
                        <b>Longitude</b> :{" "}
                        {profileData?.data?.profile?.longitude ? (
                          profileData?.data?.profile?.longitude
                        ) : (
                          <span className="text-muted">Not Available</span>
                        )}
                      </p>
                    </div>
                    <div className="col-lg-6 pl-0">
                      <p>
                        {" "}
                        <b> Category :</b>{" "}
                        <span>
                          {profileData?.data?.profile?.category?.name ? (
                            profileData?.data?.profile?.category?.name
                          ) : (
                            <span className="text-muted">Not Available</span>
                          )}
                        </span>{" "}
                      </p>
                    </div>
                    {profileData?.data?.profile?.vp_category?.name && (
                      <div className="col-lg-6 pl-0">
                        <p>
                          {" "}
                          <b> VP Category : </b>{" "}
                          {profileData?.data?.profile?.vp_category?.name ? (
                            profileData?.data?.profile?.vp_category?.name
                          ) : (
                            <span className="text-muted">Not Available</span>
                          )}{" "}
                        </p>
                      </div>
                    )}
                    {profileData?.data?.profile?.bank_category?.name && (
                      <div className="col-lg-6 pl-0">
                        <p>
                          {" "}
                          <b> Bank Category : </b>{" "}
                          {profileData?.data?.profile?.bank_category?.name ? (
                            profileData?.data?.profile?.bank_category?.name
                          ) : (
                            <span className="text-muted">Not Available</span>
                          )}{" "}
                        </p>
                      </div>
                    )}
                    {/* <div className="col-lg-6 pl-0" >
                                            <p> <b>Type:</b> {profileData?.data?.profile?.type?.name ? profileData?.data?.profile?.type?.name : <span className="text-muted">Not Available</span>}</p>
                                        </div> */}
                    <div className="col-lg-6 pl-0">
                      <p>
                        <b>Range : </b>{" "}
                        {
                          ranges?.data?.find(
                            (value) =>
                              value?.id == profileData?.data?.profile?.range?.id
                          )?.name
                        }
                      </p>
                    </div>
                    <div className="col-lg-6 pl-0">
                      <p>
                        {" "}
                        <b>Paid:</b>{" "}
                        {profileData?.data?.profile?.paid == false
                          ? "No"
                          : "Yes"}
                      </p>
                    </div>
                    <div className="col-lg-6 pl-0">
                      <p>
                        {" "}
                        <b>assigned / deployed: </b>
                        {profileData?.data?.assignment_by_rank?.reduce(
                          (acc, { count }) => acc + Number(count),
                          0
                        )}{" "}
                        /{" "}
                        {profileData?.data?.force_deployemnt?.reduce(
                          (acc, { count }) => acc + Number(count),
                          0
                        ) || 0}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* map view */}
            <div className="col-md-4">
              <div className="">
                <div className="">
                  {profileData?.data?.profile?.profile_image ? (
                    <img
                      style={{
                        objectFit: "contain",
                        // width: "200px",
                        height: "260px",
                        maxWidth: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: "100%",
                        margin: "0 auto",
                      }}
                      src={showImage}
                      alt=""
                    />
                  ) : (
                    <p
                      style={{
                        height: "260px",
                      }}
                      className="d-flex text-muted small justify-content-center align-items-center"
                    >
                      No image uploaded
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-1 pt-3 text-uppercase">
        <div className="col-lg-12 card p-4">
          <div className="col-lg-12 pb-1 pl-0">
            <h2 className="text-uppercase">Satellite Image</h2>
          </div>
          {showSatelliteImage ? (
            <div style={{ height: "300px" }}>
              <img
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  // height: "100%",
                  // objectFit: "fill",
                  margin: "0 auto",
                }}
                src={showSatelliteImage}
                alt=""
                height="300px"
              />
            </div>
          ) : (
            <p
              style={{
                height: "260px",
              }}
              className="text-muted small d-flex justify-content-center align-items-center"
            >
              No image uploaded
            </p>
          )}
        </div>
      </div>

      <div className="row mx-1 mt-2 pt-3">
        <div className="col-lg-12 card p-4 mb-2">
          <div className="col-lg-12 pb-1 pl-0 text-uppercase">
            <h2>Documents Uploaded</h2>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Profile</th>
                <th>SOP'S</th>
                <th>Assignment</th>
                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  {(profileData?.data?.profile?.profile_documents == null ||
                    profileData?.data?.profile?.profile_documents.length <=
                      0) && (
                    <li className="d-flex py-1 text-secondary">
                      <span className="text-muted">Not Available</span>
                    </li>
                  )}
                  {profileData?.data?.profile?.profile_documents?.map(
                    (doc, i) => (
                      <li key={i} className="d-flex py-1 text-secondary">
                        <FileEarmarkArrowDown fontSize="20px" />
                        <div
                          className="text-secondary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            fetchFile(doc?.id, "docs");
                            // fetchFile(doc?.url)
                          }}
                        >
                          {doc.name}
                        </div>

                        {/* <a href={API_URL + doc?.url} className="text-secondary" target="_blank">{doc.name} /{doc?.id}</a> */}
                      </li>
                    )
                  )}
                </td>
                <td
                  style={{
                    verticalAlign: "top",
                  }}
                >
                  {(profileData?.data?.profile?.sops == null ||
                    profileData?.data?.profile?.sops.length <= 0) && (
                    <li className="d-flex py-1 text-secondary">
                      <span className="text-muted">Not Available</span>
                    </li>
                  )}
                  {profileData?.data?.profile?.sops?.map((doc, i) => (
                    <li key={i} className="d-flex py-1 text-secondary">
                      <FileEarmarkArrowDown fontSize="20px" />
                      <div
                        className="text-secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          fetchFile(doc?.id, "docs");
                        }}
                      >
                        {doc.name}
                      </div>

                      {/* <a href={API_URL + doc?.url} className="text-secondary" target="_blank">{doc.name}</a> */}
                    </li>
                  ))}
                </td>
                <td
                  style={{
                    verticalAlign: "top",
                  }}
                >
                  {(profileData?.data?.assignment_documents == null ||
                    profileData?.data?.assignment_documents.length <= 0) && (
                    <li className="d-flex py-1 text-secondary">
                      <span className="text-muted">Not Available</span>
                    </li>
                  )}
                  {profileData?.data?.assignment_documents?.map((doc, i) => (
                    <li key={i} className="d-flex py-1 text-secondary">
                      <FileEarmarkArrowDown fontSize="20px" />
                      <div
                        className="text-secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          fetchFile(doc?.id, "docs");
                        }}
                      >
                        {doc.name}
                      </div>

                      {/* <a href={API_URL + doc?.url} className="text-secondary">{doc.name}</a> */}
                    </li>
                  ))}
                </td>
                <td
                  style={{
                    verticalAlign: "top",
                  }}
                >
                  {(profileData?.data?.unit_cctv_document == null ||
                    profileData?.data?.unit_cctv_document.length <= 0) && (
                    <li className="d-flex py-1 text-secondary">
                      <span className="text-muted">Not Available</span>
                    </li>
                  )}
                  {profileData?.data?.unit_cctv_document?.map((doc, i) => (
                    <li key={i} className="d-flex py-1 text-secondary">
                      <FileEarmarkArrowDown fontSize="20px" />
                      <div
                        className="text-secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          fetchFile(doc?.id, "docs");
                        }}
                      >
                        {doc.name}
                      </div>

                      {/* <a href={API_URL + doc?.url} className="text-secondary" target="_blank">{doc.name}</a> */}
                    </li>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* CCTV */}
      {/* <div className="row mx-1 pt-3">
                <div className="col-lg-12 card p-4 mb-4">
                    <h2>CCTV</h2>
                    <table className="table has-action-buttons">
                        <thead>
                            <tr>
                                <th>SANCTIONED Count</th>
                                <th>Deployed Count</th>
                                <th>Comment</th>
                                <th>Document</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{profileData?.data?.cctv ? profileData?.data?.cctv : <span className="text-muted">Not Available</span>}</td>
                                <td>{profileData?.data?.cctv_deployed ? profileData?.data?.cctv_deployed : <span className="text-muted">Not Available</span>}</td>
                                <td data-toggle="tooltip" data-placement="top" title={profileData?.data?.deployed_exceed_comment}>
                                    {profileData?.data?.deployed_exceed_comment ? (profileData?.data?.deployed_exceed_comment?.length > 20 ? profileData?.data?.deployed_exceed_comment?.slice(20) + '...' : profileData?.data?.deployed_exceed_comment) : (<span className="text-muted">Not Available</span>)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}

      <div className="row mx-1 mt-3 text-uppercase">
        {/* assignment by rank */}

        <div className="col-lg-12 p-0 m-0 ">
          <div className="row p-0 m-0 bg-white">
            <div className="col-lg-4 card g-1 p-4 shadow-none">
              <h2>SANCTIONED DEPLOYMENT</h2>
              <div className="col-lg-12  pb-3">
                {profileData?.data?.assignment_by_rank?.length === null && (
                  <span className="text-muted">Not Available</span>
                )}
              </div>
              <table className="table ">
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Count</th>
                  </tr>
                </thead>
                {(profileData?.data?.assignment_by_rank == null ||
                  profileData?.data?.assignment_by_rank.length <= 0) && (
                  <tr>
                    <td>
                      <span className="text-muted">Not Available</span>
                    </td>
                    <td>
                      <span className="text-muted">Not Available</span>
                    </td>
                  </tr>
                )}
                {profileData?.data?.assignment_by_rank?.map((item, index) => (
                  <tr>
                    <td>
                      {item.rank ? (
                        item.rank
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}{" "}
                    </td>
                    <td>
                      {item.count ? (
                        item.count
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th>Total</th>
                  <th>
                    {profileData?.data?.assignment_by_rank?.reduce(
                      (acc, { count }) => acc + Number(count),
                      0
                    )}
                  </th>
                </tr>
              </table>
            </div>

            <div className="col-lg-8 card g-1 p-4 shadow-none">
              <h2>Deployed By Unit</h2>
              <table className="table has-action-buttons">
                <thead>
                  <tr>
                    <th>Force</th>
                    <th>Battalion</th>
                    <th>Rank</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {(profileData?.data?.force_deployemnt == null ||
                    profileData?.data?.force_deployemnt.length <= 0) && (
                    <tr>
                      <td>
                        <span className="text-muted">Not Available</span>
                      </td>
                      <td>
                        <span className="text-muted">Not Available</span>
                      </td>
                      <td>
                        <span className="text-muted">Not Available</span>
                      </td>
                      <td>
                        <span className="text-muted">Not Available</span>
                      </td>
                    </tr>
                  )}
                  {profileData?.data?.force_deployemnt?.map((e) => (
                    <tr>
                      <td>
                        {force?.data?.find((f) => f?.id == e?.force)?.name}
                      </td>
                      <td>
                        {console.log(
                          battalion,
                          profileData?.data?.force_deployemnt
                        )}
                        {
                          battalion?.data
                            ?.filter((b) => b?.force == e?.force)
                            .find((f) => f?.id == e?.battalion)?.name
                        }
                      </td>
                      <td>
                        {" "}
                        {rank?.data?.find((f) => f?.id == e?.rank)?.name}
                      </td>
                      <td> {e.count} </td>
                    </tr>
                  ))}
                </tbody>
                <tr>
                  <th>Total</th>
                  <th></th>
                  <th></th>
                  <th>
                    {profileData?.data?.force_deployemnt?.reduce(
                      (acc, { count }) => acc + Number(count),
                      0
                    )}
                  </th>
                </tr>
              </table>

              <p>
                {profileData?.data?.force_deployment_comment && (
                  <div className="p-3 bg-light rounded">
                    {profileData?.data?.force_deployment_comment && (
                      <h6 className="font-weight-bold">Additional Notes</h6>
                    )}
                    <p className="small mb-0 pb-0">
                      {" "}
                      {profileData?.data?.force_deployment_comment}{" "}
                    </p>
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-12 card p-4 ">
          <h2>DEPLOYED OTHER FORCES</h2>
          <table className="table has-action-buttons">
            <thead>
              <tr>
                <th>Force</th>
                <th>NAME</th>
                <th>ADDRESS</th>
                <th>CONTACT</th>
                <th>Rank</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {(profileData?.data?.other_deployment == null ||
                profileData?.data?.other_deployment.length <= 0) && (
                <tr>
                  <td>
                    <span className="text-muted">Not Available</span>
                  </td>
                  <td>
                    <span className="text-muted">Not Available</span>
                  </td>
                  <td>
                    <span className="text-muted">Not Available</span>
                  </td>
                  <td>
                    <span className="text-muted">Not Available</span>
                  </td>
                  <td>
                    <span className="text-muted">Not Available</span>
                  </td>
                </tr>
              )}
              {profileData?.data?.other_deployment?.map((e) => (
                <tr>
                  <td>
                    {" "}
                    {otherforce?.data?.find((f) => f.id == e.force)?.name}{" "}
                  </td>
                  <td>
                    {" "}
                    {e.private_security_name ? (
                      e.private_security_name
                    ) : (
                      <span className="text-muted">Not Available</span>
                    )}
                  </td>
                  <td>
                    {e.private_security_address ? (
                      e.private_security_address
                    ) : (
                      <span className="text-muted">Not Available</span>
                    )}
                  </td>
                  <td>
                    {" "}
                    {e.private_security_contact ? (
                      e.private_security_contact
                    ) : (
                      <span className="text-muted">Not Available</span>
                    )}
                  </td>
                  <td>
                    {" "}
                    {rank?.data?.find((f) => f.id == e.rank)?.name ||
                      "Not Available"}{" "}
                  </td>
                  <td>
                    {" "}
                    {e.count ? (
                      e.count
                    ) : (
                      <span className="text-muted">Not Available</span>
                    )}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
            <tr>
              <th>Total</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                {profileData?.data?.other_deployment?.reduce(
                  (acc, { count }) => acc + Number(count),
                  0
                )}
              </th>
            </tr>
          </table>
        </div>

        <div className="col-lg-12 card p-4 mb-4 ">
          <h2>
            {" "}
            TOTAL Deployment :
            {profileData?.data?.other_deployment?.reduce(
              (acc, { count }) => acc + Number(count),
              0
            ) +
              profileData?.data?.force_deployemnt?.reduce(
                (acc, { count }) => acc + Number(count),
                0
              ) || 0}
          </h2>
        </div>

        <div className="col-lg-12 card p-4 mb-4">
          <h2 className="mb-3">SUPERVISIONING OFFICER </h2>
          <table className="table has-action-buttons">
            <thead>
              <tr>
                <th>Name</th>
                <th>Rank</th>
                <th>Phone</th>
                <th>Order Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {profileData?.data?.supervisor_officer?.name ? (
                    profileData?.data?.supervisor_officer?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.supervisor_officer?.rank ? (
                    profileData?.data?.supervisor_officer?.rank
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.supervisor_officer?.phone ? (
                    profileData?.data?.supervisor_officer?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.supervisor_officer?.order_no ? (
                    profileData?.data?.supervisor_officer?.order_no
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-lg-12 card p-4 mb-4">
          <h2>ESTABLISHMENT OFFICER</h2>
          <table className="table has-action-buttons">
            <thead>
              <tr>
                <th>Name</th>
                <th>Rank</th>
                <th>Phone</th>
                <th>Order Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {profileData?.data?.on_guard_incharge?.name ? (
                    profileData?.data?.on_guard_incharge?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.on_guard_incharge?.rank ? (
                    profileData?.data?.on_guard_incharge?.rank
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.on_guard_incharge?.phone ? (
                    profileData?.data?.on_guard_incharge?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}{" "}
                </td>
                <td>
                  {profileData?.data?.on_guard_incharge?.order_no ? (
                    profileData?.data?.on_guard_incharge?.order_no
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* teams and security infrastructure */}

        {/* security INFRASTRUCTURE */}
        {profileData?.data?.security_equipment?.length < 1 && (
          <div className="col-lg-12 card p-4 mb-4">
            <h2>Security INFRASTRUCTURE</h2>
            <h4>
              {" "}
              <span className="text-muted">Not Available</span>{" "}
            </h4>
          </div>
        )}
        {profileData?.data?.security_equipment?.length > 0 && (
          <div className="col-lg-12 card p-4 mb-4">
            <h2>Security INFRASTRUCTURE</h2>
            <table className="table has-action-buttons">
              <thead>
                <tr>
                  <th>INFRASTRUCTURE</th>
                  <th>NAME</th>
                  <th>SANCTIONED Count</th>
                  <th>Deployed Count</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {profileData?.data?.security_equipment?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.equipment_name ? (
                        item.equipment_name
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}
                    </td>
                    <td>
                      {item.name ? (
                        item.name
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}
                    </td>
                    <td>
                      {item.eqipment_count ? (
                        item.eqipment_count
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}
                    </td>
                    <td>
                      {item.equipment_deployed ? (
                        item.equipment_deployed
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}{" "}
                    </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"
                      title={item.deployed_more_comment}
                    >
                      {item.deployed_more_comment ? (
                        item.deployed_more_comment?.length > 20 ? (
                          item.deployed_more_comment?.slice(0, 20) + "..."
                        ) : (
                          item.deployed_more_comment
                        )
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}
                      {/* {item.deployed_more_comment ? item.deployed_more_comment : <span className="text-muted">Not Available</span>}  */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {profileData?.data?.team_s?.length < 0 && (
          <div className="col-lg-12 card p-4 mb-4">
            <h2>teams</h2>
            <h4>
              {" "}
              <span className="text-muted">Not Available</span>{" "}
            </h4>
          </div>
        )}
        {profileData?.data?.team_s?.length > 0 && (
          <div className="col-lg-12 card p-4 mb-4">
            <h2>teams</h2>
            <table className="table has-action-buttons">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Name</th>
                  <th>SANCTIONED Count</th>
                  <th>Deployed Count</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {profileData?.data?.team_s?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.team_name ? (
                        item.team_name
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}
                    </td>
                    <td>
                      {item.name ? (
                        item.name
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}
                    </td>
                    <td>
                      {item.team_count ? (
                        item.team_count
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}
                    </td>
                    <td>
                      {item.team_deployed ? (
                        item.team_deployed
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}{" "}
                    </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"
                      title={item.team_deployed_more_comment}
                    >
                      {item.team_deployed_more_comment ? (
                        item.team_deployed_more_comment
                      ) : (
                        <span className="text-muted">Not Available</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* emergency contact details */}

        <div className="col-lg-12 card p-4 mb-4">
          <h2>NEARBY LANDMARKS</h2>
          <table className="table has-action-buttons">
            <thead>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Distance (in KM )</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Concerned SSP</th>
                <td>
                  {profileData?.data?.profile?.ssp?.name ? (
                    profileData?.data?.profile?.ssp?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.ssp?.address ? (
                    profileData?.data?.profile?.ssp?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.ssp?.phone ? (
                    profileData?.data?.profile?.ssp?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.ssp?.distance ? (
                    profileData?.data?.profile?.ssp?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>Police Station</th>
                <td>
                  {profileData?.data?.profile?.police_station?.name ? (
                    profileData?.data?.profile?.police_station?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.police_station?.address ? (
                    profileData?.data?.profile?.police_station?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.police_station?.phone ? (
                    profileData?.data?.profile?.police_station?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.police_station?.distance ? (
                    profileData?.data?.profile?.police_station?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>Railway Station</th>
                <td>
                  {profileData?.data?.profile?.railway_station?.name ? (
                    profileData?.data?.profile?.railway_station?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.railway_station?.address ? (
                    profileData?.data?.profile?.railway_station?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.railway_station?.phone ? (
                    profileData?.data?.profile?.railway_station?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.railway_station?.distance ? (
                    profileData?.data?.profile?.railway_station?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>AirPort</th>
                <td>
                  {profileData?.data?.profile?.airport?.name ? (
                    profileData?.data?.profile?.airport?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.airport?.address ? (
                    profileData?.data?.profile?.airport?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.airport?.phone ? (
                    profileData?.data?.profile?.airport?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.airport?.distance ? (
                    profileData?.data?.profile?.airport?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>AirBase</th>
                <td>
                  {profileData?.data?.profile?.airbase?.name ? (
                    profileData?.data?.profile?.airbase?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.airbase?.address ? (
                    profileData?.data?.profile?.airbase?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.airbase?.phone ? (
                    profileData?.data?.profile?.airbase?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.airbase?.distance ? (
                    profileData?.data?.profile?.airbase?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>HeliPad</th>
                <td>
                  {profileData?.data?.profile?.helipad?.name ? (
                    profileData?.data?.profile?.helipad?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.helipad?.address ? (
                    profileData?.data?.profile?.helipad?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.helipad?.phone ? (
                    profileData?.data?.profile?.helipad?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.helipad?.distance ? (
                    profileData?.data?.profile?.helipad?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>NDRF</th>
                <td>
                  {profileData?.data?.profile?.ndrf?.name ? (
                    profileData?.data?.profile?.ndrf?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.ndrf?.address ? (
                    profileData?.data?.profile?.ndrf?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.ndrf?.phone ? (
                    profileData?.data?.profile?.ndrf?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.ndrf?.distance ? (
                    profileData?.data?.profile?.ndrf?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>District Police Control Room</th>
                <td>
                  {profileData?.data?.profile?.District_Police_Control_Room
                    ?.name ? (
                    profileData?.data?.profile?.District_Police_Control_Room
                      ?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.District_Police_Control_Room
                    ?.address ? (
                    profileData?.data?.profile?.District_Police_Control_Room
                      ?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.District_Police_Control_Room
                    ?.phone ? (
                    profileData?.data?.profile?.District_Police_Control_Room
                      ?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.District_Police_Control_Room
                    ?.distance ? (
                    profileData?.data?.profile?.District_Police_Control_Room
                      ?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>Fire Alram</th>
                <td>
                  {profileData?.data?.profile?.Fire_Alram?.name ? (
                    profileData?.data?.profile?.Fire_Alram?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.Fire_Alram?.address ? (
                    profileData?.data?.profile?.Fire_Alram?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.Fire_Alram?.phone ? (
                    profileData?.data?.profile?.Fire_Alram?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.Fire_Alram?.distance ? (
                    profileData?.data?.profile?.Fire_Alram?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>Magistrate Office</th>
                <td>
                  {profileData?.data?.profile?.Magistrate_Office?.name ? (
                    profileData?.data?.profile?.Magistrate_Office?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.Magistrate_Office?.address ? (
                    profileData?.data?.profile?.Magistrate_Office?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.Magistrate_Office?.phone ? (
                    profileData?.data?.profile?.Magistrate_Office?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.Magistrate_Office?.distance ? (
                    profileData?.data?.profile?.Magistrate_Office?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>Hospital</th>
                <td>
                  {profileData?.data?.profile?.Hospital?.name ? (
                    profileData?.data?.profile?.Hospital?.name
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.Hospital?.address ? (
                    profileData?.data?.profile?.Hospital?.address
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.Hospital?.phone ? (
                    profileData?.data?.profile?.Hospital?.phone
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
                <td>
                  {profileData?.data?.profile?.Hospital?.distance ? (
                    profileData?.data?.profile?.Hospital?.distance
                  ) : (
                    <span className="text-muted">Not Available</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ViewVpProfile;
